
import {
    EuiPage,
    EuiPageBody,
    EuiPageContent,
    EuiButtonEmpty,
    EuiFieldText,
} from "@elastic/eui";
import React, { Component } from "react";
import MyFrame from "../components/MyFrame";

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            queryPatient: "",
            queryDoctor: "",
            queryStaff: "",
            queryBill: "",
        };
    }

    render() {
        const self = this;
        return (
            <>
                <EuiPageBody
                    style={{ flexDirection: "column", float: "left", width: "100%", margin: 20 }}
                    verticalPosition="center"
                    horizontalPosition="center"
                >
                    <EuiPageBody
                        style={{ flexDirection: "row" }}
                    >
                        <MyFrame
                            betaBadgeLabel={"Pendaftaran Hari Ini"}
                            className="card-badge"
                            horizontalPosition="left"
                            height={100}
                            width={100}
                            src="https://superset.beta.siapdok.id/superset/explore/?r=64&standalone=1&height=100"
                        />
                        <MyFrame
                            className="card-badge"
                            betaBadgeLabel={"Konsultasi Hari Ini"}
                            horizontalPosition="left"
                            height={100}
                            width={100}
                            src="https://superset.beta.siapdok.id/superset/explore/?r=65&standalone=1&height=100"
                        />
                        <MyFrame
                            betaBadgeLabel={"Total Pembayaran Hari Ini (RP)"}
                            className="card-badge"
                            horizontalPosition="left"
                            height={100}
                            width={100}
                            src="https://superset.beta.siapdok.id/superset/explore/?r=66&standalone=1&height=100"
                        />
                        <MyFrame
                            betaBadgeLabel={"Total Pendaftaran"}
                            className="card-badge"
                            horizontalPosition="left"
                            height={100}
                            width={100}
                            src="https://superset.beta.siapdok.id/superset/explore/?r=67&standalone=1&height=100"
                        />
                        <MyFrame
                            betaBadgeLabel={"Total Pembayaran (Jumlah)"}
                            className="card-badge"
                            horizontalPosition="left"
                            height={100}
                            width={100}
                            src="https://superset.beta.siapdok.id/superset/explore/?r=69&standalone=1&height=100"
                        />
                        <MyFrame
                            betaBadgeLabel={"Total Pembayaran (RP)"}
                            className="card-badge"
                            horizontalPosition="left"
                            height={100}
                            width={100}
                            src="https://superset.beta.siapdok.id/superset/explore/?r=68&standalone=1&height=200"
                        />
                    </EuiPageBody>
                    <EuiPageBody
                        style={{ flexDirection: "row", marginTop: 20 }}
                    >
                        <MyFrame
                            title="Pendaftaran per Klinik"
                            height={400}
                            width={400}
                            src="https://superset.beta.siapdok.id/superset/explore/?r=47&standalone=1&height=400"
                        />
                        <MyFrame
                            title="Pendaftaran per Klinik (Jumlah)"
                            height={400}
                            width={400}
                            src="https://superset.beta.siapdok.id/superset/explore/?r=49&standalone=1&height=400"
                        />
                        <MyFrame
                            title="Konsultasi per Klinik"
                            height={400}
                            width={400}
                            src="https://superset.beta.siapdok.id/superset/explore/?r=50&standalone=1&height=400"
                        />
                    </EuiPageBody>
                    <EuiPageBody
                        style={{ flexDirection: "row", marginTop: 20 }}
                    >
                        <MyFrame
                            title="Pendaftaran per Klinik (Jumlah)"
                            height={400}
                            width={400}
                            src="https://superset.beta.siapdok.id/superset/explore/?r=52&standalone=1&height=400"
                        />
                        <MyFrame
                            title="Pendaftaran per Dokter"
                            height={400}
                            width={400}
                            src="https://superset.beta.siapdok.id/superset/explore/?r=53&standalone=1&height=400"
                        />
                        <MyFrame
                            title="Konsultasi per Dokter (Jumlah)"
                            height={400}
                            width={400}
                            src="https://superset.beta.siapdok.id/superset/explore/?r=54&standalone=1&height=400"
                        />
                    </EuiPageBody>
                    <EuiPageBody
                        style={{ flexDirection: "row", marginTop: 20 }}
                    >
                        <MyFrame
                            title="Pendaftaran Pembayaran Bulan Lalu"
                            height={400}
                            width={700}
                            src="https://superset.beta.siapdok.id/superset/explore/?r=55&standalone=1&height=400&width=700"
                        />
                        <MyFrame
                            title="Pendaftaran Pembayaran Bulan Ini"
                            height={400}
                            width={700}
                            src="https://superset.beta.siapdok.id/superset/explore/?r=56&standalone=1&height=400&width=700"
                        />
                    </EuiPageBody>
                </EuiPageBody>
            </>
        )
    }
}
export default Dashboard;
