import React, { Component } from "react";

import {
  EuiPageContent,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiPageContentBody,
  EuiTitle,
  EuiFieldText,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiPage,
  EuiPageBody,
  EuiFormRow,
  EuiFilePicker,
  EuiSpacer,
  EuiDatePicker,
  EuiTextArea,
} from "@elastic/eui";

class SessionDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <EuiPage className="euiNavDrawerPage">
          <EuiPageBody className={"content-container"}>
            <EuiPageContent>
              <EuiPageContentHeader>
                <EuiPageContentHeaderSection>
                  <EuiTitle>
                    <h2>Pertemuan</h2>
                  </EuiTitle>
                </EuiPageContentHeaderSection>
                <EuiPageContentHeaderSection>
                  <EuiFlexGroup>
                    {this.props.buttonLabel ? (
                      <EuiFlexItem grow={false}>
                        <EuiButton fill onClick={this.props.butonFunc}>
                          {this.props.buttonLabel}
                        </EuiButton>
                      </EuiFlexItem>
                    ) : null}
                  </EuiFlexGroup>
                </EuiPageContentHeaderSection>
              </EuiPageContentHeader>
              <EuiPageContentBody>
                <EuiForm
                  className="form-layout"
                >
                  <EuiFormRow label="ID">
                    <EuiFieldText name="idPertemuan" disabled value={'14bdcf-42-abd89-163cef-123'}/>
                  </EuiFormRow>
                  <EuiFormRow label="Nama Dokter">
                    <EuiFieldText name="nameDoctor" />
                  </EuiFormRow>
                  <EuiFormRow label="Nama Pasien">
                    <EuiFieldText name="namePasien" />
                  </EuiFormRow>
                  <EuiFormRow label="Tanggal">
                    <EuiDatePicker />
                  </EuiFormRow>
                  <EuiFormRow label="Lokasi">
                    <EuiFieldText name="location" />
                  </EuiFormRow>
                  <EuiFormRow label="Catatan Medis">
                    <EuiTextArea />
                  </EuiFormRow>
                  <EuiFormRow label="Resep">
                    <EuiTextArea />
                  </EuiFormRow>
                  <EuiFormRow label="Lampiran">
                    <EuiFilePicker
                      name="attachment"
                      id="attachmentFilePicker"
                      multiple
                    />
                  </EuiFormRow>
                  <EuiSpacer size="l" />
                  <EuiFlexGroup justifyContent={"flexEnd"}>
                    <EuiFlexItem grow={false}>
                      <EuiButton fill>Tutup</EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiForm>
              </EuiPageContentBody>
            </EuiPageContent>
          </EuiPageBody>
        </EuiPage>
      </>
    );
  }
}

export default SessionDetail;
