import React, { Component } from "react";

import {
    EuiHeader,
    EuiHeaderLogo,
    EuiHeaderBreadcrumbs,
    EuiHeaderSection,
    EuiHeaderSectionItem,
    EuiHeaderSectionItemButton,
    EuiPopover,
    EuiAvatar,
    EuiIcon,
    EuiButton
} from "@elastic/eui";

import Config from "./Config";
import telekonsul from "./assets/telekonsul.png";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        return (
            <div style={{ marginBottom: "50px" }}>
                (c) 2019-2021 SiapDok by PT Telekonsul Digital Indonesia
            </div>
        );
    }
}

export default Footer;
