import React, { Component } from "react";
import { debounce } from "lodash";
import {
  EuiPage,
  EuiPageBody,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFlexItem,
  EuiModalBody,
  EuiModalFooter,
  EuiModal,
  EuiSelect,
  EuiOverlayMask,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiButton,
} from "@elastic/eui";
import TablePage from "../components/TablePage";
import Patient from "../services/Patients";
import moment from "moment";
import ClinicService from "../services/Clinics";

const PatientService = new Patient();
const clinicService = new ClinicService();

class PatientsOfClinic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      order: "ASC",
      search: "",
      loading: true,
      orderBy: "full_name",
      data: [],
      clinicList: [],
      clinicName: localStorage.getItem("clinicName"),
      clinicNameTitle: localStorage.getItem("clinicName"),
      clinicId: this.props.match.params.uuid,
      column: [
        {
          field: "external_id",
          name: "Nomor Rekam Medis",
        },
        {
          field: "name",
          name: "Name",
        },
        {
          field: "email",
          name: "Email",
        },
        {
          field: "phone_number",
          name: "Nomor Telepon",
        },
        {
          field: "dob",
          name: "Tanggal Lahir",
        },
      ],
    };
  }

  componentDidMount = () => {
    this.page(1);
    this.loadClinics();
  };

  page = (page) => {
    page = page || 1;
    this.setState({ loading: true });
    let payload = {
      clinic_id: this.state.clinicId,
      page: page,
      limit: this.state.limit,
      search: this.state.search,
      order: this.state.order,
      order_by: this.state.orderBy,
    };
    PatientService.getPatientsByClinic(payload)
      .then((result) => {
        for (let i in result.items) {
          if (result.items[i].dob.length > 0) {
            result.items[i].dob = moment
              .utc(result.items[i].dob)
              .format("DD-MM-YYYY");
          } else result.items[i].dob = "-";
        }
        this.setState(
          {
            page: result.page,
            limit: result.limit,
            total: result.total,
            order: result.order,
            orderBy: result.order_by,
            data: result.items,
            loading: false,
          },
          () => {
            this.forceUpdate();
          }
        );
      })
      .catch((err) => {
        this.setState({
          loading: false,
          data: [],
        });
      });
  };

  handleOnSearch = (value) => {
    this.setState({ search: value, loading: true });
    this.handleSearchDebounce();
  };

  handleSearchDebounce = debounce(() => {
    const payload = {
      search: this.state.search,
      clinic_id: this.props.match.params.uuid,
    };
    PatientService.getPatientsByClinic(payload)
      .then((result) => {
        console.log(result);
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          order: result.order,
          orderBy: result.order_by,
          data: result.items,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, 2000);

  save = () => {
   this.page(1);
   this.closeModal();
   this.setState({
     clinicNameTitle: this.state.clinicName,
   })
  }

  loadClinics = () => {
    return new Promise((resolve, reject) => {
      let payload = {
        limit: 1000,
      };
      clinicService
        .getClinics(payload)
        .then((result) => {
          let options = [];
          for (let i in result.items) {
            options.push({
              value: result.items[i].id,
              text: result.items[i].name
            });
          }
          this.setState(
            {
              clinicList: options,
            },
            () => {
              resolve();
            }
          );
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            clinicList: [],
          });
          reject(err);
        });
    });
  };

  onClinicChange = e => {
    let value = e.target.value || null;
    console.log(value);
    let name = "";
    for (let i in this.state.clinicList) {
      if (this.state.clinicList[i].value === value) {
        name =
          this.state.clinicList[i].name || this.state.clinicList[i].text;
      }
    }
    this.setState({
      clinicId: value,
      clinicName: name
    });
  };

  clinicListModal = () => {
    this.setState(
      {
        showModal: true,
      },
      () => {
        this.loadClinics();
      }
    );
  };
  closeModal = () => {
    this.setState({
      showModal: false,
      clinicList: [],
    });
  };

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };

  onItemClick = (item) => {
    this.props.history.push("/patient/detail/" + item.id);
  };

  render() {
    return (
      <>
        <EuiPage className="class">
          <EuiPageBody
            style={{ flexDirection: "row" }}
            className={"content-container"}
          >
            <TablePage
              backButton={1}
              title={`Daftar Pasien ${this.state.clinicNameTitle}`}
              placeholder={"Nama Pasien"}
              searchComp={
                <EuiFlexItem>
                  <EuiFieldText
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        this.search(this.state.search);
                      }
                    }}
                    value={this.state.search}
                    onChange={(e) => this.handleOnSearch(e.target.value)}
                    placeholder={this.props.placeholder}
                    append={
                      <EuiButtonEmpty
                        onClick={() => this.search(this.state.search)}
                      >
                        Cari
                      </EuiButtonEmpty>
                    }
                  />
                </EuiFlexItem>
              }
              buttonListLabel={"Lihat Klinik Lain"}
              buttonListFunc={() => this.clinicListModal()}
              data={this.state.data}
              column={this.state.column}
              location={this.props.location}
              disablePagination={this.state.loading}
              loading={this.state.loading}
              page={this.state.page}
              limit={this.state.limit}
              total={this.state.total}
              prev={this.prevPage}
              next={this.nextPage}
              toPage={this.page}
              onItemClick={this.onItemClick}
            />
          </EuiPageBody>
        </EuiPage>
        {this.state.showModal && (
          <EuiOverlayMask>
            <EuiModal onClose={() => this.closeModal()}>
              <EuiModalHeader>
                <EuiModalHeaderTitle>Pilih Klinik</EuiModalHeaderTitle>
              </EuiModalHeader>

              <EuiModalBody>
              <div>
              <EuiSelect
                      placeholder="Klinik"
                      options={this.state.clinicList}
                      value={this.state.clinicId}
                      name="clinicName"
                      onChange={this.onClinicChange}
                      aria-label="Organisasi"
                    />
              </div>
              </EuiModalBody>
              <EuiModalFooter>
              <EuiButton onClick={() => this.save()} fill>
                  Cari
                </EuiButton>
                <EuiButtonEmpty onClick={() => this.closeModal()}>
                  Batal
                </EuiButtonEmpty>
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        )}
      </>
    );
  }
}

export default PatientsOfClinic;
