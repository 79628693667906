import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { EuiNavDrawer, EuiSideNav, EuiHorizontalRule } from '@elastic/eui';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBinoculars,
  faWallet,
  faUserInjured,
  faUserMd,
  faUserNurse,
  faShieldAlt,
  faClinicMedical,
  faHospital,
  faCalendarDay,
  faCalendarAlt,
  faQrcode,
  faDatabase,
  faUser,
  faTablets,
  faMortarPestle,
  faCogs,
  faCalendarCheck,
  faMobile,
  faPills,
  faSync,
  faGlobeAsia,
  faDesktop,
  faBug,
  faMoneyBillWaveAlt,
  faCalendar,
  faBook,
  faMicroscope,
  faHome,
  faStickyNote,
} from '@fortawesome/free-solid-svg-icons';

import VariantService from './services/Variants.js';
import Config from './Config';

const variantService = new VariantService();

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: '',
      prescriptionsVariants: [],
      summariesVariants: [],
      overviewVariants: [],
    };
  }
  onSelect(selected, path) {
    this.setState({ selected: selected });
    this.props.history.push(path);
    this.props.history.go(0);
  }
  componentDidMount() {
    let self = this;
    variantService.getVariants().then((result) => {
      console.log('--------------------------------');
      let prescriptionsVariants = [];
      let summariesVariants = [];
      let overviewVariants = [];
      for (let i in result.items) {
        let prescriptionVariant = Object.assign({}, result.items[i]);
        let summaryVariant = Object.assign({}, result.items[i]);
        let overviewVariant = Object.assign({}, result.items[i]);

        prescriptionVariant.id = 'prescriptions-' + prescriptionVariant.variant;
        prescriptionVariant.isSelected =
          this.selected === 'prescriptions-' + prescriptionVariant.variant;
        prescriptionVariant.onClick = function () {
          self.onSelect(
            'prescriptions-' + prescriptionVariant.variant,
            '/prescriptions/' + prescriptionVariant.variant
          );
        };
        prescriptionsVariants.push(prescriptionVariant);

        summaryVariant.id = 'summaries-' + summaryVariant.variant;
        summaryVariant.isSelected =
          this.selected === 'summaries-' + summaryVariant.variant;
        summaryVariant.onClick = function () {
          self.onSelect(
            'summaries-' + summaryVariant.variant,
            '/summaries/' + summaryVariant.variant
          );
        };
        summariesVariants.push(summaryVariant);

        overviewVariant.id = 'overview-' + overviewVariant.variant;
        overviewVariant.isSelected =
          this.selected === 'overview-' + overviewVariant.variant;
        overviewVariant.onClick = function () {
          self.onSelect(
            'overview-' + overviewVariant.variant,
            '/overview/' + overviewVariant.variant
          );
        };

        overviewVariants.push(overviewVariant);
      }
      this.setState({
        prescriptionsVariants,
        summariesVariants,
        overviewVariants,
      });
    });
  }
  render() {
    const sidenav = [
      {
        id: 0,
        name: 'Telekonsul',
        icon: <FontAwesomeIcon icon={faUserInjured} />,
        forceOpen: false,
        isSelected: false,
        items: [
          /* Commented for template
          {
            id: '',
            name: '',
            isSelected: this.state.selected === '',
            icon: <FontAwesomeIcon icon={faUserInjured} />,
            onClick: () => {
              this.setState({ selected: '' });
            },
            items: [
              {
                id: '',
                name: '',
                isSelected: this.state.selected === '',
                icon: <FontAwesomeIcon icon={faUserInjured} />,
                onClick: () => {
                  this.setState({ selected: '' });
                },
              },
            ],
          },
          */
          //   {
          //     id: 'landing',
          //     name: 'Dashboard',
          //     isSelected: this.state.selected === 'landing',
          //     icon: <FontAwesomeIcon icon={faHome} />,
          //     onClick: () => {
          //       this.setState({ selected: 'landing' });
          //       this.props.history.push('/');
          //     },
          //   },
          {
            id: 'summaries',
            name: 'Summary',
            isSelected: this.state.selected === 'summaries',
            icon: <FontAwesomeIcon icon={faStickyNote} />,
            onClick: () => {
              this.setState({ selected: 'summaries' });
            },
            items: this.state.summariesVariants,
          },
          {
            id: 'overview',
            name: 'Overview',
            isSelected: this.state.selected === 'overview',
            icon: <FontAwesomeIcon icon={faBook} />,
            onClick: () => {
              this.setState({ selected: 'overview' });
            },
            items: this.state.overviewVariants,
          },
          {
            id: 'prescriptions',
            name: 'Daftar Transaksi',
            isSelected: this.state.selected === 'prescriptions',
            icon: <FontAwesomeIcon icon={faTablets} />,
            onClick: () => {
              this.setState({ selected: 'prescriptions' });
            },
            items: this.state.prescriptionsVariants,
            /*
            [
              {
                id: "prescriptions-rscm",
                name: "RSCM",
                isSelected: this.state.selected === "",
                icon: <FontAwesomeIcon icon={faTablets} />,
                onClick: () => {
                  this.setState({ selected: "prescriptions-rscm" });
                  this.props.history.push("/prescriptions/rscm");
                },
              },
            ],
            */
          },
          /*
          {
            id: 'doctors',
            name: 'Data dokter',
            isSelected: this.state.selected === 'doctors',
            icon: <FontAwesomeIcon icon={faUser} />,
            onClick: () => {
              this.setState({ selected: 'doctors' });
            },
            items: [
              {
                id: 'doctors-rscm',
                name: 'RSCM',
                isSelected: this.state.selected === 'doctors-rscm',
                icon: <FontAwesomeIcon icon={faUserMd} />,
                onClick: () => {
                  this.setState({ selected: 'doctors-rscm' });
                  this.props.history.push('/doctors/rscm');
                },
              },
            ],
          },
					 * */
        ],
      },
    ];

    let supersetNav = (Config.supersetPages || []).map((v) => {
      return {
        id: v.id,
        name: v.name,
        icon: <FontAwesomeIcon icon={faDatabase} />,
        isSelected: this.state.selected === v.id,
        onClick: () => {
          this.setState({ selected: v.id });
          this.props.history.push('/' + v.id);
        },
      };
    });

    let finalSideNav = [...sidenav];

    if (supersetNav.length > 0) {
      let parent = {
        id: 'report',
        name: 'Laporan',
        isSelected: this.state.selected === 'report',
        icon: <FontAwesomeIcon icon={faBook} />,
        onClick: () => {
          this.setState({ selected: 'report' });
        },
        items: [...supersetNav],
      };

      finalSideNav[0].items = [...sidenav[0].items, parent];
    }

    return (
      <EuiNavDrawer
        onMouseEnter={() => {
          if (
            document
              .getElementsByClassName('euiNavDrawer')[0]
              .className.includes('euiNavDrawer-isCollapsed')
          ) {
            this.setState({ isExpanded: true });
            document
              .getElementsByClassName('euiListGroupItem__button')[0]
              .click();
          }
        }}
        onMouseLeave={() => {
          if (
            !document
              .getElementsByClassName('euiNavDrawer')[0]
              .className.includes('euiNavDrawer-isCollapsed')
          ) {
            document
              .getElementsByClassName('euiListGroupItem__button')[0]
              .click();
            this.setState({ isExpanded: false });
          }
        }}
      >
        <EuiHorizontalRule margin='none' />
        <div>
          {this.state.isExpanded && (
            <EuiSideNav id='sidenav' items={finalSideNav} />
          )}
          {!this.state.isExpanded && (
            <div
              style={{
                transform: 'rotate(90deg)',
                marginTop: '40vh',
                textAlign: 'center',
              }}
            >
              <p>Menu</p>
            </div>
          )}
        </div>
      </EuiNavDrawer>
    );
  }
}

export default withRouter(Sidebar);
