import Config from "../Config";
import axios from "axios";
import Error from './Error';
import Misc from "./Misc";

const ErrorService = new Error();
const MiscService = new Misc();

class Logs {
  getLogsBySessionId = (uuid) => {
    return new Promise((resolve, reject) => {
      let payload =  {sessionId: uuid, orderby: 'timestamp', orderBy: 'timestamp'};
      let url = `${Config.apiHost}/api/logs-by-session-id?sessionId=${uuid}`
      axios.post(url, payload, { headers: MiscService.generateHeaders() })
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => {
        reject(err)
        ErrorService.handle(err)
      })
    });
  }
}
export default Logs;
