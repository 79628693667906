import React, { Component } from 'react';
import Medicine from '../services/Medicines';
import { debounce } from "lodash";
import Utils from "../Utils";
import {
  EuiPage,
  EuiPageBody,
  EuiButton,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiOverlayMask,
  EuiButtonEmpty,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiFlexItem,
  EuiTextColor,
  EuiSpacer,
  EuiTextArea,
  EuiSelect,
} from "@elastic/eui";
import TablePage from "../components/TablePage";
import ErrorService from "../services/Error.js";

const medicineServices = new Medicine()
const errorService = new ErrorService();

class Medicines extends Component {
  state = {
    page: 1,
    limit: 10,
    order: "ASC",
    search: "",
    loading: false,
    orderBy: "name",
    id: "",
    name: "",
    substance: "",
    price: "",
    vendor: "",
    saveLoading: false,
    deleteLoading: false,
    size: "",
    usability: "",
    warning: "",
    code: "",
    dosage: "",
    column: [
      {
        field: "name",
        name: "Nama Obat"
      },
      {
        field: "substance",
        name: "Komposisi"
      },
      {
        field: "priceStr",
        name: "Harga"
      },
      {
        field: "vendor",
        name: "Produsen"
      }
    ],
    validationFields: {
      name: {
        type: "string",
        isInvalid: false,
        errors: ["Tidak boleh kosong"],
        isValidFunc: function (value) {
          return value && value.length > 0;
        }
      },
      substance: {
        type: "string",
        isInvalid: false,
        errors: ["Tidak boleh kosong"],
        isValidFunc: function (value) {
          return value && value.length > 0;
        }
      },
      price: {
        type: "string",
        isInvalid: false,
        errors: ["Tidak boleh kosong"],
        isValidFunc: function (value) {
          return value && value.length > 0;
        }
      },
      vendor: {
        type: "string",
        isInvalid: false,
        errors: ["Tidak boleh kosong"],
        isValidFunc: function (value) {
          return value && value.length > 0;
        }
      },
      size: {
        type: "string",
        isInvalid: false,
        errors: ["Tidak boleh kurang dari 1"],
        isValidFunc: function (value) {
          return value && parseInt(value,10) > 0;
        }
      },
      code: {
        type: "string",
        isInvalid: false,
        errors: ["Tidak boleh kosong"],
        isValidFunc: function (value) {
          return value && value.length > 0;
        }
      }
    },
    medicineGroup: [
      {
        value: 0,
        text: 'Non Group'
      },
    ],
    groupSelected: "0",
    medicineTypes: [
      {
        value: 0,
        text: 'Pilih Tipe'
      },
      {
        value: 1,
        text: 'Tablet'
      },
      {
        value: 2,
        text: 'Kapsul'
      },
      {
        value: 3,
        text: 'Botol'
      },
    ],
    typeSelected: "0"
  }

  componentDidMount = () => {
    this.page(1);
  };

  page = (page) => {
    page = page || 1;
    this.setState({ loading: true });
    let payload = {
      page: page,
      limit: this.state.limit,
      search: this.state.search,
      order: this.state.order,
      orderBy: this.state.orderBy
    };
    medicineServices.getMedicines(payload)
    .then((result) => {
      for (let i in result.items) {
        result.items[i].substance = result.items[i].substance !== null ? result.items[i].substance.toString() : ""
        result.items[i].priceStr = `Rp ${ result.items[i].price.units}`
      }
      this.setState(
        {
          page: result.page,
          limit: result.limit,
          total: result.total,
          order: result.order,
          orderBy: result.orderBy,
          data: result.items,
          loading: false
        },
        () => {
          this.forceUpdate();
        }
      );
    })
    .catch(err => {
      console.log(err);
      this.setState({
        loading: false,
        data: []
      });
    });
  };

  handleChange = e => {
    let value = e.target.value || null;
    let obj = {};
    obj[e.target.name] = value;
    obj["failedAttempt"] = false;
    let validationFields = { ...this.state.validationFields };
    if (validationFields[e.target.name]) {
      validationFields[e.target.name].isInvalid = false;
      obj.validationFields = validationFields;
    }
    this.setState(obj);
  };

  handleOnSearch = value => {
    this.setState({ search: value, loading: true });
    this.handleSearchDebounce();
  };

  handleSearchDebounce = debounce(() => {
    this.setState({ serach: this.state.search })
    this.page(this.state.page)
  }, 2000);

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };

  add = () => {
    this.prepareNewItem();
  };

  prepareNewItem = () => {
    this.setState(
      {
        id: "",
        name: "",
        substance: "",
        price: "",
        vendor: "",
        showModal: true,
        isNewItem: true,
        typeSelected: "0",
        groupSelected: "0",
        usability: "",
        dosage: "",
        code: "",
        warning: "",
        size: 0,
      }
    );
  };

  closeModal = () => {
    this.setState(
      {
        id: "",
        name: "",
        substance: "",
        price: "",
        vendor: "",
        showModal: false,
        isNewItem: false,
        typeSelected: "0",
        groupSelected: "0",
        usability: "",
        dosage: "",
        code: "",
        warning: "",
        size: "",
      }
    );
  };

  save = () => {
    let state = { ...this.state };
    this.setState({ saveLoading: true }, () => {
      Utils.validateFields(state).then(result => {
        this.setState({ validationFields: result.validationFields });
        this.forceUpdate();
        if (!result.isValid) {
          this.setState({ saveLoading: false });
          console.log(result);
          return;
        }
        let payload = {
          name: this.state.name,
          substance: this.state.substance.split(","),
          vendor: this.state.vendor,
          price: {
            currency_code: "IDR",
            units: parseInt(this.state.price, 10)
          },
          type: parseInt(this.state.typeSelected, 10),
          group: parseInt(this.state.groupSelected, 10),
          usability: this.state.usability,
          dosage: this.state.dosage,
          code: this.state.code,
          warning: this.state.warning,
          size: parseInt(this.state.size,10)
        };
        if (this.state.id && this.state.id.length > 0) {
          payload.id = this.state.id;
          // Update existing
          medicineServices
            .updateMedicine(payload)
            .then(result => {
              this.setState({ saveLoading: false, loading: true });
              this.closeModal();
              this.page(1);
            })
            .catch(err => {
              this.setState({ saveLoading: false });
              errorService.handle(err);
            });
        } else {
          // Create new one
          medicineServices
            .createMedicine(payload)
            .then(result => {
              this.setState({ saveLoading: false, loading: true });
              this.closeModal();
              this.page(1);
            })
            .catch(err => {
              this.setState({ saveLoading: false });
              errorService.handle(err);
            });
        }
      });
    });
  };

  onItemClick = item => {
    this.setState(
      {
        id: item.id,
        name: item.name,
        substance: item.substance,
        price: item.price.units.toString(),
        vendor: item.vendor,
        showModal: true,
        typeSelected: item.type.toString(),
        groupSelected: item.group.toString(),
        usability: item.usability,
        dosage: item.dosage,
        code: item.code,
        warning: item.warning,
        size: item.size,
      }
    );
  };

  delete = () => {
    if (!(this.state.id && this.state.id.length > 0)) {
      return;
    }
    if (!window.confirm("Apakah Anda yakin ingin menghapus item ini?")) {
      return;
    }
    this.setState({ deleteLoading: true })
    medicineServices
      .deleteMedicine(this.state.id)
      .then(result => {
        this.setState({ deleteLoading: false, loading: true });
        this.closeModal();
        this.page(1);
      })
      .catch(err => {
        this.setState({ saveLoading: false });
        errorService.handle(err);
      });
  };

  onGroupChange = e => {
    let value = e.target.value || null;
    let name = "";
    for (let i in this.state.medicineGroup) {
      if (this.state.medicineGroup[i].value === value) {
        name =
          this.state.medicineGroup[i].name || this.state.medicineGroup[i].text;
      }
    }
    this.setState({
      groupSelected: value,
    });
  };

  onTypeChange = e => {
    let value = e.target.value || null;
    let name = "";
    for (let i in this.state.medicineTypes) {
      if (this.state.medicineTypes[i].value === value) {
        name =
          this.state.medicineTypes[i].name || this.state.medicineTypes[i].text;
      }
    }
    this.setState({
      typeSelected: value,
    });
  };

  render() {
    return (
      <>
        <EuiPage className="class">
          <EuiPageBody
            style={{ flexDirection: "row" }}
            className={"content-container"}
          >
            <TablePage
              title={"Daftar Obat"}
              placeholder={"Cari"}
              searchComp={
                <EuiFlexItem>
                  <EuiFieldText
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        this.handleOnSearch(this.state.search);
                      }
                    }}
                    value={this.state.search}
                    onChange={e => this.handleOnSearch(e.target.value)}
                    placeholder={this.props.placeholder}
                    append={
                      <EuiButtonEmpty
                        onClick={() => this.handleOnSearch(this.state.search)}
                      >
                        Cari
                      </EuiButtonEmpty>
                    }
                  />
                </EuiFlexItem>
              }
              buttonLabel={"Tambah"}
              buttonFunc={() => this.add()}
              data={this.state.data}
              column={this.state.column}
              disablePagination={this.state.loading}
              loading={this.state.loading}
              page={this.state.page}
              limit={this.state.limit}
              total={this.state.total}
              prev={this.prevPage}
              next={this.nextPage}
              toPage={this.page}
              onItemClick={this.onItemClick}
            />
          </EuiPageBody>
        </EuiPage>
        {this.state.showModal && (
          <EuiOverlayMask>
            <EuiModal
              onClose={() => this.closeModal()}
              initialFocus="[name=popswitch]"
              style={{ maxWidth: 400 }}
            >
              <EuiModalHeader>
                <EuiModalHeaderTitle>Lihat/Ubah Obat</EuiModalHeaderTitle>
              </EuiModalHeader>
              <EuiModalBody>
                <EuiForm>
                  <div style={{ marginTop: 15 }}>
                  <EuiFormRow
                      label="Kode"
                      isInvalid={
                        this.state.validationFields["code"].isInvalid
                      }
                      error={
                        this.state.validationFields["code"].isInvalid &&
                        this.state.validationFields["code"].errors
                      }
                    >
                      <EuiFieldText
                        placeholder="Kode"
                        value={this.state.code}
                        name="code"
                        onChange={this.handleChange}
                        aria-label="Code"
                      />
                    </EuiFormRow>
                    <EuiFormRow
                      label="Nama"
                      isInvalid={
                        this.state.validationFields["name"].isInvalid
                      }
                      error={
                        this.state.validationFields["name"].isInvalid &&
                        this.state.validationFields["name"].errors
                      }
                    >
                      <EuiFieldText
                        placeholder="Nama"
                        value={this.state.name}
                        name="name"
                        onChange={this.handleChange}
                        aria-label="Nama"
                      />
                    </EuiFormRow>
                    <EuiFormRow
                      label="Grup"
                    >
                      <EuiSelect
                        placeholder="Grup"
                        options={this.state.medicineGroup}
                        value={this.state.groupSelected}
                        name="groupSelected"
                        onChange={this.onGroupChange}
                        aria-label="Grup"
                      />
                    </EuiFormRow>
                    <EuiFormRow
                      label="Produsen"
                      isInvalid={
                        this.state.validationFields["vendor"].isInvalid
                      }
                      error={
                        this.state.validationFields["vendor"].isInvalid &&
                        this.state.validationFields["vendor"].errors
                      }
                    >
                      <EuiFieldText
                        placeholder="Produsen"
                        value={this.state.vendor}
                        name="vendor"
                        onChange={this.handleChange}
                        aria-label="Produsen"
                      />
                    </EuiFormRow>
                    <EuiFormRow
                      label="Tipe"
                    >
                      <EuiSelect
                        placeholder="Tipe"
                        options={this.state.medicineTypes}
                        value={this.state.typeSelected}
                        name="typeSelected"
                        onChange={this.onTypeChange}
                        aria-label="Grup"
                      />
                    </EuiFormRow>
                    <EuiFormRow
                      label="Ukuran"
                      isInvalid={
                        this.state.validationFields["size"].isInvalid
                      }
                      error={
                        this.state.validationFields["size"].isInvalid &&
                        this.state.validationFields["size"].errors
                      }
                    >
                      <EuiFieldText
                        placeholder="Ukuran"
                        value={this.state.size}
                        name="size"
                        onChange={this.handleChange}
                        aria-label="Ukuran"
                      />
                    </EuiFormRow>
                    <EuiFormRow
                      label="Komposisi"
                      isInvalid={
                        this.state.validationFields["substance"].isInvalid
                      }
                      error={
                        this.state.validationFields["substance"].isInvalid &&
                        this.state.validationFields["substance"].errors
                      }
                    >
                      <EuiTextArea
                        placeholder="Komposisi"
                        value={this.state.substance}
                        name="substance"
                        onChange={this.handleChange}
                        aria-label="Komposisi"
                      />
                    </EuiFormRow>
                    <p style={{ fontSize: 12}}>
                      <EuiTextColor color="danger">*Pakai koma jika terdapat lebih dari satu komposisi</EuiTextColor>
                    </p>
                    <EuiSpacer size="m" />
                    <EuiFormRow
                      label="Khasiat dan Kegunaan"
                    >
                      <EuiTextArea
                        placeholder="Khasiat dan kegunaan"
                        value={this.state.usability}
                        name="usability"
                        onChange={this.handleChange}
                        aria-label="Komposisi"
                      />
                    </EuiFormRow>
                    <EuiFormRow
                      label="Takaran Pemakaian"
                    >
                      <EuiTextArea
                        placeholder="Takaran Pemakaian"
                        value={this.state.dosage}
                        name="dosage"
                        onChange={this.handleChange}
                        aria-label="Takaran Pemakaian"
                      />
                    </EuiFormRow>
                    <EuiFormRow
                      label="Peringatan dan pemakaian"
                    >
                      <EuiTextArea
                        placeholder="Peringatan dan pemakaian"
                        value={this.state.warning}
                        name="warning"
                        onChange={this.handleChange}
                        aria-label="Peringatan dan pemakaian"
                      />
                    </EuiFormRow>
                    <EuiFormRow
                      label="Harga"
                      isInvalid={
                        this.state.validationFields["price"].isInvalid
                      }
                      error={
                        this.state.validationFields["price"].isInvalid &&
                        this.state.validationFields["price"].errors
                      }
                    >
                      <EuiFieldText
                        placeholder="Harga"
                        value={this.state.price}
                        name="price"
                        onChange={this.handleChange}
                        aria-label="Harga"
                      />
                    </EuiFormRow>
                    
                  </div>
                </EuiForm>
              </EuiModalBody>
              <EuiModalFooter>
                <EuiButtonEmpty onClick={() => this.closeModal()}>
                  Batal
                </EuiButtonEmpty>
                {this.state.id && (
                  <EuiButton
                    color={"danger"}
                    onClick={() => this.delete()}
                    disabled={this.state.saveLoading} isLoading={this.state.deleteLoading}
                    fill
                  >
                    Hapus
                  </EuiButton>
                )}
                <EuiButton onClick={() => this.save()} fill isLoading={this.state.saveLoading} disabled={this.state.deleteLoading}>
                  Simpan
                </EuiButton>
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        )}
      </>
    )
  }
}

export default Medicines;
