import React, {Component} from 'react';

import {
  EuiPage,
  EuiPageBody,
  EuiButton,
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalFooter,
  EuiButtonEmpty,
} from '@elastic/eui';
import TablePage from '../components/TablePage';
import WeblinkService from '../services/Weblink';
import ErrorService from '../services/Error.js';
import Config from "../Config";

const weblinkService = new WeblinkService();
const errorService = new ErrorService();

class WeblinkList extends Component {
  state = {
    page: 1,
    limit: 10,
    order: 'ASC',
    search: '',
    loading: false,
    orderBy: 'role',
    data: [],
    column: [
      {
        field: 'role',
        name: 'Role',
      },
      {
        field: 'id',
        name: 'Aksi',
        render: (id, data) => (
          <>
            <EuiButtonEmpty onClick={() => {
              navigator.clipboard.writeText(Config.weblink + "/" + weblinkService.makeUserCode(data.role) + "-" + data.id)
              alert("Link berhasil disalin")
            }}>
              Salin link
            </EuiButtonEmpty>
          </>
        ),
        sortable: false,
      },

    ],
  };

  componentDidMount = () => {
    this.page(1);
  };

  page = page => {
    page = page || 1;
    this.setState({loading: true, search: ''});
    let payload = {
      Id: this.props.match.params.id,
      page: page,
      limit: this.state.limit,
      search: this.state.search,
      order: this.state.order,
      orderBy: this.state.orderBy,
    };

    weblinkService.listWeblink(payload).then(result => {
      console.log("WEBLINK LIST view ---> ",result)
      this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          order: result.order,
          orderBy: result.orderBy,
          data: result.items,
          loading: false,
        },
        () => {
          this.forceUpdate();
        },
      );
    }).catch(err => {
      console.log("ERROR ------> ",err)
      this.setState({
        loading: false,
        data: [],
      });
    })
  };

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  };

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };

  save = (role = "") => {
    let payload = {
      session_id: this.props.match.params.id,
      role: role,
    }
    weblinkService
      .createWeblink(payload)
      .then(result => {
        this.setState({saveLoading: false, loading: true});
        this.page(1);
        this.closeModal();
      })
      .catch(err => {
        this.setState({saveLoading: false});
        errorService.handle(err);
      });
  };

  render() {
    return (
      <>
        <EuiPage className="class">
          <EuiPageBody
            style={{flexDirection: 'row'}}
            className={'content-container'}>
            {!this.state.loading && (
              <TablePage
                title={'Daftar weblink'}
                data={this.state.data}
                column={this.state.column}
                buttonLabel={'Tambah'}
                buttonFunc={() =>
                  this.setState({
                    showModal: true,
                  })
                }
                disablePagination={this.state.loading}
                page={this.state.page}
                limit={this.state.limit}
                total={this.state.total}
                prev={this.prevPage}
                next={this.nextPage}
                toPage={this.page}
              />
            )}
          </EuiPageBody>
        </EuiPage>
        {this.state.showModal ? (
          <EuiOverlayMask>
            <EuiModal
              onClose={() => this.closeModal()}
              initialFocus="[name=popswitch]">
              <EuiModalHeader>
                <EuiModalHeaderTitle>
                  Buat link untuk...
                </EuiModalHeaderTitle>
              </EuiModalHeader>

              <EuiModalFooter>
                <EuiButton onClick={() => this.save("PATIENT")} fill>
                  Pasien
                </EuiButton>
                <EuiButton onClick={() => this.save("DOCTOR")} fill>
                  Dokter
                </EuiButton>
                <EuiButton onClick={() => this.save("ASSISTANT")} fill>
                  Assistant
                </EuiButton>
                <EuiButton onClick={() => this.closeModal()}>
                  batal
                </EuiButton>
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        ) : null}
      </>
    );
  }
}

export default WeblinkList;
