import React, { Component } from 'react';
import { debounce } from 'lodash';
import {
  EuiPage,
  EuiPageBody,
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiForm,
  EuiFormRow,
  EuiSelect,
  EuiButton,
  EuiFilePicker,
  EuiButtonEmpty,
  EuiFieldText,
  EuiConfirmModal,
  EuiDatePicker,
  EuiFlexItem,
  EuiGlobalToastList,
  EuiComboBox,
} from '@elastic/eui';
import TablePage from '../components/TablePage';
import Patient from '../services/Patients';
import moment from 'moment';
import Organizations from '../services/Organizations';
import swal from 'sweetalert';
import Utils from '../Utils';
import ErrorService from '../services/Error';
import Datepicker from '../components/Datepicker';
import Config from '../Config';
import RegionCodes from '../services/RegionCodes';

const NusantaraValid = require('nusantara-valid');

const PatientService = new Patient();
const organizationServices = new Organizations();
const errorService = new ErrorService();
const patientUpdateRequiresMR = Config.patientUpdateRequiresMR;
const regionCodesService = new RegionCodes();


class Patients extends Component {
  constructor(props) {
    super(props);

    this.state = {
      patientType: this.identifyPatientType(),
      page: 1,
      limit: 10,
      order: 'ASC',
      search: '',
      loading: true,
      orderBy: 'name',
      data: [],
      column: [
        {
          field: 'externalId',
          name: 'Nomor Rekam Medis',
        },
        {
          field: 'name',
          name: 'Name',
        },
        {
          field: 'email',
          name: 'Email',
        },
        {
          field: 'phoneNumber',
          name: 'Nomor Telepon',
        },
        {
          field: 'genderString',
          name: 'Jenis Kelamin',
        },
        {
          field: 'dob',
          name: 'Tanggal Lahir',
        },
      ],
      file: null,
      selectedOrganizationId: '',
      organizationId: '',
      organizations: [],
      dob: moment.utc(),

      // Form
      name: '',
      email: '',
      phoneNumber: '',
      nik: '',
      externalId: '',
      addressName: '',
      rt: '',
      rw: '',
      selectedProvince: '',
      selectedCity: '',
      selectedDistrict: '',
      selectedSubDistrict: '',
      postalCode: '',
      parent: '',
      province: [],
      city: [],
      district: [],
      subDistrict: [],
      selectedProvinceName: '',
      selectedCityName: '',
      selectedDistrictName: '',
      selectedSubDistrictName: '',
      validationFields: {
        externalId: {
          type: 'string',
          isInvalid: false,
          errors: [''],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        organizationId: {
          type: 'string',
          isInvalid: false,
          errors: ['Tidak boleh kosong'],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        name: {
          type: 'string',
          isInvalid: false,
          errors: ['Tidak boleh kosong'],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        email: {
          type: 'string',
          isInvalid: false,
          errors: ['Tidak boleh kosong'],
          isValidFunc: function (value) {
            return true; // allowed to be empty
          },
        },
        phoneNumber: {
          type: 'string',
          isInvalid: false,
          errors: ['Tidak boleh kosong'],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        nik: {
          type: 'string',
          isInvalid: false,
          errors: ['Tidak boleh kurang dari 16 digit dan lebih dari 18 digit.','NIK tidak valid, silahkan coba lagi'],
          isValidFunc: function (value) {
            return value ? (value.length == 0 || (value.length >= 16 && value.length <= 18)) : true ;
          },
        },
        selectedGender: {
          type: 'string',
          isInvalid: false,
          errors: ['Tidak boleh kosong'],
          isValidFunc: function(value) {
            return value && value.length > 0 && value !== "0";
          },
        },
        addressName: {
          type: 'string',
          isInvalid: false,
          errors: ['Nama jalan tidak boleh kosong'],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        rt: {
          type: 'string',
          isInvalid: false,
          errors: ['RT tidak boleh kosong'],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        rw: {
          type: 'string',
          isInvalid: false,
          errors: ['RW tidak boleh kosong'],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        selectedProvince: {
          type: 'string',
          isInvalid: false,
          errors: ['Provinsi tidak boleh kosong'],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        selectedCity: {
          type: 'string',
          isInvalid: false,
          errors: ['Kota tidak boleh kosong'],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        selectedDistrict: {
          type: 'string',
          isInvalid: false,
          errors: ['Kecamatan tidak boleh kosong'],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        selectedSubDistrict: {
          type: 'string',
          isInvalid: false,
          errors: ['Keluarahan tidak boleh kosong'],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        postalCode: {
          type: 'string',
          isInvalid: false,
          errors: ['Kode pos tidak boleh kosong'],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
      },
      toasts: [],
      toastId: 0,
      gender: [
        {
          value: '0',
          text: 'Silakan pilih jenis kelamin'
        },
        {
          value: '1',
          text: 'Pria'
        },
        {
          value: '2',
          text: 'Wanita'
        },
      ],
      selectedGender: '0',
    };


  }

  componentDidMount = () => {
    const search = this.props.location.search;
    const searchIndex = search.search("q=");
    if (searchIndex !== -1) {
      const searchQuery = search.slice(searchIndex + 2);
      this.handleOnSearch(searchQuery);
    }
    this.page(1);
    this.loadOrganizations();
    this.loadProvinceRegionCodes();
  };

  loadOrganizations = () => {
    return new Promise((resolve, reject) => {
      let payload = {
        limit: 1000,
      };
      organizationServices
        .getOrganizations(payload)
        .then(response => {
          let items = [];
          if (this.state.isNewItem) {
            items.push({ value: '', text: 'Pilih organisasi...' });
          }
          response.items.forEach(item => {
            items.push({ value: item.id, text: item.name });
          });
          console.log(items)
          this.setState(
            {
              organizations: items,
              selectedOrganizationId: items[0].value,
            },
            () => {
              resolve();
            },
          );
        })
        .catch(err => {
          console.log(err);
          this.setState({
            organizations: [],
          });
          reject(err);
        });
    });
  };

  loadProvinceRegionCodes = () => {
    return new Promise((resolve, reject) => {
      let payload = {
        limit: 1000,
        order: 'ASC',
      };
      regionCodesService
        .getRegionCodes(payload)
        .then(response => {
          console.log(response)
          let items = [];
          response.data.forEach(item => {
            items.push({ value: item.code, text: item.name, label: item.name });
          });
          console.log(items)
          this.setState(
            {
              province: items,
            },
            () => {
              resolve();
            },
          );

        })
        .catch(err => {
          console.log(err);
          this.setState({
            organizations: [],
          });
          reject(err);
        });
    });
  };

  loadCityRegionCodes = () => {
    return new Promise((resolve, reject) => {
      let payload = {
        limit: 1000,
        order: 'ASC',
        parent: this.state.selectedProvince,
      };
      regionCodesService
        .getRegionCodes(payload)
        .then(response => {
          console.log(response)
          let items = [];
          response.data.forEach(item => {
            items.push({ value: item.code, text: item.name, label: item.name });
          });
          console.log(items)
          this.setState(
            {
              city: items,
            },
            () => {
              resolve();
            },
          );

        })
        .catch(err => {
          console.log(err);
          this.setState({
            organizations: [],
          });
          reject(err);
        });
    });
  };

  loadDistrictRegionCodes = () => {
    return new Promise((resolve, reject) => {
      let payload = {
        limit: 1000,
        order: 'ASC',
        parent: this.state.selectedCity,
      };
      regionCodesService
        .getRegionCodes(payload)
        .then(response => {
          console.log(response)
          let items = [];
          response.data.forEach(item => {
            items.push({ value: item.code, text: item.name, label: item.name });
          });
          console.log(items)
          this.setState(
            {
              district: items,
            },
            () => {
              resolve();
            },
          );

        })
        .catch(err => {
          console.log(err);
          this.setState({
            organizations: [],
          });
          reject(err);
        });
    });
  };

  loadSubDistrictRegionCodes = () => {
    return new Promise((resolve, reject) => {
      let payload = {
        limit: 1000,
        order: 'ASC',
        parent: this.state.selectedDistrict,
      };
      regionCodesService
        .getRegionCodes(payload)
        .then(response => {
          console.log(response)
          let items = [];
          response.data.forEach(item => {
            items.push({ value: item.code, text: item.name, label: item.name });
          });
          console.log(items)
          this.setState(
            {
              subDistrict: items,
            },
            () => {
              resolve();
            },
          );

        })
        .catch(err => {
          console.log(err);
          this.setState({
            organizations: [],
          });
          reject(err);
        });
    });
  };

  onChangeFile = file => {
    this.setState({ file });
  };

  onChangeOrganization = event => {
    let value = event.target.value || null;
    let name = '';
    for (let i in this.state.organizations) {
      if (this.state.organizations[i].value === value) {
        name =
          this.state.organizations[i].name || this.state.organizations[i].text;
      }
    }
    this.setState({
      organizationId: value,
      organizationName: name,
    });
  };

  submitFile = () => {
    this.setState({
      showModalImport:false,
      loading:true
    })
    const { organizationId, file, patientType } = this.state;
    let external = patientType == 'patients-external' && true
    PatientService.import({ organizationId: organizationId, file, external })
      .then(response => {
        swal({
          title: '',
          type: 'success',
          text: 'Data pasien berhasil diimpor',
        });
        this.setState(
          {
            file: {},
            selectedOrganizationId: '',
            organizations: [],
            loading:false
          },
          () => {
            this.closeModal();
            this.page();
          },
        );
      })
      .catch(err => {
        this.setState({
          loading:false
        })
        console.log(err);
        if (err.response) {
          swal({
            title: '',
            type: 'error',
            text:
              'Berkas CSV gagal diimpor. Silakan periksa kembali berkas CSV Anda.',
          });
        }
        this.closeModal();
      });
  };
  add = () => {
    // disable add external patient for now
    if (this.state.patientType === "patients-external") {
      return swal({
        title: '',
        type: 'error',
        text:
          'Mohon maaf, fitur belum tersedia',
      });
    }
    this.prepareNewItem();
  };
  addFile = () => {
    this.setState(
      {
        id: '',
        name: '',
        organizationId: '',
        organizationName: '',
        externalId: '',
        showModalImport: true,
        isNewItem: true,
      },
      () => {
        this.loadOrganizations();
      },
    );
  };
  closeModalImport = () => {
    this.setState({
      showModalImport: false,
    });
  };
  closeModal = () => {
    this.setState({
      showModal: false,
      isNewItem: false,
      id: '',
      name: '',
      organizationId: '',
      organizationName: '',
      externalId: '',
    });
  };

  page = page => {
    page = page || 1;
    this.setState({ loading: true });
    let payload = {
      page: page,
      limit: this.state.limit,
      search: this.state.search,
      order: this.state.order,
      orderBy: this.state.orderBy,
    };
    if (this.state.patientType === "patients-external") {
      payload.externalPatients = true;
    }
    PatientService.getPatients(payload)
      .then(result => {
        for (let i in result.items) {
          result.items[i].dob = moment
            .utc(result.items[i].dob)
            .format('DD-MM-YYYY');
          if (result.items[i].gender === 1) {
            result.items[i].genderString = 'Pria' 
          } else if (result.items[i].gender === 2) {
            result.items[i].genderString = 'Wanita' 
          }
        }
        console.log(result)
        this.setState(
          {
            page: result.page,
            limit: result.limit,
            total: result.total,
            order: result.order,
            orderBy: result.orderBy,
            data: result.items,
            loading: false,
          },
          () => {
            this.forceUpdate();
          },
        );
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loading: false,
          data: [],
        });
      });
  };

  handleOnSearch = value => {
    this.setState({ search: value, loading: true });
    this.handleSearchDebounce();
  };

  handleSearchDebounce = debounce(() => {
    this.page(1)
  }, 2000);

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };

  show = item => {
    this.setState({ id: item.id, name: item.name, showModal: true });
  };

  prepareNewItem = () => {
    this.setState(
      {
        id: '',
        name: '',
        email: '',
        phoneNumber: '',
        dob: moment.utc(),
        organizationId: '',
        organizationName: '',
        externalId: '',
        showModal: true,
        isNewItem: true,
      },
      () => {
        this.loadOrganizations();
      },
    );
  };
  handleChange = e => {
    let value = e.target.value || null;
    let obj = {};
    if (e.target.name === 'phoneNumber' && value !== null) {
      value = value.replace(/[^0-9]/g, '')
    }
    if (e.target.name === 'name' && value !== null) {
      value = value.replace(/[^a-zA-Z ]+/g, '');
    }
    obj[e.target.name] = value;
    obj['failedAttempt'] = false;
    let validationFields = { ...this.state.validationFields };
    if (validationFields[e.target.name]) {
      validationFields[e.target.name].isInvalid = false;
      obj.validationFields = validationFields;
    }
    this.setState(obj);
  };
  save = () => {
    let state = { ...this.state };
    this.setState({ saveLoading: true }, () => {
      Utils.validateFields(state).then(result => {
        this.setState({ validationFields: result.validationFields });
        this.forceUpdate();
        if (!result.isValid) {
          console.log(result);
          return;
        }

        let payload = {
          name: this.state.name,
          organizationId: this.state.organizationId,
          email: this.state.email,
          phoneNumber: Utils.reformatPhoneNumber(this.state.phoneNumber),
          nik: this.state.nik,
          dob: this.state.dob,
          externalId: this.state.externalId,
          gender: parseInt(this.state.selectedGender, 10),
          address: `${this.state.addressName},RT.${this.state.rt}/RW.${this.state.rw},${this.state.selectedProvinceName},${this.state.selectedCityName},${this.state.selectedDistrictName},${this.state.selectedSubDistrictName},${this.state.postalCode}`,
          province: this.state.selectedProvince,
          city: this.state.selectedCity,
          district: this.state.selectedDistrict,
          subDistrict: this.state.selectedSubDistrict,
          postalCode: this.state.postalCode,
          rt: this.state.rt,
          rw: this.state.rw,
        };

        if (this.state.id && this.state.id.length > 0) {
          payload.id = this.state.id;
          // Update existing
          PatientService.update(payload)
            .then(result => {
              this.setState({ saveLoading: false, loading: true });
              this.closeModal();
              this.page(1);
            })
            .catch(err => {
              this.setState({ saveLoading: false });
              errorService.handle(err);
            });
        } else {
          // Create new one
          PatientService.create(payload)
            .then(result => {
              this.setState({ saveLoading: false, loading: true });
              this.closeModal();
              this.page(1);
            })
            .catch(err => {
              console.log(err.response)
              this.setState({ saveLoading: false });
              if (err && err.response && err.response.data && err.response.data.message && err.response.data.message === "duplicate-phone-number") {
                this.showToast(0)
              }
              errorService.handle(err);
            });
        }
      });
    });
  };

  delete = () => {
    if (!(this.state.id && this.state.id.length > 0)) {
      return;
    }
    if (!window.confirm('Apakah Anda yakin ingin menghapus item ini?')) {
      return;
    }
    PatientService.delete(this.state.id)
      .then(result => {
        this.setState({ saveLoading: false, loading: true });
        this.closeModal();
        this.page(1);
      })
      .catch(err => {
        this.setState({ saveLoading: false });
        errorService.handle(err);
      });
  };
  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
      id: '',
      name: '',
      externalId: '',
    });
  };
  onItemClick = item => {
    // disable click external patient for now
    if (this.state.patientType === "patients-external") {
      return
    }
    this.props.history.push('/patient/detail/' + item.id);
  };
  onOrganizationChange = e => {
    let value = e.target.value || null;
    let name = '';
    for (let i in this.state.organizations) {
      if (this.state.organizations[i].value === value) {
        name =
          this.state.organizations[i].name || this.state.organizations[i].text;
      }
    }
    this.setState({
      organizationId: value,
      organizationName: name,
    });
  };
  dateChange = date => {
    let parsedDate = date.day + "-" + date.month + "-" + date.year
    this.setState({
      dob: moment.utc(parsedDate,'DD-MM-YYYY'),
    });
    console.log(this.state.dob)
  };
  onGenderChange = e => {
    let value = e.target.value || null;
    let name = '';
    let obj = {}
    for (let i in this.state.gender) {
      if (this.state.gender[i].value === value) {
        name = this.state.gender[i].name || this.state.gender[i].text;
      }
    }
    this.setState({
      selectedGender: value,
    });
    obj[e.target.name] = value;
    obj['failedAttempt'] = false;
    let validationFields = {...this.state.validationFields};
    if (validationFields[e.target.name]) {
      validationFields[e.target.name].isInvalid = false;
      obj.validationFields = validationFields;
    }
    this.setState(obj);
  };
  showToast = (select) => {
    const toast = [
      {
        title: "Error",
        color: "danger",
        iconType: "alert",
        text: (
          <div style={{ textAlign: 'left' }}>
            <p>
              Terdapat nomor telepon yang sama.
            </p>
            <p>
              Silakan cek kembali apakah data sudah benar dan periksa kembali jika ada nomor yang sama pada daftar pasien.
            </p>
          </div>
        ),
      },
    ]

    let selectedToast = this.state.toasts
    let toastId = this.state.toastId
    selectedToast.push({ id: toastId++, ...toast[select] })

    this.setState({ toasts: selectedToast, toastId: toastId })
  }

  removeToast = (removedToast) => {
    let toastsLeft = this.state.toasts.filter((toast) => { return toast.id !== removedToast.id })

    this.setState({ toasts: toastsLeft });
  };
  identifyPatientType = () => {
    let patientType = this.props.match.path.split("/")
    return patientType[1]
  }

  onProvinceChange = (e) => {
    this.setState({
      selectedOptionsProvince: e,
    });
    if (e.length > 0) {
      this.setState({
        selectedProvince: e[0].value,
        selectedOptionsProvince: e,
        selectedProvinceName: e[0].text,
      },() => {
        this.loadCityRegionCodes();
      });
    }
  };

  onCityChange = (e) => {
    this.setState({
      selectedOptionsCity: e,
    });
    if (e.length > 0) {
      this.setState({
        selectedCity: e[0].value,
        selectedOptionsCity: e,
        selectedCityName: e[0].text,
      },() => {
        this.loadDistrictRegionCodes();
      });
    }
  };

  onDistrictChange = (e) => {
    this.setState({
      selectedOptionsDistrict: e,
    });
    if (e.length > 0) {
      this.setState({
        selectedDistrict: e[0].value,
        selectedOptionsDistrict: e,
        selectedDistrictName: e[0].text,
      },() => {
        this.loadSubDistrictRegionCodes();
      });
    }
  };

  onSubDistrictChange = (e) => {
    this.setState({
      selectedOptionsSubDistrict: e,
    });
    if (e.length > 0) {
      this.setState({
        selectedSubDistrict: e[0].value,
        selectedOptionsSubDistrict: e,
        selectedSubDistrictName: e[0].text,
      });
    }
  };

  render() {
    const dobMoment = moment(this.state.dob);
    return (
      <>
        <EuiPage className="class">
          <EuiPageBody
            style={{ flexDirection: 'row' }}
            className={'content-container'}>
            <TablePage
              title={this.state.patientType === 'patients-external' ? "Pasien Eksternal" : 'Pasien'}
              placeholder={'Nama Pasien'}
              searchComp={
                <EuiFlexItem>
                  <EuiFieldText
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        this.handleOnSearch(this.state.search);
                      }
                    }}
                    value={this.state.search}
                    onChange={e => this.handleOnSearch(e.target.value)}
                    placeholder={this.props.placeholder}
                    append={
                      <EuiButtonEmpty
                        onClick={() => this.handleOnSearch(this.state.search)}>
                        Cari
                      </EuiButtonEmpty>
                    }
                  />
                </EuiFlexItem>
              }
              buttonImportLabel={this.state.patientType === 'patients-external' ? "Impor Pasien Eksternal" : 'Impor Pasien'}
              buttonImportFunc={() => this.addFile()}
              data={this.state.data}
              column={this.state.column}
              location={this.props.location}
              disablePagination={this.state.loading}
              loading={this.state.loading}
              page={this.state.page}
              limit={this.state.limit}
              total={this.state.total}
              prev={this.prevPage}
              next={this.nextPage}
              toPage={this.page}
              onItemClick={this.onItemClick}
            />
          </EuiPageBody>
          <EuiGlobalToastList
            toasts={this.state.toasts}
            dismissToast={this.removeToast}
            toastLifeTimeMs={5000}
          />
        </EuiPage>
        {this.state.showDeleteModal ? (
          <EuiOverlayMask>
            <EuiConfirmModal
              title="Hapus"
              onCancel={this.closeDeleteModal}
              onConfirm={this.closeDeleteModal}
              cancelButtonText="Batal"
              confirmButtonText="Hapus"
              buttonColor="danger"
              defaultFocusedButton="confirm">
              <p>Apakah anda ingin menghapus item ini ?</p>
            </EuiConfirmModal>
          </EuiOverlayMask>
        ) : null}

        {this.state.showModal && (
          <EuiOverlayMask>
            <EuiModal
              onClose={() => this.closeModal()}
              initialFocus="[name=popswitch]">
              <EuiModalHeader>
                <EuiModalHeaderTitle>Pasien</EuiModalHeaderTitle>
              </EuiModalHeader>

              <EuiModalBody>
                <EuiForm>
                  <EuiFormRow
                    label="Organisasi"
                    isInvalid={
                      this.state.validationFields['organizationId'].isInvalid
                    }
                    error={
                      this.state.validationFields['organizationId'].isInvalid &&
                      this.state.validationFields['organizationId'].errors
                    }>
                    <EuiSelect
                      placeholder="Organisasi"
                      options={this.state.organizations}
                      value={this.state.organizationId}
                      name="organizationName"
                      onChange={this.onOrganizationChange}
                      aria-label="Organisasi"
                    />
                  </EuiFormRow>
                  {this.state.organizationId &&
                    this.state.organizationId.length > 0 &&
                    this.state.organizationName &&
                    this.state.organizationName.length > 0 && (
                      <div style={{ marginTop: 15 }}>
                        <EuiFormRow
                          label="Nama"
                          isInvalid={
                            this.state.validationFields['name'].isInvalid
                          }
                          error={
                            this.state.validationFields['name'].isInvalid &&
                            this.state.validationFields['name'].errors
                          }>
                          <EuiFieldText
                            placeholder="Nama"
                            value={this.state.name}
                            name="name"
                            onChange={this.handleChange}
                            aria-label="name"
                          />
                        </EuiFormRow>
                        {  patientUpdateRequiresMR !== false && <EuiFormRow
                          label="Nomor Rekam Medis"
                          isInvalid={
                            this.state.validationFields['externalId'].isInvalid
                          }
                          error={
                            this.state.validationFields['externalId'].isInvalid
                          }>
                          <EuiFieldText
                            placeholder="Nomor Rekam Medis"
                            value={this.state.externalId}
                            name="externalId"
                            onChange={this.handleChange}
                            aria-label="externalId"
                          />
                        </EuiFormRow> }

                        <EuiFormRow
                          label="Surel"
                          isInvalid={
                            this.state.validationFields['email'].isInvalid
                          }
                          error={
                            this.state.validationFields['email'].isInvalid
                          }>
                          <EuiFieldText
                            placeholder="Surel"
                            value={this.state.email}
                            name="email"
                            onChange={this.handleChange}
                            aria-label="email"
                          />
                        </EuiFormRow>

                        <EuiFormRow
                          label="Nomor Telepon"
                          isInvalid={
                            this.state.validationFields['phoneNumber'].isInvalid
                          }
                          error={
                            this.state.validationFields['phoneNumber']
                              .isInvalid &&
                            this.state.validationFields['phoneNumber'].errors
                          }>
                          <EuiFieldText
                            placeholder="Telepon"
                            value={this.state.phoneNumber}
                            name="phoneNumber"
                            onChange={this.handleChange}
                            aria-label="phoneNumber"
                          />
                        </EuiFormRow>
                        <EuiFormRow
                          label="NIK"
                          isInvalid={
                            this.state.validationFields['nik'].isInvalid
                          }
                          error={
                            this.state.validationFields['nik']
                              .isInvalid &&
                            this.state.validationFields['nik'].errors
                          }>
                          <EuiFieldText
                            placeholder="NIK"
                            value={this.state.nik}
                            name="nik"
                            onChange={this.handleChange}
                            aria-label="nik"
                          />
                        </EuiFormRow>
                        {console.log(this.state.dob)}
                        <EuiFormRow label="Tanggal lahir">
                          <Datepicker
                            onChange={date => this.dateChange(date)}
                            value={dobMoment}
                          />
                        </EuiFormRow>
                        <EuiFormRow
                          label="Jenis Kelamin"
                          isInvalid={
                            this.state.validationFields['selectedGender'].isInvalid
                          }
                          error={
                            this.state.validationFields['selectedGender'].isInvalid &&
                            this.state.validationFields['selectedGender'].errors
                          }>
                          <EuiSelect
                            placeholder="gender"
                            options={this.state.gender}
                            value={this.state.selectedGender}
                            name="selectedGender"
                            onChange={this.onGenderChange}
                            aria-label="Jenis Kelamin"
                          />
                        </EuiFormRow>
                        <EuiFormRow
                          label="Nama Jalan"
                          isInvalid={
                            this.state.validationFields['addressName'].isInvalid
                          }
                          error={
                            this.state.validationFields['addressName']
                              .isInvalid &&
                            this.state.validationFields['addressName'].errors
                          }>
                          <EuiFieldText
                            placeholder="Nama Jalan"
                            value={this.state.addressName}
                            name="addressName"
                            onChange={this.handleChange}
                            aria-label="addressName"
                          />
                        </EuiFormRow>
                        <EuiFormRow
                          label="RT"
                          isInvalid={
                            this.state.validationFields['rt'].isInvalid
                          }
                          error={
                            this.state.validationFields['rt']
                              .isInvalid &&
                            this.state.validationFields['rt'].errors
                          }>
                          <EuiFieldText
                            placeholder="RT"
                            value={this.state.rt}
                            name="rt"
                            onChange={this.handleChange}
                            aria-label="rt"
                          />
                        </EuiFormRow>
                        <EuiFormRow
                          label="RW"
                          isInvalid={
                            this.state.validationFields['rw'].isInvalid
                          }
                          error={
                            this.state.validationFields['rw']
                              .isInvalid &&
                            this.state.validationFields['rw'].errors
                          }>
                          <EuiFieldText
                            placeholder="RW"
                            value={this.state.rw}
                            name="rw"
                            onChange={this.handleChange}
                            aria-label="rw"
                          />
                        </EuiFormRow>
                        <EuiFormRow
                          label="Provinsi"
                          isInvalid={
                            this.state.validationFields['selectedProvince'].isInvalid
                          }
                          error={
                            this.state.validationFields['selectedProvince'].isInvalid &&
                            this.state.validationFields['selectedProvince'].errors
                          }
                        >
                          <div>
                            <EuiComboBox
                              placeholder="Provinsi"
                              options={this.state.province}
                              name="province"
                              onChange={(e) => {
                                this.onProvinceChange(e);
                              }}
                              value={this.state.selectedProvince}
                              isClearable={true}
                              aria-label="Provinsi"
                              singleSelection={{ asPlainText: true }}
                              selectedOptions={this.state.selectedOptionsProvince}
                            />
                          </div>
                        </EuiFormRow>
                        <EuiFormRow
                          label="Kota"
                          isInvalid={
                            this.state.validationFields['selectedCity'].isInvalid
                          }
                          error={
                            this.state.validationFields['selectedCity'].isInvalid &&
                            this.state.validationFields['selectedCity'].errors
                          }
                        >
                          <div>
                            <EuiComboBox
                              placeholder="Kota"
                              options={this.state.city}
                              name="city"
                              onChange={(e) => {
                                this.onCityChange(e);
                              }}
                              value={this.state.selectedCity}
                              isClearable={true}
                              aria-label="Kota"
                              singleSelection={{ asPlainText: true }}
                              selectedOptions={this.state.selectedOptionsCity}
                              isDisabled={this.state.selectedProvince.length === 0}
                            />
                          </div>
                        </EuiFormRow>
                        <EuiFormRow
                          label="Kecamatan"
                          isInvalid={
                            this.state.validationFields['selectedDistrict'].isInvalid
                          }
                          error={
                            this.state.validationFields['selectedDistrict'].isInvalid &&
                            this.state.validationFields['selectedDistrict'].errors
                          }
                        >
                          <div>
                            <EuiComboBox
                              placeholder="Kecamatan"
                              options={this.state.district}
                              name="district"
                              onChange={(e) => {
                                this.onDistrictChange(e);
                              }}
                              value={this.state.selectedDistrict}
                              isClearable={true}
                              aria-label="Kecamatan"
                              singleSelection={{ asPlainText: true }}
                              selectedOptions={this.state.selectedOptionsDistrict}
                              isDisabled={this.state.selectedCity.length === 0}
                            />
                          </div>
                        </EuiFormRow>
                        <EuiFormRow
                          label="Kelurahan"
                          isInvalid={
                            this.state.validationFields['selectedSubDistrict'].isInvalid
                          }
                          error={
                            this.state.validationFields['selectedSubDistrict'].isInvalid &&
                            this.state.validationFields['selectedSubDistrict'].errors
                          }
                        >
                          <div>
                            <EuiComboBox
                              placeholder="Kelurahan"
                              options={this.state.subDistrict}
                              name="subDistrict"
                              onChange={(e) => {
                                this.onSubDistrictChange(e);
                              }}
                              value={this.state.selectedSubDistrict}
                              isClearable={true}
                              aria-label="Kelurahan"
                              singleSelection={{ asPlainText: true }}
                              selectedOptions={this.state.selectedOptionsSubDistrict}
                              isDisabled={this.state.selectedDistrict.length === 0}
                            />
                          </div>
                        </EuiFormRow>
                        <EuiFormRow
                          label="Kode Pos"
                          isInvalid={
                            this.state.validationFields['postalCode'].isInvalid
                          }
                          error={
                            this.state.validationFields['postalCode']
                              .isInvalid &&
                            this.state.validationFields['postalCode'].errors
                          }>
                          <EuiFieldText
                            placeholder="Kode Pos"
                            value={this.state.postalCode}
                            name="postalCode"
                            onChange={this.handleChange}
                            aria-label="postalCode"
                          />
                        </EuiFormRow>
                      </div>
                    )}
                </EuiForm>
              </EuiModalBody>

              <EuiModalFooter>
                <EuiButtonEmpty onClick={() => this.closeModal()}>
                  Batal
                </EuiButtonEmpty>
                {this.state.id && (
                  <EuiButton
                    color={'danger'}
                    onClick={() => this.delete()}
                    fill>
                    Hapus
                  </EuiButton>
                )}
                <EuiButton onClick={() => this.save()} fill>
                  Simpan
                </EuiButton>
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        )}

        {this.state.showModalImport && (
          <EuiOverlayMask>
            <EuiModal
              onClose={() => this.closeModalImport()}
              initialFocus="[name=popswitch]">
              <EuiModalHeader>
                <EuiModalHeaderTitle>Import Data</EuiModalHeaderTitle>
              </EuiModalHeader>

              <EuiModalBody>
                <EuiForm>
                  <EuiFormRow
                    label="Organisasi"
                    isInvalid={
                      this.state.validationFields['organizationId'].isInvalid
                    }
                    error={
                      this.state.validationFields['organizationId'].isInvalid &&
                      this.state.validationFields['organizationId'].errors
                    }>
                    <EuiSelect
                      placeholder="Organisasi"
                      options={this.state.organizations}
                      value={this.state.organizationId}
                      name="organizationName"
                      onChange={this.onOrganizationChange}
                      aria-label="Organisasi"
                    />
                  </EuiFormRow>
                  <h5 style={{ margin: 12 }}>Pilih File CSV</h5>
                  <EuiFilePicker
                    id="importPatientData"
                    multiple
                    initialPromptText="Pilih atau seret dan lepas berkas"
                    onChange={file => {
                      this.onChangeFile(file);
                    }}
                    display="large"
                    aria-label="Use aria labels when no actual label is in use"
                  />
                </EuiForm>
              </EuiModalBody>

              <EuiModalFooter>
                <EuiButtonEmpty onClick={() => this.closeModalImport()}>
                  Batal
                </EuiButtonEmpty>
                <EuiButton fill onClick={() => this.submitFile()} disabled={!(this.state.organizationId.length > 0 && this.state.file !== null)}>
                  Proses
                </EuiButton>
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        )}
      </>
    );
  }
}

export default Patients;
