import React, { Component } from "react";

import { debounce } from 'lodash';
import { EuiPage, EuiPageBody, EuiFlexItem, EuiFieldText, EuiButtonEmpty } from "@elastic/eui";
import TablePage from "../components/TablePage";
import OrderService from '../services/Orders';

const orderService = new OrderService();

class Orders extends Component {
  state = {
    page: 1,
    limit: 10,
    order: 'ASC',
    search: '',
    loading: false,
    orderBy: 'name',
    data: [],
    column: [
      {
        field: 'id',
        name: 'ID',
      },
      {
        field: 'number',
        name: 'Purchase number',
      },
      {
        field: 'created_date',
        name: 'Purchase date',
      },
    ],
  };

  handleOnSearch = value => {
    this.setState({ search: value });
    this.handleSearchDebounce();
  };

  handleSearchDebounce = debounce(() => {
    this.page(1)
  }, 2000);

  page = page => {
    page = page || 1;
    this.setState({loading: true});
    let payload = {
      page: page,
      limit: this.state.limit,
      search: this.state.search,
      order: this.state.order,
      orderBy: this.state.orderBy,
    };
    orderService
      .getOrders(payload)
      .then(result => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          order: result.order,
          orderBy: result.orderBy,
          data: result.Orders || [],
          loading: false,
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loading: false,
          data: [],
        });
      });
  };

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };
  componentDidMount = () => {
    this.page(1);
  };


  render() {
    return (
      <>
        <EuiPage className="class">
          <EuiPageBody
            style={{ flexDirection: "row" }}
            className={"content-container"}
          >
          <TablePage
              title={this.state.patientType === 'patients-external' ? "Pasien Eksternal" : 'Pasien'}
              placeholder={'Nama Pasien'}
              searchComp={
                <EuiFlexItem>
                  <EuiFieldText
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        this.search(this.state.search);
                      }
                    }}
                    value={this.state.search}
                    onChange={e => this.handleOnSearch(e.target.value)}
                    placeholder={this.props.placeholder}
                    append={
                      <EuiButtonEmpty
                        onClick={() => this.search(this.state.search)}>
                        Cari
                      </EuiButtonEmpty>
                    }
                  />
                </EuiFlexItem>
              }
              data={this.state.data}
              column={this.state.column}
              location={this.props.location}
              disablePagination={this.state.loading}
              loading={this.state.loading}
              page={this.state.page}
              limit={this.state.limit}
              total={this.state.total}
              prev={this.prevPage}
              next={this.nextPage}
              toPage={this.page}
              onItemClick={this.onItemClick}
            />
          </EuiPageBody>
        </EuiPage>
      </>
    );
  }
}

export default Orders;
