import Config from '../Config';
import axios from 'axios';
import Error from './Error';
import Misc from './Misc';

const ErrorService = new Error();
const MiscService = new Misc();

class ThirdpartyLogs {
  getThirdpartyLogs = payload => {
      return new Promise((resolve, reject) => {
      payload = payload || {};
      payload.limit = payload.limit || 10;
      payload.order = payload.Order || 'DESC';
      payload.search = payload.search || '';
      payload.page = payload.page || '';
      payload.startDate = payload.startDate || '';
      payload.endDate = payload.endDate || '';
        let url = `${Config.apiHost}/api/thirdparty/logs?page=${payload.page}&limit=${payload.limit}&order=${payload.order}`;

        if (payload.search !== '') {
          url += `&search=${payload.search}`;
        }

        if (payload.startDate !== '' && payload.endDate !== '') {
          url += `&start_date=${payload.startDate}&end_date=${payload.endDate}`;
        }
        axios
          .get(url, { headers: MiscService.generateHeaders() })
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            reject(err);
            ErrorService.handle(err);
          });
      });
    };
    
    getThirdpartyLogsById = id => {
      return new Promise((resolve, reject) => {
          let url = `${Config.apiHost}/api/thirdparty/logs/${id}`;
          axios
            .get(url, { headers: MiscService.generateHeaders() })
            .then(response => {
              resolve(response.data);
            })
            .catch(err => {
              reject(err);
              ErrorService.handle(err);
            });
        });
    };
  
    
}

export default ThirdpartyLogs;
