import Config from "../Config";
import axios from "axios";
import Error from './Error';
import Misc from "./Misc";
import Utils from '../Utils';

const ErrorService = new Error();
const MiscService = new Misc();
class Staffs {

  getStaffs = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {}
      payload.page = payload.page || 1
      payload.limit = payload.limit || 10
      payload.order = payload.Order || "ASC"
      payload.search = payload.search || ""
      payload.type = payload.type || ""
      let url = `${Config.apiHost}/api/staffs?page=${payload.page}&limit=${payload.limit}&order=${payload.order}&type=${payload.type}&search=${payload.search}`
      if (payload.search && payload.search.length > 0) {
        url += `&search=${payload.search}`
      }
      if (payload.clinicId && payload.clinicId.length > 0) {
        url += `&clinicId=${payload.clinicId}`
      }
      if (payload.organizationId && payload.organizationId.length > 0) {
        url += `&organizationId=${payload.organizationId}`
      }
      axios.get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          let data = Utils.camelCase(response.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err)
          ErrorService.handle(err)
        })
    });
  }

  getStaff = (uuid) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/staff/${uuid}`
      axios.get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          let data = Utils.camelCase(response.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err)
          ErrorService.handle(err)
        })
    });
  }

  create = payload => {
    console.log(payload)
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload = Utils.snakeCase(payload);
      let url = `${Config.apiHost}/api/staffs`;
      axios
        .post(url, payload, { headers: MiscService.generateHeaders() })
        .then(response => {
          let data = Utils.camelCase(response.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  update = payload => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload = Utils.snakeCase(payload);
      let url = `${Config.apiHost}/api/staff/${payload.id}`;
      axios
        .put(url, payload, { headers: MiscService.generateHeaders() })
        .then(response => {
          let data = Utils.camelCase(response.data);
          resolve(data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  delete = id => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/staff/${id}`;
      axios
        .delete(url, { headers: MiscService.generateHeaders() })
        .then(response => {
          let data = Utils.camelCase(response.data);
          resolve(data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  import = payload => {
    const {organizationId, file} = payload;
    const url = `${Config.apiHost}/api/staff/import`
    
    const formData = new FormData;
    formData.append('organization_id', organizationId);
    formData.append('file', file[0]);

    return new Promise((resolve, reject) => {
      axios
        .post(url, formData, { headers: MiscService.generateHeaders() })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err)
          ErrorService.handle(err);
        });
    })
  }
  uploadAvatarFile = file => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/staff/upload`;
      var formData = new FormData();
      formData.append("file", file)
      axios
        .post(url, formData, { headers: MiscService.generateHeaders() })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  getFile = blobId => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/blob/${blobId}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          console.log(err.response)
          if (err.response.status !== 404 && !err.response.data.code.toString().includes("The specified key does not exist")) {
            ErrorService.handle(err);
          }
        });
    });
  };

}

export default Staffs;
