import React, { Component } from "react";

import {
  EuiPageContent,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiPageContentBody,
  EuiTitle,
  EuiFieldText,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiPage,
  EuiPageBody,
  EuiFormRow,
  EuiFilePicker,
  EuiSpacer,
  EuiTab,
  EuiTabs,
} from "@elastic/eui";
import Table from "../components/Table";

class Hospital extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabId: "info",
      data: [],
      column: [],
      bankData: [],
      bankColumn: [],
      nameHospital: "",
      phone: "",
      address: "",
      location: "",
      email: "",
      accountName: "",
      bankName: "",
      bankBranch: "",
      accountNumber: "",
      validationFields: {
        accountName: {
          type: "string",
          isInvalid: false,
          errors: ["Nama Akun tidak boleh kosong"],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        bankName: {
          type: "string",
          isInvalid: false,
          errors: ["Nama Bank tidak boleh kosong"],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        bankBranch: {
          type: "string",
          isInvalid: false,
          errors: ["Nama Cabang tidak boleh kosong"],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        accountNumber: {
          type: "string",
          isInvalid: false,
          errors: ["Nomor Rekening tidak boleh kosong"],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        nameHospital: {
          type: "string",
          isInvalid: false,
          errors: ["Nama Rumah Sakit tidak boleh kosong"],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        phone: {
          type: "string",
          isInvalid: false,
          errors: ["Nomor Telepon tidak boleh kosong"],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        address: {
          type: "string",
          isInvalid: false,
          errors: ["Alamat tidak boleh kosong"],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        location: {
          type: "string",
          isInvalid: false,
          errors: ["Lokasi tidak boleh kosong"],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        email: {
          type: "string",
          isInvalid: false,
          errors: ["Email tidak boleh kosong"],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
      },
    };
  }

  initLicenseTable() {
    const column = [
      {
        field: "accountName",
        name: "Alias",
      },
      {
        field: "bankName",
        name: "Bank",
      },
      {
        field: "accountNumber",
        name: "Nomor",
      },
      {
        field: "action",
        name: "Aksi",
      },
    ];

    this.setState({ bankColumn: column });
  }

  componentDidMount() {
    this.initLicenseTable();
  }

  handleChange = (e) => {
    let value = e.target.value || null;
    let obj = {};
    obj[e.target.name] = value;
    obj["failedAttempt"] = false;
    let validationFields = { ...this.state.validationFields };
    if (validationFields[e.target.name]) {
      validationFields[e.target.name].isInvalid = false;
      obj.validationFields = validationFields;
    }
    this.setState(obj);
  };

  onSelectedTabChanged = (id) => {
    this.setState({ selectedTabId: id });
  };

  renderTabs = () => {
    const tabs = [
      {
        id: "info",
        name: "Info",
        disabled: false,
      },
      {
        id: "bankAccount",
        name: "Rekening",
        disabled: false,
      },
    ];
    return tabs.map((tab, index) => (
      <EuiTab
        {...(tab.href && { href: tab.href, target: "_blank" })}
        onClick={() => this.onSelectedTabChanged(tab.id)}
        isSelected={tab.id === this.state.selectedTabId}
        disabled={tab.disabled}
        key={index}
      >
        {tab.name}
      </EuiTab>
    ));
  };

  renderTabsContent = () => {
    switch (this.state.selectedTabId) {
      case "info":
        return (
          <EuiPageContentBody>
            <EuiForm className="form-layout" id="formHospital">
              <EuiFormRow label="Nama">
                <EuiFieldText
                  name="nameHospital"
                  placeholder="Nama"
                  value={this.state.nameHospital}
                  onChange={this.handleChange}
                />
              </EuiFormRow>
              <EuiFormRow label="Telepon">
                <EuiFieldText
                  name="phone"
                  placeholder="Telepon"
                  value={this.state.phone}
                  onChange={this.handleChange}
                />
              </EuiFormRow>
              <EuiFormRow label="Alamat">
                <EuiFieldText
                  name="address"
                  placeholder="Alamat"
                  value={this.state.address}
                  onChange={this.handleChange}
                />
              </EuiFormRow>
              <EuiFormRow label="Lokasi">
                <EuiFieldText
                  name="location"
                  placeholder="Lokasi"
                  value={this.state.location}
                  onChange={this.handleChange}
                />
              </EuiFormRow>
              <EuiFormRow label="Surel">
                <EuiFieldText
                  name="email"
                  placeholder="Surel"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </EuiFormRow>
              <EuiFormRow label="Ikon">
                <EuiFilePicker name="icon" id="iconFilePicker" />
              </EuiFormRow>
              <EuiSpacer size="l" />
              <EuiFlexGroup justifyContent={"flexEnd"}>
                <EuiFlexItem grow={false}>
                  <EuiButton fill color="danger">
                    Hapus
                  </EuiButton>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButton fill>Ubah</EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiForm>
          </EuiPageContentBody>
        );
      case "bankAccount":
        return (
          <EuiPageContentBody>
            <EuiForm className="form-layout" id="formLisensi">
              <EuiFormRow label="Alias">
                <EuiFieldText
                  name="accountName"
                  placeholder="Nama akun"
                  value={this.state.accountName}
                  onChange={this.handleChange}
                />
              </EuiFormRow>
              <EuiFormRow label="Bank">
                <EuiFieldText
                  name="bankName"
                  placeholder="Bank"
                  value={this.state.bankName}
                  onChange={this.handleChange}
                />
              </EuiFormRow>
              <EuiFormRow label="Cabang">
                <EuiFieldText
                  name="bankBranch"
                  placeholder="Nama Cabang"
                  value={this.state.bankBranch}
                  onChange={this.handleChange}
                />
              </EuiFormRow>
              <EuiFormRow label="Nomor">
                <EuiFieldText
                  name="accountNumber"
                  placeholder="Nomor Rekening"
                  value={this.state.accountNumber}
                  onChange={this.handleChange}
                />
              </EuiFormRow>
              <EuiSpacer size="l" />
              <EuiFlexGroup justifyContent={"flexEnd"}>
                <EuiFlexItem grow={false}>
                  <EuiButton
                    fill
                  >
                    Tambah
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiForm>
            <EuiSpacer />
            <Table data={this.state.bankData} columns={this.state.bankColumn} />
          </EuiPageContentBody>
        );
      default:
        break;
    }
  };

  render() {
    return (
      <>
        <EuiPage className="euiNavDrawerPage">
          <EuiPageBody className={"content-container"}>
            <EuiPageContent>
              <EuiPageContentHeader>
                <EuiPageContentHeaderSection>
                  <EuiTitle>
                    <h2>Rumah Sakit</h2>
                  </EuiTitle>
                </EuiPageContentHeaderSection>
                <EuiPageContentHeaderSection>
                  <EuiFlexGroup>
                    {this.props.buttonLabel ? (
                      <EuiFlexItem grow={false}>
                        <EuiButton fill onClick={this.props.butonFunc}>
                          {this.props.buttonLabel}
                        </EuiButton>
                      </EuiFlexItem>
                    ) : null}
                  </EuiFlexGroup>
                </EuiPageContentHeaderSection>
              </EuiPageContentHeader>
              <EuiTabs>{this.renderTabs()}</EuiTabs>
              {this.renderTabsContent()}
            </EuiPageContent>
          </EuiPageBody>
        </EuiPage>
      </>
    );
  }
}

export default Hospital;
