import Config from "../Config";
import axios from "axios";
import Error from './Error';
import Misc from "./Misc";

const ErrorService = new Error();
const MiscService = new Misc();

class Recordings {
  getRecordingBySessionID = (id) => {
    return new Promise((resolve, reject) =>{
        axios.get(Config.apiHost+'/api/recording-by-session/'+id, {headers: MiscService.generateHeaders()})
        .then((response) => {
            resolve(response.data)
        })
        .catch((err) => {
          reject(err)
          ErrorService.handle(err)        
        });
      });
    }
}
export default Recordings;
