import React, { Component } from 'react';

import {
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiButtonEmpty,
  EuiFieldText,
  EuiPanel,
  EuiTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiDatePicker,
  EuiHorizontalRule,
  EuiSpacer,
  EuiCard,
  EuiDatePickerRange,
  EuiFormRow,
  EuiSelect,
  EuiLoadingChart,
} from '@elastic/eui';

import OverviewService from '../services/SummaryOverview.js';
import moment from 'moment';
import VariantService from '../services/Variants.js';
import Chart from 'react-apexcharts';

const variantService = new VariantService();
const Service = new OverviewService();

const initialOptions = {
  dataLabels: {
    enabled: true,
  },
  stroke: {
    show: true,
    width: 4,
    colors: ['transparent'],
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  xaxis: {
    labels: {
      show: false,
    },
  },
  fill: {
    opacity: 1,
  },
  // tooltip: {
  //   y: {
  //     formatter: function (val) {
  //       return '$ ' + val + ' thousands';
  //     },
  //   },
  // },
};

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // response data
      session_summary: {},
      prescription_summary: {},
      diagnostic_summary: {},

      variant: '',
      variantName: '',

      startDateDaily: moment().subtract(7, 'days'),
      endDateDaily: moment(),
      loadingAll: true,
      loadingData: true,
      optionsTransaction: {
        ...initialOptions,
        dataLabels: {
          enabled: false,
        },

        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
          },
        },
        // xaxis: {
        //   categories: [],
        // },
      },
      optionsPharmacy: {
        ...initialOptions,
        labels: [],
        title: {
          text: 'Apotek dengan transaksi terbanyak',
          style: { color: 'black', fontWeight: 'bold' },
        },
        plotOptions: {
          pie: {
            donut: {
              background: 'white',
              labels: {
                show: true,

                total: {
                  show: true,
                  showAlways: false,
                },
                // value: {
                //   formatter: (value) => {
                //     return formatMoney('IDR', value);
                //   },
                // },
                // total: {
                //   show: true,
                //   formatter: (value) => {
                //     return formatMoney('IDR', report.total_idr);
                //   },
                // },
              },
            },
          },
        },
      },
      optionsMedicine: {
        ...initialOptions,
        labels: [],
        title: {
          text: 'Obat dengan transaksi terbanyak',
          style: { color: 'black', fontWeight: 'bold' },
        },
        plotOptions: {
          pie: {
            donut: {
              background: 'white',
              labels: {
                show: true,
                total: {
                  show: true,
                  showAlways: false,
                },
                // value: {
                //   formatter: (value) => {
                //     return formatMoney('IDR', value);
                //   },
                // },
                // total: {
                //   show: true,
                //   formatter: (value) => {
                //     return formatMoney('IDR', report.total_idr);
                //   },
                // },
              },
            },
          },
        },
      },
      seriesTransaction: [
        {
          name: 'Sesi konsultasi telah selesai',
          data: [],
          color: '#AAAAAA',
        },
        {
          name: 'Transaksi peresepan KF telah selesai',
          data: [],
          color: '#009944',
        },
        {
          name: 'Transaksi peresepan KF gagal',
          data: [],
          color: '#CF000F',
        },
      ],
      seriesPharmacy: [],
      seriesMedicine: [],
    };
  }

  componentDidMount() {
    let variant = this.props.match.params.variant;
    if (!variant || (variant && variant.length < 1)) {
      this.props.history.push('/');
      return;
    }

    variantService.getVariants().then((result) => {
      for (let i in result.items) {
        if (result.items[i].variant === variant) {
          console.log(result.items[i]);
          this.setState({
            variantName: result.items[i].name,
            variant: variant,
          });
          this.onFetchDataAll(variant);
          this.onFetchData(variant);
          break;
        }
      }
    });
  }

  onFetchDataAll = (variant) => {
    variant = variant || this.state.variant;

    Service.getOverview({
      variant: variant,
      type: 'all',
    })
      .then((result) => {
        // example data
        /* 
        {
          "total_patients": 32,
          "total_connected_kf_patients": 12,
          "total_prescription_doctor": 58,
          "total_average_session_per_week": 29.46153846153846
        }
        */
        this.setState({
          ...result,
          loadingAll: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loadingAll: false,
        });
      });
  };

  onFetchData = async (variant) => {
    variant = variant || this.state.variant;

    let startDate = this.state.startDateDaily.clone();
    let endDate = this.state.endDateDaily.clone().add(1, 'days');

    try {
      let summaries = await Service.getSummaries({
        variant: variant,
        type: 'daily',
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
      });
      // example data
      /*
        {
          "type": "daily",
          "total_sessions": 46,
          "total_prescription_success": 0,
          "total_prescription_fail": 6,
          "sessions": {
              "2022-11-14": 14,
              "2022-11-15": 6,
              "2022-11-16": 14,
              "2022-11-17": 6,
              "2022-11-18": 4,
              "2022-11-19": 2
          },
          "prescription_success": {},
          "prescription_fail": {
              "2022-11-16": 5,
              "2022-11-18": 1
          }
        }
      */

      // set data
      // append the key list sessions, prescription success, and prescription fail to categories
      let categories = [];

      // iterate each object
      for (let key in summaries.sessions) {
        // check if the key is not in the categories
        if (categories.indexOf(key) < 0) {
          // push the key to the categories
          categories.push(key);
        }
      }

      // same goes for prescriptions
      for (let key in summaries.prescription_success) {
        if (categories.indexOf(key) < 0) {
          categories.push(key);
        }
      }

      for (let key in summaries.prescription_fail) {
        if (categories.indexOf(key) < 0) {
          categories.push(key);
        }
      }

      // sort the categories
      categories.sort((a, b) => {
        // parse to moment
        let aMoment = moment(a, 'YYYY-MM-DD');
        let bMoment = moment(b, 'YYYY-MM-DD');

        // compare the moment
        return aMoment.isBefore(bMoment) ? -1 : 1;
      });

      // set the values
      let sessions = [];
      let prescription_success = [];
      let prescription_fail = [];

      // iterate each category
      for (let i in categories) {
        // get the key
        let key = categories[i];

        // check if the key is in the sessions
        if (key in summaries.sessions) {
          // push the value to the sessions
          sessions.push(summaries.sessions[key]);
        } else {
          // push 0 to the sessions
          sessions.push(0);
        }

        // same goes for prescription
        if (key in summaries.prescription_success) {
          prescription_success.push(summaries.prescription_success[key]);
        } else {
          prescription_success.push(0);
        }

        if (key in summaries.prescription_fail) {
          prescription_fail.push(summaries.prescription_fail[key]);
        } else {
          prescription_fail.push(0);
        }
      }

      let newOptions = {
        ...this.state.optionsTransaction,
        xaxis: {
          categories,
          labels: {
            show: categories.length > 0,
            formatter: function (value) {
              return moment(value, 'YYYY-MM-DD').format('DD-MM-YYYY');
            },
          },
        },
        yaxis: {
          ...this.state.optionsTransaction.yaxis,
          labels: {
            show: categories.length > 0,
          },
        },
      };

      this.setState({
        total_sessions_daily: summaries.total_sessions,
        total_prescription_success_daily: summaries.total_prescription_success,
        total_prescription_fail_daily: summaries.total_prescription_fail,
        optionsTransaction: newOptions,
        seriesTransaction: [
          {
            ...this.state.seriesTransaction[0],
            data: sessions,
          },
          {
            ...this.state.seriesTransaction[1],
            data: prescription_success,
          },
          {
            ...this.state.seriesTransaction[2],
            data: prescription_fail,
          },
        ],
      });

      let overview = await Service.getOverview({
        variant: variant,
        type: '',
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
      });

      let categoriesPharmacy = [];
      let categoriesMedicine = [];

      // set data
      // append the key of pharmacy and medicine to categories

      // iterate each object
      for (let key in overview.pharmacist) {
        // check if the key is not in the categories
        if (categoriesPharmacy.indexOf(key) < 0) {
          // push the key to the categories
          categoriesPharmacy.push(key);
        }
      }

      // same goes for medicine
      for (let key in overview.medicines) {
        if (categoriesMedicine.indexOf(key) < 0) {
          // push the key to the categories
          categoriesMedicine.push(key);
        }
      }

      // set the values
      let pharmacist = [];
      let medicines = [];

      // iterate each category
      for (let i in categoriesPharmacy) {
        // get the key
        let key = categoriesPharmacy[i];

        // check if the key is in the pharmacy
        if (key in overview.pharmacist) {
          // push the value to the pharmacy
          pharmacist.push(overview.pharmacist[key]);
        } else {
          // push 0 to the pharmacy
          pharmacist.push(0);
        }
      }

      // same goes for medicine
      for (let i in categoriesMedicine) {
        // get the key
        let key = categoriesMedicine[i];

        // check if the key is in the medicine
        if (key in overview.medicines) {
          // push the value to the medicine
          medicines.push(overview.medicines[key]);
        } else {
          // push 0 to the medicine
          medicines.push(0);
        }
      }

      // sort key and values of pharmacy and medicine
      let sortedPharmacist = [];
      let sortedMedicines = [];

      for (let i in categoriesPharmacy) {
        sortedPharmacist.push({
          name: categoriesPharmacy[i],
          value: pharmacist[i],
        });
      }

      for (let i in categoriesMedicine) {
        sortedMedicines.push({
          name: categoriesMedicine[i],
          value: medicines[i],
        });
      }

      sortedPharmacist.sort((a, b) => {
        return a.value > b.value ? -1 : 1;
      });

      sortedMedicines.sort((a, b) => {
        return a.value > b.value ? -1 : 1;
      });

      let newOptionsPharmacy = {
        ...this.state.optionsPharmacy,
        labels: sortedPharmacist.map((item) => item.name),
        noData:
          sortedPharmacist.length > 0
            ? {}
            : {
                text: 'Tidak ada data',
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                  fontSize: '14px',
                },
              },
      };

      let newOptionsMedicine = {
        ...this.state.optionsMedicine,
        labels: sortedMedicines.map((item) => item.name),
        noData:
          sortedMedicines.length > 0
            ? {}
            : {
                text: 'Tidak ada data',
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                  fontSize: '14px',
                },
              },
      };

      this.setState({
        optionsPharmacy: newOptionsPharmacy,
        seriesPharmacy: sortedPharmacist.map((item) => item.value),

        optionsMedicine: newOptionsMedicine,
        seriesMedicine: sortedMedicines.map((item) => item.value),
        loadingData: false,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        loadingData: false,
      });
    }
  };

  generateYearOptions = () => {
    let options = [];
    let year = moment().year();
    let minYear = 2020;

    for (let i = year; i >= minYear; i--) {
      options.push({
        value: moment().year(i).format('YYYY'),
        text: i,
      });
    }

    return options;
  };

  generateMonthOptions = () => {
    let options = [];
    let month = moment().month();
    let year = moment().year();
    let minYear = 2020;

    for (let i = month; i >= 0; i--) {
      let m = moment().year(year).month(i);

      options.push({
        value: m.format('MM-YYYY'),
        text: m.format('MMMM YYYY'),
      });

      if (i === 0) {
        i = 12;
        year--;
      }

      if (year < minYear) {
        break;
      }
    }

    return options;
  };

  render() {
    const renderSummary = (items, loading) => {
      return (
        <div>
          <EuiFlexGroup>
            {items.map((item, index) => {
              return (
                <EuiFlexItem key={index}>
                  <EuiCard
                    style={{ backgroundColor: item.bgColor }}
                    title={loading ? 'Loading...' : item.title}
                    description={item.description}
                  />
                </EuiFlexItem>
              );
            })}
          </EuiFlexGroup>
        </div>
      );
    };

    return (
      <>
        <EuiPage className='class'>
          <EuiPageBody className='content-container'>
            <EuiPanel paddingSize='s'>
              <EuiTitle style={{ textAlign: 'left' }} size='l'>
                <h2>Overview {this.state.variantName || ''}</h2>
              </EuiTitle>
              <EuiHorizontalRule />
              <EuiTitle style={{ textAlign: 'left' }} size='s'>
                <h3>Overall</h3>
              </EuiTitle>
              {renderSummary(
                [
                  {
                    bgColor: '#F4F4F4',
                    title: this.state.total_patients || 0,
                    description: 'Pasien terdaftar',
                  },
                  {
                    bgColor: '#F4F4F4',
                    title: this.state.total_connected_kf_patients || 0,
                    description:
                      'Pasien terhubung dengan apotek KF sesuai geolocation',
                  },
                  {
                    bgColor: '#F4F4F4',
                    // round nearest
                    title:
                      Math.round(this.state.total_average_session_per_week) ||
                      0,
                    description: 'Rata-rata konsultasi per minggu',
                  },
                  {
                    bgColor: '#F4F4F4',
                    // percentage
                    title:
                      (
                        this.state.total_percentage_prescription_per_session ||
                        0
                      ).toFixed(1) + '%',
                    description:
                      'Dokter meresepkan obat untuk pasien telekonsultasi',
                  },
                ],
                this.state.loadingAll
              )}
              <EuiSpacer />
              <EuiFormRow label='Rentang waktu' style={{ maxWidth: '500px' }}>
                <EuiDatePickerRange
                  fullWidth
                  startDateControl={
                    <EuiDatePicker
                      minDate={moment('01-01-2020', 'DD-MM-YYYY')}
                      selected={this.state.startDateDaily}
                      onChange={(date, e) => {
                        if (date && e) {
                          if (this.state.endDateDaily.diff(date, 'days') > 30) {
                            if (
                              !window.confirm(
                                'Rentang waktu yang dipilih melebihi 30 hari dan akan menampilkan data yang sangat banyak. Apakah Anda yakin ingin melanjutkan?'
                              )
                            ) {
                              return;
                            }
                          }
                          this.setState(
                            {
                              startDateDaily: date,
                              endDateDaily: this.state.endDateDaily.isBefore(
                                date
                              )
                                ? date
                                : this.state.endDateDaily,
                              loadingData: true,
                            },
                            () => this.onFetchData()
                          );
                        }
                      }}
                      maxDate={moment()}
                      dateFormat={'dddd, DD MMMM YYYY'}
                    />
                  }
                  endDateControl={
                    <EuiDatePicker
                      selected={this.state.endDateDaily}
                      minDate={this.state.startDateDaily}
                      maxDate={moment()}
                      onChange={(date, e) => {
                        if (date && e) {
                          if (
                            date.diff(this.state.startDateDaily, 'days') > 30
                          ) {
                            if (
                              !window.confirm(
                                'Rentang waktu yang dipilih melebihi 30 hari dan akan menampilkan data yang sangat banyak. Apakah Anda yakin ingin melanjutkan?'
                              )
                            ) {
                              return;
                            }
                          }

                          this.setState(
                            {
                              endDateDaily: date,
                              loadingData: true,
                            },
                            () => this.onFetchData()
                          );
                        }
                      }}
                      dateFormat={'dddd, DD MMMM YYYY'}
                    />
                  }
                />
              </EuiFormRow>
              <EuiSpacer />
              <EuiFlexGroup>
                <EuiFlexItem grow={2} style={{ position: 'relative' }}>
                  {renderSummary(
                    [
                      {
                        bgColor: '#F4F4F4',
                        title: this.state.total_sessions_daily || 0,
                        description: 'Sesi konsultasi telah selesai',
                      },
                      {
                        bgColor: '#F4F4F4',
                        title: this.state.total_prescription_success_daily || 0,
                        description: 'Transaksi peresepan KF telah selesai',
                      },
                      {
                        bgColor: '#F4F4F4',
                        title: this.state.total_prescription_fail_daily || 0,
                        description: 'Transaksi peresepan KF gagal',
                      },
                    ],
                    this.state.loadingData
                  )}
                  <EuiSpacer />
                  <EuiFlexGroup justifyContent='center'>
                    <EuiFlexItem grow={false}>
                      <EuiTitle size='s'>
                        <h3>Transaksi peresepan KF</h3>
                      </EuiTitle>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                  {this.state.loadingData && (
                    <div
                      style={{
                        zIndex: 999,
                        position: 'absolute',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        top: '50%',
                        left: 0,
                        right: 0,
                        textAlign: 'center',
                      }}
                    >
                      <EuiLoadingChart mono size='xl' />
                    </div>
                  )}
                  <Chart
                    type='bar'
                    options={this.state.optionsTransaction}
                    series={this.state.seriesTransaction}
                    width={'100%'}
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={1}>
                  <EuiFlexGroup wrap direction='column'>
                    <EuiFlexItem style={{ position: 'relative' }}>
                      {this.state.loadingData && (
                        <div
                          style={{
                            zIndex: 999,
                            position: 'absolute',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            top: '44%',
                            left: 0,
                            right: 0,
                            textAlign: 'center',
                          }}
                        >
                          <EuiLoadingChart mono size='xl' />
                        </div>
                      )}
                      <Chart
                        type='donut'
                        options={this.state.optionsPharmacy}
                        series={this.state.seriesPharmacy}
                        width={'100%'}
                      />
                    </EuiFlexItem>
                    <EuiFlexItem style={{ position: 'relative' }}>
                      {this.state.loadingData && (
                        <div
                          style={{
                            zIndex: 999,
                            position: 'absolute',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            bottom: '50%',
                            left: 0,
                            right: 0,
                            textAlign: 'center',
                          }}
                        >
                          <EuiLoadingChart mono size='xl' />
                        </div>
                      )}
                      <Chart
                        type='donut'
                        options={this.state.optionsMedicine}
                        series={this.state.seriesMedicine}
                        width={'100%'}
                      />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiPanel>
          </EuiPageBody>
        </EuiPage>
      </>
    );
  }
}

export default Overview;
