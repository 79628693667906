import React, { Component } from 'react';

import {
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiButtonEmpty,
  EuiFieldText,
  EuiPanel,
  EuiTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiDatePicker,
  EuiHorizontalRule,
  EuiSpacer,
  EuiCard,
  EuiDatePickerRange,
  EuiFormRow,
  EuiSelect,
  EuiLoadingChart,
} from '@elastic/eui';

import SummariesService from '../services/SummaryOverview.js';
import moment from 'moment';
import VariantService from '../services/Variants.js';
import Chart from 'react-apexcharts';

import '../styles/Summary.css';

const variantService = new VariantService();
const Summaries = new SummariesService();

const initialOptions = {
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '55%',
      endingShape: 'rounded',
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 4,
    colors: ['transparent'],
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  xaxis: {
    labels: {
      show: false,
    },
  },
  fill: {
    opacity: 1,
  },
  // tooltip: {
  //   y: {
  //     formatter: function (val) {
  //       return '$ ' + val + ' thousands';
  //     },
  //   },
  // },
  // noData: {
  //   text: 'Tidak ada data',
  //   align: 'center',
  //   verticalAlign: 'middle',
  //   offsetX: 0,
  //   offsetY: 0,
  //   style: {
  //     color: undefined,
  //     fontSize: '14px',
  //     fontFamily: undefined,
  //   },
  // },
};

const listMonths = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'December',
];

class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // response data
      session_summary: {},
      prescription_summary: {},
      diagnostic_summary: {},

      variant: '',
      variantName: '',

      selectedMonthlyDate: moment().format('YYYY'),
      startDateWeekly: moment().format('MM-YYYY'),
      endDateWeekly: moment().format('MM-YYYY'),
      startDateDaily: moment().subtract(7, 'days'),
      endDateDaily: moment(),
      loadingMonthlyChart: true,
      loadingWeeklyChart: true,
      loadingDailyChart: true,
      optionsMonthly: {
        ...initialOptions,
        xaxis: {
          categories: listMonths,
          labels: {
            show: true,
          },
        },
      },
      optionsWeekly: {
        ...initialOptions,
        // tooltip: {
        //   y: {
        //     formatter: function (val) {
        //       return '$ ' + val + ' thousands';
        //     },
        //   },
        // },
        // xaxis: {
        //   categories: [],
        // },
      },
      optionsDaily: {
        ...initialOptions,
        // xaxis: {
        //   categories: [],
        // },
      },
      seriesMonthly: [
        {
          name: 'Sesi konsultasi telah selesai',
          color: '#AAAAAA',
          data: [],
        },
        {
          name: 'Transaksi peresepan KF telah selesai',
          color: '#009944',
          data: [],
        },
        {
          name: 'Transaksi peresepan KF gagal',
          color: '#CF000F',
          data: [],
        },
      ],
      seriesWeekly: [
        {
          name: 'Sesi konsultasi telah selesai',
          data: [],
          color: '#AAAAAA',
        },
        {
          name: 'Transaksi peresepan KF telah selesai',
          data: [],
          color: '#009944',
        },
        {
          name: 'Transaksi peresepan KF gagal',
          data: [],
          color: '#CF000F',
        },
      ],
      seriesDaily: [
        {
          name: 'Sesi konsultasi telah selesai',
          data: [],
          color: '#AAAAAA',
        },
        {
          name: 'Transaksi peresepan KF telah selesai',
          data: [],
          color: '#009944',
        },
        {
          name: 'Transaksi peresepan KF gagal',
          data: [],
          color: '#CF000F',
        },
      ],
    };
  }

  componentDidMount() {
    let variant = this.props.match.params.variant;
    if (!variant || (variant && variant.length < 1)) {
      this.props.history.push('/');
      return;
    }

    variantService.getVariants().then((result) => {
      for (let i in result.items) {
        if (result.items[i].variant === variant) {
          this.setState({
            variantName: result.items[i].name,
            variant: variant,
          });
          this.onFetchMonthlySummaries(variant);
          this.onFetchWeeklySummaries(variant);
          this.onFetchDailySummaries(variant);
          break;
        }
      }
    });
  }

  onFetchMonthlySummaries = (variant) => {
    variant = variant || this.state.variant;
    let year = this.state.selectedMonthlyDate;

    Summaries.getSummaries({
      variant: variant,
      type: 'monthly',
      year: year,
    })
      .then((result) => {
        // example data
        /*
          {
              "type": "monthly",
              "total_sessions": 707,
              "total_prescription_success": 12,
              "total_prescription_fail": 6,
              "sessions": {
                  "2022-01": 0,
                  "2022-02": 0,
                  "2022-03": 37,
                  "2022-04": 46,
                  "2022-05": 26,
                  "2022-06": 57,
                  "2022-07": 108,
                  "2022-08": 108,
                  "2022-09": 133,
                  "2022-10": 81,
                  "2022-11": 111,
                  "2022-12": 0
              },
              "prescription_success": {
                  "2022-01": 0,
                  "2022-02": 0,
                  "2022-03": 0,
                  "2022-04": 0,
                  "2022-05": 0,
                  "2022-06": 0,
                  "2022-07": 0,
                  "2022-08": 2,
                  "2022-09": 3,
                  "2022-10": 5,
                  "2022-11": 2,
                  "2022-12": 0
              },
              "prescription_fail": {
                  "2022-01": 0,
                  "2022-02": 0,
                  "2022-03": 0,
                  "2022-04": 0,
                  "2022-05": 0,
                  "2022-06": 0,
                  "2022-07": 0,
                  "2022-08": 0,
                  "2022-09": 0,
                  "2022-10": 0,
                  "2022-11": 6,
                  "2022-12": 0
              }
          }
        */

        // set data
        this.setState({
          loadingMonthlyChart: false,
          total_sessions: result.total_sessions,
          total_prescription_success: result.total_prescription_success,
          total_prescription_fail: result.total_prescription_fail,
          optionsMonthly: {
            ...this.state.optionsMonthly,
            yaxis: {
              ...this.state.optionsMonthly.yaxis,
              labels: {
                show:
                  result.total_sessions > 0 ||
                  result.total_prescription_success > 0 ||
                  result.total_prescription_fail > 0,
              },
            },
          },
          seriesMonthly: [
            {
              ...this.state.seriesMonthly[0],
              data: Object.values(result.sessions),
            },
            {
              ...this.state.seriesMonthly[1],
              data: Object.values(result.prescription_success),
            },
            {
              ...this.state.seriesMonthly[2],
              data: Object.values(result.prescription_fail),
            },
          ],
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loadingMonthlyChart: false,
        });
      });
  };

  onFetchWeeklySummaries = (variant) => {
    variant = variant || this.state.variant;

    let startDate = moment(this.state.startDateWeekly, 'MM-YYYY');
    let endDate = moment(this.state.endDateWeekly, 'MM-YYYY').add(1, 'month');

    Summaries.getSummaries({
      variant: variant,
      type: 'weekly',
      monthYearStart: startDate.format('YYYY-MM'),
      monthYearEnd: endDate.format('YYYY-MM'),
    })
      .then((result) => {
        // example data
        /*
        {
        "result": {
            "type": "weekly",
            "total_sessions": 111,
            "total_prescription_success": 2,
            "total_prescription_fail": 6,
            "sessions": {
                "2022-44": 26,
                "2022-45": 39,
                "2022-46": 46
            },
            "prescription_success": {
                "2022-44": 2
            },
            "prescription_fail": {
                "2022-46": 6
            }
        }
      }
      */

        // set data
        // append the key list sessions, prescription success, and prescription fail to categories
        let categories = [];

        // iterate each object
        for (let key in result.sessions) {
          // check if the key is not in the categories
          if (categories.indexOf(key) < 0) {
            // push the key to the categories
            categories.push(key);
          }
        }

        // same goes for prescriptions
        for (let key in result.prescription_success) {
          if (categories.indexOf(key) < 0) {
            categories.push(key);
          }
        }

        for (let key in result.prescription_fail) {
          if (categories.indexOf(key) < 0) {
            categories.push(key);
          }
        }

        // sort the categories
        categories.sort((a, b) => {
          // parse to moment
          let aMoment = moment(a, 'YYYY-WW');
          let bMoment = moment(b, 'YYYY-WW');

          // compare the moment
          return aMoment.isBefore(bMoment) ? -1 : 1;
        });

        // set the values
        let sessions = [];
        let prescription_success = [];
        let prescription_fail = [];

        // iterate each category
        for (let i in categories) {
          // get the key
          let key = categories[i];

          // check if the key is in the sessions
          if (key in result.sessions) {
            // push the value to the sessions
            sessions.push(result.sessions[key]);
          } else {
            // push 0 to the sessions
            sessions.push(0);
          }

          // same goes for prescription
          if (key in result.prescription_success) {
            prescription_success.push(result.prescription_success[key]);
          } else {
            prescription_success.push(0);
          }

          if (key in result.prescription_fail) {
            prescription_fail.push(result.prescription_fail[key]);
          } else {
            prescription_fail.push(0);
          }
        }

        let newOptions = {
          ...this.state.optionsWeekly,
          xaxis: {
            categories,
            labels: {
              show: categories.length > 0,
              formatter: function (value) {
                return moment(value, 'YYYY-WW').format('WW-YYYY');
              },
            },
            tooltip: {
              enabled: true,
              formatter: function (value) {
                let date = moment(value, 'YYYY-WW');
                let start = date.startOf('week').format('DD MMMM YYYY');
                let end = date.endOf('week').format('DD MMMM YYYY');

                return `Minggu ke-${date.format(
                  'WW [Tahun ]YYYY'
                )} (${start} - ${end})`;
              },
            },
          },
          yaxis: {
            ...this.state.optionsWeekly.yaxis,
            labels: {
              show: categories.length > 0,
            },
          },
        };

        this.setState({
          loadingWeeklyChart: false,
          total_sessions_weekly: result.total_sessions,
          total_prescription_success_weekly: result.total_prescription_success,
          total_prescription_fail_weekly: result.total_prescription_fail,
          optionsWeekly: newOptions,
          seriesWeekly: [
            {
              ...this.state.seriesWeekly[0],
              data: sessions,
            },
            {
              ...this.state.seriesWeekly[1],
              data: prescription_success,
            },
            {
              ...this.state.seriesWeekly[2],
              data: prescription_fail,
            },
          ],
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loadingWeeklyChart: false,
        });
      });
  };

  onFetchDailySummaries = (variant) => {
    variant = variant || this.state.variant;

    let startDate = this.state.startDateDaily.clone();
    let endDate = this.state.endDateDaily.clone().add(1, 'days');

    Summaries.getSummaries({
      variant: variant,
      type: 'daily',
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    })
      .then((result) => {
        // example data
        /*
        {
          "type": "daily",
          "total_sessions": 46,
          "total_prescription_success": 0,
          "total_prescription_fail": 6,
          "sessions": {
              "2022-11-14": 14,
              "2022-11-15": 6,
              "2022-11-16": 14,
              "2022-11-17": 6,
              "2022-11-18": 4,
              "2022-11-19": 2
          },
          "prescription_success": {},
          "prescription_fail": {
              "2022-11-16": 5,
              "2022-11-18": 1
          }
        }
      */

        // set data
        // append the key list sessions, prescription success, and prescription fail to categories
        let categories = [];

        // iterate each object
        for (let key in result.sessions) {
          // check if the key is not in the categories
          if (categories.indexOf(key) < 0) {
            // push the key to the categories
            categories.push(key);
          }
        }

        // same goes for prescriptions
        for (let key in result.prescription_success) {
          if (categories.indexOf(key) < 0) {
            categories.push(key);
          }
        }

        for (let key in result.prescription_fail) {
          if (categories.indexOf(key) < 0) {
            categories.push(key);
          }
        }

        // sort the categories
        categories.sort((a, b) => {
          // parse to moment
          let aMoment = moment(a, 'YYYY-MM-DD');
          let bMoment = moment(b, 'YYYY-MM-DD');

          // compare the moment
          return aMoment.isBefore(bMoment) ? -1 : 1;
        });

        // set the values
        let sessions = [];
        let prescription_success = [];
        let prescription_fail = [];

        // iterate each category
        for (let i in categories) {
          // get the key
          let key = categories[i];

          // check if the key is in the sessions
          if (key in result.sessions) {
            // push the value to the sessions
            sessions.push(result.sessions[key]);
          } else {
            // push 0 to the sessions
            sessions.push(0);
          }

          // same goes for prescription
          if (key in result.prescription_success) {
            prescription_success.push(result.prescription_success[key]);
          } else {
            prescription_success.push(0);
          }

          if (key in result.prescription_fail) {
            prescription_fail.push(result.prescription_fail[key]);
          } else {
            prescription_fail.push(0);
          }
        }

        let newOptions = {
          ...this.state.optionsDaily,
          xaxis: {
            categories,
            labels: {
              show: categories.length > 0,
              formatter: function (value) {
                return moment(value, 'YYYY-MM-DD').format('DD-MM-YYYY');
              },
            },
          },
          yaxis: {
            ...this.state.optionsDaily.yaxis,
            labels: {
              show: categories.length > 0,
            },
          },
        };

        this.setState({
          loadingDailyChart: false,
          total_sessions_daily: result.total_sessions,
          total_prescription_success_daily: result.total_prescription_success,
          total_prescription_fail_daily: result.total_prescription_fail,
          optionsDaily: newOptions,
          seriesDaily: [
            {
              ...this.state.seriesDaily[0],
              data: sessions,
            },
            {
              ...this.state.seriesDaily[1],
              data: prescription_success,
            },
            {
              ...this.state.seriesDaily[2],
              data: prescription_fail,
            },
          ],
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loadingDailyChart: false,
        });
      });
  };

  generateYearOptions = () => {
    let options = [];
    let year = moment().year();
    let minYear = 2020;

    for (let i = year; i >= minYear; i--) {
      options.push({
        value: moment().year(i).format('YYYY'),
        text: i,
      });
    }

    return options;
  };

  generateMonthOptions = () => {
    let options = [];
    let month = moment().month();
    let year = moment().year();
    let minYear = 2020;

    for (let i = month; i >= 0; i--) {
      let m = moment().year(year).month(i);

      options.push({
        value: m.format('MM-YYYY'),
        text: m.format('MMMM YYYY'),
      });

      if (i === 0) {
        i = 12;
        year--;
      }

      if (year < minYear) {
        break;
      }
    }

    return options;
  };

  render() {
    const renderSummary = (items, loading) => {
      return (
        <div>
          <EuiFlexGroup>
            {items.map((item, index) => {
              return (
                <EuiFlexItem key={index}>
                  <EuiCard
                    style={{ backgroundColor: item.bgColor }}
                    title={loading ? 'Loading...' : item.title}
                    description={item.description}
                  />
                </EuiFlexItem>
              );
            })}
          </EuiFlexGroup>
        </div>
      );
    };

    return (
      <>
        <EuiPage className='class'>
          <EuiPageBody className='content-container'>
            <EuiPanel paddingSize='s'>
              <EuiTitle style={{ textAlign: 'left' }} size='l'>
                <h2>Summary {this.state.variantName || ''}</h2>
              </EuiTitle>
              <EuiHorizontalRule />
              <EuiFlexGroup wrap>
                <EuiFlexItem grow={false} style={{ width: '48%' }}>
                  <EuiPanel style={{ position: 'relative' }}>
                    <div style={{ width: '50%', textAlign: 'left' }}>
                      <EuiTitle>
                        <h4>Transaksi per bulan</h4>
                      </EuiTitle>
                      <EuiSpacer size='s' />
                      <EuiFormRow style={{ width: '150px' }} label='Tahun'>
                        <EuiSelect
                          options={this.generateYearOptions()}
                          value={this.state.selectedMonthlyDate}
                          onChange={(e) =>
                            this.setState(
                              {
                                selectedMonthlyDate: e.target.value,
                                loadingMonthlyChart: true,
                              },
                              () => this.onFetchMonthlySummaries()
                            )
                          }
                        />
                      </EuiFormRow>
                    </div>
                    <EuiSpacer />
                    {this.state.loadingMonthlyChart && (
                      <div
                        style={{
                          zIndex: 999,
                          position: 'absolute',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          top: '50%',
                          left: 0,
                          right: 0,
                          textAlign: 'center',
                        }}
                      >
                        <EuiLoadingChart mono size='xl' />
                      </div>
                    )}
                    <Chart
                      type='bar'
                      options={this.state.optionsMonthly}
                      series={this.state.seriesMonthly}
                      width={'100%'}
                    />
                    {renderSummary(
                      [
                        {
                          bgColor: '#F4F4F4',
                          title: this.state.total_sessions || 0,
                          description: 'Jumlah Sesi konsultasi telah selesai',
                        },
                        {
                          bgColor: '#F4F4F4',
                          title: this.state.total_prescription_success || 0,
                          description:
                            'Jumlah Transaksi peresepan KF telah selesai',
                        },
                        {
                          bgColor: '#F4F4F4',
                          title: this.state.total_prescription_fail || 0,
                          description: 'Jumlah Transaksi peresepan KF gagal',
                        },
                      ],
                      this.state.loadingMonthlyChart
                    )}
                  </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem grow={false} style={{ width: '48%' }}>
                  <EuiPanel style={{ position: 'relative' }}>
                    <div style={{ textAlign: 'left' }}>
                      <EuiTitle>
                        <h4>Transaksi per minggu</h4>
                      </EuiTitle>
                      <EuiSpacer size='s' />
                      <EuiFormRow
                        label='Rentang waktu'
                        className='summary-weekly-select'
                      >
                        <EuiDatePickerRange
                          fullWidth
                          startDateControl={
                            <EuiSelect
                              style={{ border: 'none', boxShadow: 'none' }}
                              options={this.generateMonthOptions()}
                              value={this.state.startDateWeekly}
                              onChange={(e) => {
                                let value = e.target.value;
                                let endDate = moment(
                                  this.state.endDateWeekly,
                                  'MM-YYYY'
                                );

                                if (
                                  endDate.diff(
                                    moment(value, 'MM-YYYY'),
                                    'months'
                                  ) > 12
                                ) {
                                  if (
                                    !window.confirm(
                                      'Rentang waktu yang dipilih melebihi 12 bulan dan akan menampilkan data yang sangat banyak. Apakah Anda yakin ingin melanjutkan?'
                                    )
                                  ) {
                                    return;
                                  }
                                }

                                this.setState(
                                  {
                                    startDateWeekly: e.target.value,
                                    endDateWeekly: endDate.isBefore(
                                      moment(value, 'MM-YYYY')
                                    )
                                      ? value
                                      : this.state.endDateWeekly,
                                    loadingWeeklyChart: true,
                                  },
                                  () => this.onFetchWeeklySummaries()
                                );
                              }}
                            />
                          }
                          endDateControl={
                            <EuiSelect
                              style={{ border: 'none', boxShadow: 'none' }}
                              options={this.generateMonthOptions().map(
                                (item) => {
                                  let value = item.value;
                                  let startDate = moment(
                                    this.state.startDateWeekly,
                                    'MM-YYYY'
                                  );

                                  if (
                                    moment(value, 'MM-YYYY').isBefore(startDate)
                                  ) {
                                    return {
                                      value: item.value,
                                      text: item.text,
                                      disabled: true,
                                    };
                                  }

                                  return item;
                                }
                              )} // disable options before start date
                              value={this.state.endDateWeekly}
                              onChange={(e) => {
                                if (
                                  moment(e.target.value, 'MM-YYYY').diff(
                                    moment(
                                      this.state.startDateWeekly,
                                      'MM-YYYY'
                                    ),
                                    'months'
                                  ) > 12
                                ) {
                                  if (
                                    !window.confirm(
                                      'Rentang waktu yang dipilih melebihi 12 bulan dan akan menampilkan data yang sangat banyak. Apakah Anda yakin ingin melanjutkan?'
                                    )
                                  ) {
                                    return;
                                  }
                                }
                                this.setState(
                                  {
                                    endDateWeekly: e.target.value,
                                    loadingWeeklyChart: true,
                                  },
                                  () => this.onFetchWeeklySummaries()
                                );
                              }}
                            />
                          }
                        />
                      </EuiFormRow>
                    </div>
                    <EuiSpacer />
                    {this.state.loadingWeeklyChart && (
                      <div
                        style={{
                          zIndex: 999,
                          position: 'absolute',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          top: '50%',
                          left: 0,
                          right: 0,
                          textAlign: 'center',
                        }}
                      >
                        <EuiLoadingChart mono size='xl' />
                      </div>
                    )}
                    <Chart
                      type='bar'
                      options={this.state.optionsWeekly}
                      series={this.state.seriesWeekly}
                      width={'100%'}
                    />
                    {renderSummary(
                      [
                        {
                          bgColor: '#F4F4F4',
                          title: this.state.total_sessions_weekly || 0,
                          description: 'Sesi konsultasi telah selesai',
                        },
                        {
                          bgColor: '#F4F4F4',
                          title:
                            this.state.total_prescription_success_weekly || 0,
                          description: 'Transaksi peresepan KF telah selesai',
                        },
                        {
                          bgColor: '#F4F4F4',
                          title: this.state.total_prescription_fail_weekly || 0,
                          description: 'Transaksi peresepan KF gagal',
                        },
                      ],
                      this.state.loadingWeeklyChart
                    )}
                  </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem grow={false} style={{ width: '48%' }}>
                  <EuiPanel style={{ position: 'relative' }}>
                    <div style={{ textAlign: 'left' }}>
                      <EuiTitle>
                        <h4>Transaksi per hari</h4>
                      </EuiTitle>
                      <EuiSpacer size='s' />
                      <EuiFormRow
                        label='Rentang waktu'
                        style={{ maxWidth: '500px' }}
                      >
                        <EuiDatePickerRange
                          fullWidth
                          startDateControl={
                            <EuiDatePicker
                              minDate={moment('01-01-2020', 'DD-MM-YYYY')}
                              selected={this.state.startDateDaily}
                              onChange={(date, e) => {
                                if (date && e) {
                                  if (
                                    this.state.endDateDaily.diff(date, 'days') >
                                    30
                                  ) {
                                    if (
                                      !window.confirm(
                                        'Rentang waktu yang dipilih melebihi 30 hari dan akan menampilkan data yang sangat banyak. Apakah Anda yakin ingin melanjutkan?'
                                      )
                                    ) {
                                      return;
                                    }
                                  }
                                  this.setState(
                                    {
                                      startDateDaily: date,
                                      endDateDaily:
                                        this.state.endDateDaily.isBefore(date)
                                          ? date
                                          : this.state.endDateDaily,
                                      loadingDailyChart: true,
                                    },
                                    () =>
                                      setTimeout(
                                        this.onFetchDailySummaries,
                                        1500
                                      )
                                  );
                                }
                              }}
                              maxDate={moment()}
                              dateFormat={'dddd, DD MMMM YYYY'}
                            />
                          }
                          endDateControl={
                            <EuiDatePicker
                              selected={this.state.endDateDaily}
                              minDate={this.state.startDateDaily}
                              maxDate={moment()}
                              onChange={(date, e) => {
                                if (date && e) {
                                  if (
                                    date.diff(
                                      this.state.startDateDaily,
                                      'days'
                                    ) > 30
                                  ) {
                                    if (
                                      !window.confirm(
                                        'Rentang waktu yang dipilih melebihi 30 hari dan akan menampilkan data yang sangat banyak. Apakah Anda yakin ingin melanjutkan?'
                                      )
                                    ) {
                                      return;
                                    }
                                  }

                                  this.setState(
                                    {
                                      endDateDaily: date,
                                      loadingDailyChart: true,
                                    },
                                    () =>
                                      setTimeout(
                                        this.onFetchDailySummaries,
                                        1500
                                      )
                                  );
                                }
                              }}
                              dateFormat={'dddd, DD MMMM YYYY'}
                            />
                          }
                        />
                      </EuiFormRow>
                    </div>
                    <EuiSpacer />
                    <div>
                      {this.state.loadingDailyChart && (
                        <div
                          style={{
                            zIndex: 999,
                            position: 'absolute',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            top: '50%',
                            left: 0,
                            right: 0,
                            textAlign: 'center',
                          }}
                        >
                          <EuiLoadingChart mono size='xl' />
                        </div>
                      )}
                      <Chart
                        type='bar'
                        options={this.state.optionsDaily}
                        series={this.state.seriesDaily}
                        width={'100%'}
                      />

                      {renderSummary(
                        [
                          {
                            bgColor: '#F4F4F4',
                            title: this.state.total_sessions_daily || 0,
                            description: 'Sesi konsultasi telah selesai',
                          },
                          {
                            bgColor: '#F4F4F4',
                            title:
                              this.state.total_prescription_success_daily || 0,
                            description: 'Transaksi peresepan KF telah selesai',
                          },
                          {
                            bgColor: '#F4F4F4',
                            title:
                              this.state.total_prescription_fail_daily || 0,
                            description: 'Transaksi peresepan KF gagal',
                          },
                        ],
                        this.state.loadingDailyChart
                      )}
                    </div>
                  </EuiPanel>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiPanel>
          </EuiPageBody>
        </EuiPage>
      </>
    );
  }
}

export default Summary;
