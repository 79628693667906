import React, { Component } from 'react';

import {
  EuiPage,
  EuiPageBody,
  EuiButton,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiOverlayMask,
  EuiButtonEmpty,
  EuiConfirmModal,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiSelect,
} from '@elastic/eui';
import TablePage from '../components/TablePage';
import OrganizationService from '../services/Organizations';
import ErrorService from '../services/Error.js';
import Utils from '../Utils';
import SettingServices from '../services/Settings';

const organizationService = new OrganizationService();
const errorService = new ErrorService();
const appSettingService = new SettingServices()

class Organization extends Component {
  state = {
    page: 1,
    limit: 10,
    order: 'ASC',
    search: '',
    loadingPage: true,
    orderBy: 'name',
    data: [],
    column: [
      {
        field: 'renderedName',
        render: (renderedName) => {
          if (renderedName.indexOf('UNIT1:') > -1) {
            return (
              <span style={{ marginLeft: 50 }}>
                • {renderedName.replace('UNIT1:', '')}
              </span>
            );
          }
          else if (renderedName.indexOf('UNIT:') > -1) {
            return (
              <span style={{ marginLeft: 30 }}>
                • {renderedName.replace('UNIT:', '')}
              </span>
            );
          } else {
            return <span>{renderedName}</span>;
          }
        },
      },
    ],

    // Form
    name: '',
    validationFields: {
      name: {
        type: 'string',
        isInvalid: false,
        errors: ['Nama tidak boleh kosong'],
        isValidFunc: function (value) {
          return value && value.length > 0;
        },
      },
      parentId: {
        type: 'string',
        isInvalid: false,
        errors: [''],
        isValidFunc: function (value) {
          return true; // Allowed to be empty
        },
      },
    },
    allowSiblingAsParent: false,
  };

  componentDidMount = () => {
    this.loadSetting();
    this.page(1);
    this.loadTopOrganizations();
  };
  loadSetting = () => {
    appSettingService.getAppSettings('ALLOW_SIBLING_UNITS_AS_PARENTS').then((result) => {
      console.log("Allow sibling as parent ", result['data'])
      this.setState({
        allowSiblingAsParent: (result['data'] === 'true')
      })
    })
  }
  onParentChange = (e) => {
    let value = e.target.value || null;
    let name = '';
    for (let i in this.state.parents) {
      if (this.state.parents[i].value === value) {
        name = this.state.parents[i].name || this.state.parents[i].text;
      }
    }
    this.setState({ parentId: value, parentName: name });
  };
  loadTopOrganizations = () => {
    let payload = {
      limit: 100000,
    };
    organizationService
      .getOrganizations(payload)
      .then((result) => {
        let options = [];
        options.push({ value: '', text: 'Pilih organisasi...' });
        for (let i in result.items) {
          if (result.items[i].id === this.state.id) {
            continue
          }
          if (result.items[i].parentId && result.items[i].parentId.length > 0 && !this.state.allowSiblingAsParent) {
            continue;
          }
          options.push({
            value: result.items[i].id,
            text: result.items[i].name,
          });
        }
        this.setState({ parents: options });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          parents: [],
        });
      });
  };

  handleChange = (e) => {
    let value = e.target.value || null;
    let obj = {};
    obj[e.target.name] = value;
    obj['failedAttempt'] = false;
    let validationFields = { ...this.state.validationFields };
    if (validationFields[e.target.name]) {
      validationFields[e.target.name].isInvalid = false;
      obj.validationFields = validationFields;
    }
    this.setState(obj);
  };

  page = (page) => {
    page = page || 1;
    this.setState({ loadingPage: true, search: '' });
    let payload = {
      page: page,
      limit: this.state.limit,
      search: this.state.search,
      order: this.state.order,
      orderBy: this.state.orderBy,
      parentOnly: true
    };
    organizationService
      .getOrganizations(payload)
      .then(async (result) => {
        console.log(result.items);
        let sorted = [];

        for (let i in result.items) {
          result.items[i].renderedName = result.items[i].name;
          sorted.push(result.items[i]);
          let units = await this.getOrganizationUnit(result.items[i].id);
          for (let j in units) {
            units[j].renderedName = 'UNIT:' + units[j].name;
            sorted.push(units[j]);
            if (this.state.allowSiblingAsParent) {
              let units1 = await this.getOrganizationUnit(units[j].id);
              for (let k in units1) {
                units1[k].renderedName = 'UNIT1:' + units1[k].name;
                sorted.push(units1[k]);
              }
            }
          }
        }

        this.setState(
          {
            page: result.page,
            limit: result.limit,
            total: result.total,
            order: result.order,
            orderBy: result.orderBy,
            data: sorted,
            loadingPage: false,
          },
          () => {
            this.forceUpdate();
          }
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loadingPage: false,
          data: [],
        });
      });
  };

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };

  add = () => {
    this.setState({ id: '', name: '', showModal: true });
  };

  show = (item) => {
    this.setState({ id: item.id, name: item.name, showModal: true });
  };

  getOrganizationUnit = async (parentID) => {
    let payload = {
      limit: 100000,
      parentID,
    };

    try {
      let resp = await organizationService.getOrganizations(payload);
      return resp.items || [];
    } catch (e) {
      console.log(e)
      return [];
    }
  };

  save = () => {
    let state = { ...this.state };
    this.setState({ saveLoading: true }, () => {
      Utils.validateFields(state).then((result) => {
        this.setState({ validationFields: result.validationFields });
        this.forceUpdate();
        if (!result.isValid) {
          return;
        }
        if (this.state.id && this.state.id.length > 0) {
          // Update existing
          organizationService
            .update(Utils.snakeCase({
              name: this.state.name,
              id: this.state.id,
              parentId: this.state.parentId,
            }))
            .then((result) => {
              this.setState({ saveLoading: false });
              this.closeModal();
              this.loadTopOrganizations();
              this.page(1);
            })
            .catch((err) => {
              this.setState({ saveLoading: false });
              if (
                err &&
                err.response &&
                err.response.status &&
                err.response.status == 400
              ) {
                alert(
                  'Organisasi ini tidak dapat berinduk ke organisasi lain karena merupakan induk dari unit lain.'
                );
                return;
              }
              errorService.handle(err);
            });
        } else {
          // Create new one
          organizationService
            .create({ name: this.state.name, parentId: this.state.parentId })
            .then((result) => {
              this.setState({ saveLoading: false });
              this.closeModal();
              this.loadTopOrganizations();
              this.page(1);
            })
            .catch((err) => {
              this.setState({ saveLoading: false });
              if (
                err &&
                err.response &&
                err.response.status &&
                err.response.status == 400
              ) {
                alert(
                  'Organisasi ini tidak dapat berinduk ke organisasi lain karena merupakan induk dari unit lain.'
                );
                return;
              }
              errorService.handle(err);
            });
        }
      });
    });
  };

  delete = () => {
    if (!(this.state.id && this.state.id.length > 0)) {
      return;
    }
    if (!window.confirm('Apakah Anda yakin ingin menghapus item ini?')) {
      return;
    }
    organizationService
      .delete(this.state.id)
      .then((result) => {
        this.setState({ saveLoading: false });
        this.closeModal();
        this.page(1);
        this.loadTopOrganizations();
      })
      .catch((err) => {
        this.setState({ saveLoading: false });
        if (
          err &&
          err.response &&
          err.response.status &&
          err.response.status == 409
        ) {
          alert(
            'Organisasi ini masih memiliki unit. Hapus unit turunan terlebih dahulu.'
          );
          return;
        }
        errorService.handle(err);
      });
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
      id: '',
      name: '',
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      id: '',
      name: '',
      parentId: '',
    });
  };

  onItemClick = (item) => {
    this.setState({
      id: item.id,
      name: item.name,
      parentId: item.parentId,
      showModal: true,
    }, () => {
      this.loadTopOrganizations();
    });
  };

  render() {
    return (
      <>
        <EuiPage className="class">
          <EuiPageBody
            style={{ flexDirection: 'row' }}
            className={'content-container'}
          >
            <TablePage
              title={'Organisasi'}
              placeholder={'Cari'}
              buttonLabel={'Tambah'}
              buttonFunc={() =>
                this.setState({ showModal: true, id: '', name: '' })
              }
              data={this.state.data}
              column={this.state.column}
              disablePagination={false}
              page={this.state.page}
              limit={this.state.limit}
              total={this.state.total}
              prev={this.prevPage}
              next={this.nextPage}
              toPage={this.page}
              onItemClick={this.onItemClick}
              loading={this.state.loadingPage}
            />
          </EuiPageBody>
        </EuiPage>
        {this.state.showDeleteModal ? (
          <EuiOverlayMask>
            <EuiConfirmModal
              title="Hapus"
              onCancel={this.closeDeleteModal}
              onConfirm={this.closeDeleteModal}
              cancelButtonText="Batal"
              confirmButtonText="Hapus"
              buttonColor="danger"
              defaultFocusedButton="confirm"
            >
              <p>Apakah anda ingin menghapus item ini ?</p>
            </EuiConfirmModal>
          </EuiOverlayMask>
        ) : null}
        {this.state.showModal && (
          <EuiOverlayMask>
            <EuiModal
              onClose={() => this.closeModal()}
              initialFocus="[name=popswitch]"
            >
              <EuiModalHeader>
                <EuiModalHeaderTitle>Organisasi</EuiModalHeaderTitle>
              </EuiModalHeader>

              <EuiModalBody>
                <EuiForm>
                  <EuiFormRow
                    label="Nama"
                    isInvalid={this.state.validationFields['name'].isInvalid}
                    error={
                      this.state.validationFields['name'].isInvalid &&
                      this.state.validationFields['name'].errors
                    }
                  >
                    <EuiFieldText
                      placeholder="Nama"
                      value={this.state.name}
                      name="name"
                      onChange={this.handleChange}
                      aria-label="Nama"
                    />
                  </EuiFormRow>
                </EuiForm>
                <EuiFormRow
                  label="Induk"
                  isInvalid={this.state.validationFields['parentId'].isInvalid}
                  error={
                    this.state.validationFields['parentId'].isInvalid &&
                    this.state.validationFields['parentId'].errors
                  }
                >
                  <EuiSelect
                    placeholder="Induk"
                    options={this.state.parents}
                    value={this.state.parentId}
                    name="parentName"
                    onChange={this.onParentChange}
                    aria-label="Induk"
                  />
                </EuiFormRow>
              </EuiModalBody>

              <EuiModalFooter>
                <EuiButtonEmpty onClick={() => this.closeModal()}>
                  Batal
                </EuiButtonEmpty>
                {this.state.id && (
                  <EuiButton
                    color={'danger'}
                    onClick={() => this.delete()}
                    fill
                  >
                    Hapus
                  </EuiButton>
                )}
                <EuiButton onClick={() => this.save()} fill>
                  Simpan
                </EuiButton>
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        )}
      </>
    );
  }
}

export default Organization;
