import React, {Component, Fragment} from 'react';
import {
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiFieldPassword,
  EuiLink,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiGlobalToastList,
} from '@elastic/eui';
import UserService from '../services/Users.js';
import ErrorService from '../services/Error.js';
import Utils from '../Utils';

const userService = new UserService();
const errorService = new ErrorService();

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      renderForm: '',
      resetPasswordToast: '',
      toasts: [],
      emailAddress: '',
      password: '',
      validationFields: {
        emailAddress: {
          type: 'string',
          isInvalid: false,
          errors: ['Alamat email tidak boleh kosong'],
          isValidFunc: function(value) {
            return value && value.length > 0;
          },
        },
        password: {
          type: 'string',
          isInvalid: false,
          errors: ['Sandi tidak boleh kosong'],
          isValidFunc: function(value) {
            return value && value.length > 0;
          },
        },
      },
    };
  }
  handleChange = e => {
    let value = e.target.value || null;
    let obj = {};
    obj[e.target.name] = value;
    obj['failedAttempt'] = false;
    let validationFields = {...this.state.validationFields};
    if (validationFields[e.target.name]) {
      validationFields[e.target.name].isInvalid = false;
      obj.validationFields = validationFields;
    }
    this.setState(obj);
  };

  login = () => {
    let state = {...this.state};
    Utils.validateFields(state).then(result => {
      this.setState({validationFields: result.validationFields});
      this.forceUpdate();
      if (!result.isValid) {
        return
      }
      let payload = {
        emailAddress: this.state.emailAddress,
        password: this.state.password,
      };
      this.setState({isLoading: true});
      userService
        .login(payload)
        .then(result => {
          console.log(result);
          this.setState({isLoading: false});
          if (result && result.token && result.token.length > 0) {
            localStorage.setItem('token', result.token);
            delete result.token;
            localStorage.setItem('currentUser', JSON.stringify(result));
            window.location = '/';
          } else {
            throw new Error('unauthenticated')
          }
        })
        .catch(err => {
          this.setState({isLoading: false, failedAttempt: false});
          if (err && err.response && err.response.status && err.response.status === 401) {
            this.setState({isLoading: false, failedAttempt: true});
          } else {
            errorService.handle(err);
          }
        });
    });
  };

  renderResetToast = () => {
    return (
      <EuiGlobalToastList
        toasts={this.state.toasts}
        dismissToast={() => {
          this.setState({toasts: []});
        }}
        toastLifeTimeMs={6000}
      />
    );
  };

  renderLogin = () => {
    return (
      <EuiPageContent
        betaBadgeLabel={'Login'}
        verticalPosition="center"
        horizontalPosition="center"
        style={{
          minWidth: '332px',
        }}>
        <EuiForm style={{marginTop: '55px'}}>
          <EuiFormRow
            isInvalid={this.state.validationFields['emailAddress'].isInvalid}
            error={
              this.state.validationFields['emailAddress'].isInvalid &&
              this.state.validationFields['emailAddress'].errors
            }>
            <EuiFieldText
              placeholder="Login"
              value={this.state.emailAddress}
              name="emailAddress"
              onChange={this.handleChange}
              aria-label="Login"
            />
          </EuiFormRow>

          <EuiFormRow
            isInvalid={this.state.validationFields['password'].isInvalid}
            error={
              this.state.validationFields['password'].isInvalid &&
              this.state.validationFields['password'].errors
            }>
            <EuiFieldPassword
              placeholder="Sandi"
              value={this.state.password}
              name="password"
              onChange={this.handleChange}
              aria-label="Sandi"
            />
          </EuiFormRow>
          <EuiFormRow
            isInvalid={this.state.failedAttempt}
            error={'Alamat email atau kata sandi Anda tidak tepat.'}
            >
            <></>
          </EuiFormRow>

          <EuiFlexGroup
            style={{marginTop: '55px'}}
            justifyContent="spaceBetween"
            alignItems="flexEnd">
            <EuiFlexItem>
              <EuiLink
                href="#"
                external
                onClick={() => {
                  this.setState({renderForm: 'resetPassword'});
                }}>
                Lupa sandi
              </EuiLink>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiButton
                fill
                isLoading={this.state.isLoading}
                disabled={this.state.isLoading}
                onClick={this.login}>
                Masuk
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiForm>
      </EuiPageContent>
    );
  };

  renderResetPassword = () => {
    return (
      <EuiPageContent
        betaBadgeLabel={'Reset Sandi'}
        verticalPosition="center"
        horizontalPosition="center"
        style={{
          minWidth: '332px',
        }}>
        <EuiForm style={{marginTop: '55px'}}>
          <EuiFormRow label="Masukkan alamat surel:">
            <EuiFieldText
              placeholder="Surel"
              // value={value}
              // onChange={e => onChange(e)}
              aria-label="Surel"
            />
          </EuiFormRow>

          <EuiFlexGroup
            style={{marginTop: '55px'}}
            justifyContent="spaceBetween"
            alignItems="flexEnd">
            <EuiFlexItem>
              <EuiLink
                href="#"
                external
                onClick={() => {
                  this.setState({renderForm: 'login'});
                }}>
                Login
              </EuiLink>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiButton
                fill
                onClick={() => {
                  this.setState({
                    toasts: this.state.toasts.concat({
                      title: 'Terima kasih',
                      color: 'success',
                      text: (
                        <Fragment>
                          <p>
                            Surel berisi tautan untuk reset sandi telah
                            dikirimkan ke alamat yang tadi dimasukkan. Silakan
                            ikuti instruksi yang diberikan dalam surel tersebut.
                          </p>
                        </Fragment>
                      ),
                    }),
                  });
                }}>
                Kirim
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiForm>
      </EuiPageContent>
    );
  };

  renderSwitch = () => {
    switch (this.state.renderForm) {
      case 'login':
        return this.renderLogin();
      case 'resetPassword':
        return this.renderResetPassword();
      default:
        return this.renderLogin();
    }
  };

  render() {
    return (
      <EuiPage>
        <EuiPageBody style={{minHeight: '100vh'}}>
          {this.renderSwitch()}
          {this.renderResetToast()}
        </EuiPageBody>
      </EuiPage>
    );
  }
}

export default Landing;
