import Config from "../Config";
import axios from "axios";
import Error from './Error';
import Misc from "./Misc";

const ErrorService = new Error();
const MiscService = new Misc();

class Monitoring {
  getSessions = (payload) => {
    return new Promise((resolve, reject) => {

      let url = `${Config.apiHost}/api/current-sessions`
      axios.get(url, { headers: MiscService.generateHeaders() })
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => {
        reject(err)
        ErrorService.handle(err)
      })
    });
  }

}

export default Monitoring;
