import React, { Component } from 'react';
import { debounce } from 'lodash';
import {
  EuiPage,
  EuiPageBody,
  EuiFlexItem,
  EuiFieldText,
  EuiButtonEmpty,
  EuiTab,
  EuiPageContent,
  EuiPageContentHeaderSection,
  EuiTitle,
  EuiPageContentHeader,
  EuiTabs,
  EuiPageContentBody,
  EuiFlexGroup,
  EuiSelect,
  EuiDatePicker,
} from '@elastic/eui';
import TablePage from '../components/TablePage';
import PharmaciesService from '../services/Pharmacies';
import moment from 'moment';
import Blobs from '../services/Blob';
import downloadjs from 'downloadjs';
import VariantService from '../services/Variants.js';
import swal from 'sweetalert';

const pharmaciesService = new PharmaciesService();
const variantService = new VariantService();

class KimiaFarmaPharmacies extends Component {
  state = {
    page: 1,
    limit: 10,
    search: '',
    loading: true,
    orderBy: 'created_at',
    pharmacy: 'kimiafarma',
    column: [
      {
        field: 'external_id',
        name: 'ID Transaksi',
        sortable: false,
      },
      {
        field: 'created_at',
        name: 'Tanggal Pembuatan Resep',
      },
      {
        field: 'author_name',
        name: 'Nama Dokter',
      },
      {
        field: 'expired_at',
        name: 'Masa Berlaku',
      },
      {
        field: 'patient_name',
        name: 'Nama Pasien',
      },
      {
        field: 'pharmacy_name',
        name: 'Nama Apotek',
      },
      {
        field: 'pharmacy_address',
        name: 'Lokasi Apotek',
        sortable: false,
      },
      {
        field: 'status',
        name: 'Status',
      },
      {
        field: 'receipt_blob_id',
        name: 'Aksi',
        width: '8%',
        render: (prescription_receipt, item) => (
          <EuiButtonEmpty
            size='xs'
            className='btnList'
            onClick={() => this.getPrescriptionCopy(item.id, 'id')}
          >
            Unduh Resep
          </EuiButtonEmpty>
          
        ),
        sortable: false,
      },
    ],
    selectedTabId: 'all',
    tabs: [
      {
        id: 'all',
        name: 'Semua',
        disabled: false,
      },
      {
        id: 'CREATED',
        name: 'Resep dibuat',
        disabled: false,
      },
      {
        id: 'INVOICE',
        name: 'Invoice dibuat',
        disabled: false,
      },
      {
        id: 'PAID',
        name: 'Resep Dibayar',
        disabled: false,
      },
      {
        id: 'PROCESSED',
        name: 'Resep diproses',
        disabled: false,
      },
      {
        id: 'READY_FOR_PICKUP',
        name: 'Siap diambil',
        disabled: false,
      },
      {
        id: 'DONE',
        name: 'Resep diterima',
        disabled: false,
      },
    ],
    dateRangeOptions: [
      {
        text: 'Semua Tanggal',
        value: 'all',
      },
      {
        text: 'Tanggal Pembuatan Resep',
        value: 'created_at',
      },
      {
        text: 'Tanggal Masa Berlaku',
        value: 'expired_at',
      },
    ],
    searchOptions: [
      {
        text: 'Dokter',
        value: 'author_name',
      },
      {
        text: 'Pasien',
        value: 'patient_name',
      },
      {
        text: 'Nama Apotek',
        value: 'pharmacy_name',
      },
    ],
    searchString: '',
    searchBy: 'author_name',
    searchStartDate: moment(),
    searchEndDate: moment(),
    specificDate: moment(),
    dateRange: 'all',
    data: [],
    order: false,
  };

  componentDidMount = () => {
    let variant = this.props.match.params.variant;
    if (!variant || (variant && variant.length < 1)) {
      this.props.history.push('/');
      return;
    }

    variantService.getVariants().then((result) => {
      for (let i in result.items) {
        if (result.items[i].variant === variant) {
          console.log(result.items[i]);
          this.setState({
            variantName: result.items[i].name,
            variant: variant,
          });
          this.page(1);
          break;
        }
      }
    });
  };

  page = (page) => {
    page = page || 1;
    let payload = {
      page: page,
      limit: this.state.limit,
      order: this.state.order ? 'asc' : 'desc',
      orderBy: this.state.orderBy,
      pharmacy: this.state.pharmacy,
      search: this.state.searchString,
      searchBy: this.state.searchBy,
      searchDateBy: this.state.dateRange,
      searchStartDate: moment(this.state.searchStartDate).format('YYYY-MM-DD'),
      searchEndDate: moment(this.state.searchEndDate).format('YYYY-MM-DD'),
      status:
        this.state.selectedTabId === 'all' ? '' : this.state.selectedTabId,
      variant: this.state.variant,
    };

    if (this.state.dateRange === 'all') {
      console.log('DATE RANGE IS ALL');
      payload.searchDateBy = '';
      payload.searchEndDate = '';
      payload.searchStartDate = '';
    }

    console.log('sented payload ------> ', payload);

    this.setState(
      {
        loading: true,
      },
      () => {
        pharmaciesService
          .getPharmacyPrescriptions(payload)
          .then((res) => {
            console.log(res.items);
            for (let i in res.items) {
              res.items[i].status = res.items[i].status || 0;

              switch (res.items[i].status) {
                case 0:
                  res.items[i].status = 'Resep telah dibuat';
                  break;
                case 1:
                  res.items[i].status = 'Invoice resep telah dibuat';
                  break;
                case 2:
                  res.items[i].status = 'Resep telah dibayar';
                  break;
                case 3:
                  res.items[i].status = 'Resep sedang diproses';
                  break;
                case 4:
                  res.items[i].status = 'Resep siap untuk diambil';
                  break;
                case 10:
                  res.items[i].status = 'Resep sudah diterima oleh pasien';
                  break;
                default:
                  res.items[i].status = 'Status tidak ditemukan';
                  break;
              }

              res.items[i].created_at = res.items[i].created_at
                ? moment(res.items[i].created_at, 'YYYY-MM-DD HH:mm:ss').format(
                    'DD MMMM YYYY HH:mm'
                  )
                : '-';

              res.items[i].expired_at = res.items[i].expired_at
                ? moment(res.items[i].expired_at, 'YYYY-MM-DD HH:mm:ss').format(
                    'DD MMMM YYYY HH:mm'
                  )
                : '-';
            }
            this.setState(
              {
                page: res.page,
                limit: res.limit,
                data: res.items,
                total: res.total,
                loading: false,
              },
              () => {
                this.forceUpdate();
              }
            );
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              loading: false,
            });
          });
      }
    );
  };

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };

  onSelectedTabChanged = (id) => {
    this.setState(
      { loadingData: true, selectedTabId: id, searchDate: '' },
      () => {
        this.page(1);
      }
    );
  };

  renderTabs = () => {
    return this.state.tabs.map((tab, index) => (
      <EuiTab
        {...(tab.href && { href: tab.href, target: '_blank' })}
        onClick={() => this.onSelectedTabChanged(tab.id)}
        isSelected={tab.id === this.state.selectedTabId}
        disabled={tab.disabled}
        key={index}
      >
        {tab.name}
      </EuiTab>
    ));
  };

  onDateRangeChange = (e) => {
    let value = e.target.value || null;
    this.setState(
      { dateRange: value, searchStartDate: moment(), searchEndDate: moment() },
      () => {
        if (value === 'all') {
          this.page(1);
        }
      }
    );
  };

  handleDateChange = (date) => {
    // in moment object format
    this.setState({ searchStartDate: date });
  };

  handleDateChangeEnd = (date) => {
    // in moment object format
    this.setState({ searchEndDate: date, loading: true }, () => {
      this.page(1);
    });
  };

  onSearchByChange = (e) => {
    let value = e.target.value || null;
    this.setState({ searchBy: value, searchString: '' });
  };

  searchStringChange = debounce((value) => {
    this.setState({ searchString: value, loading: true }, () => {
      this.page(1);
    });
  }, 1000);

  onSearchStringChange = (e) => {
    let value = e.target.value || null;
    if (!value || (value && value.length < 1)) {
      this.setState({ searchString: '' });
    }
    this.setState({ searchString: value });
    this.searchStringChange(value);
  };

  getPrescriptionCopy = (id, lang, forceRegenerate, cb) => {
    let payload = {
      pharmacy: this.state.pharmacy,
      variant: this.state.variant,
      id: id,
      lang: lang,
      forceRegenerate: forceRegenerate
    };

      pharmaciesService
        .getRefPrescriptionCopy(payload)
        .then((result) => {
          let fileName = `prescription_${id}.pdf`;
          if (lang === 'en') {
            fileName = `prescription_${id}_en.pdf`;
          }
          if (!forceRegenerate) {
            downloadjs(result, fileName, `application/pdf`);
          } else {
            cb();
          }
        })
        .catch((err) => {
          console.log(err);
          // parse error message
          let message = err?.message || '';
          if (err?.response?.data?.code) {
            message = err.response.data.code;
          }

          if (err.response?.status === 404) {
            message = 'Mohon maaf, resep ini belum terbuat';
          }

          swal({
            title: 'Gagal mengunduh resep',
            icon: 'error',
            type: 'error',
            text: message,
          });
        })
        .finally(() => {
          this.setState({
            loadingCopy: false,
          });
        });
  };

  renderFilterComponent = () => {
    return (
      <div>
        <div style={{ display: 'inline-block' }}>
          <EuiFlexGroup>
            <EuiFlexItem grow={false} style={{ marginRight: '-10px' }}>
              <EuiSelect
                style={{ display: 'inline-block !important' }}
                placeholder='Cari berdasarkan tanggal'
                options={this.state.dateRangeOptions}
                value={this.state.dateRange}
                name='dateRange'
                onChange={this.onDateRangeChange}
                aria-label='Cari berdasarkan tanggal'
              />
            </EuiFlexItem>
            {this.state.dateRange !== 'all' && (
              <EuiFlexGroup style={{ margin: '0px' }}>
                <EuiFlexItem grow={false}>
                  <EuiDatePicker
                    selected={this.state.searchStartDate}
                    onChange={this.handleDateChange}
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <div style={{ marginTop: 10 }}>sampai</div>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiDatePicker
                    selected={this.state.searchEndDate}
                    onChange={this.handleDateChangeEnd}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            )}
          </EuiFlexGroup>
        </div>
        <div style={{ display: 'inline-block', marginLeft: 30 }}>
          <EuiFlexGroup>
            <EuiFlexItem grow={false} style={{ marginRight: '-10px' }}>
              <EuiSelect
                style={{ display: 'inline-block !important' }}
                placeholder='Cari berdasarkan'
                options={this.state.searchOptions}
                value={this.state.searchBy}
                name='searchBy'
                onChange={this.onSearchByChange}
                aria-label='Cari berdasarkan'
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFieldText
                style={{ display: 'inline-block !important' }}
                placeholder='Cari...'
                name='searchString'
                onChange={this.onSearchStringChange}
                value={this.state.searchString}
                append={
                  <EuiButtonEmpty onClick={() => this.onSearchStringChange}>
                    Cari
                  </EuiButtonEmpty>
                }
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
      </div>
    );
  };

  onTableChange = ({ page = {}, sort = {} }) => {
    const { field: sortField, direction: sortDirection } = sort;
    this.setState({ order: !this.state.order, orderBy: sortField }, () => {
      this.page(this.state.page);
    });
  };

  render = () => {
    const sorting = {
      sort: {
        field: this.state.orderBy,
        direction: this.state.order,
      },
      enableAllColumns: true,
    };

    return (
      <>
        <EuiPage className='class'>
          <EuiPageBody
            style={{ flexDirection: 'row' }}
            className={'content-container'}
          >
            <EuiPageContent>
              <EuiPageContentHeader>
                <EuiPageContentHeaderSection>
                  <EuiTitle>
                    <h2>Daftar Transaksi KF {this.state.variantName || ''}</h2>
                  </EuiTitle>
                </EuiPageContentHeaderSection>
              </EuiPageContentHeader>
              <EuiPageContentHeader>
                <EuiTabs>{this.renderTabs()}</EuiTabs>
              </EuiPageContentHeader>
              <EuiPageContentHeader>
                {this.renderFilterComponent()}
              </EuiPageContentHeader>
              <EuiPageContentBody>
                <EuiFlexItem>
                  <EuiPage className='class'>
                    <TablePage
                      data={this.state.data}
                      column={this.state.column}
                      disablePagination={this.state.loading}
                      page={this.state.page}
                      limit={this.state.limit}
                      total={this.state.total}
                      prev={this.prevPage}
                      next={this.nextPage}
                      toPage={this.page}
                      onItemClick={this.onItemClick}
                      loading={this.state.loading}
                      sort={sorting}
                      change={this.onTableChange}
                    />
                  </EuiPage>
                </EuiFlexItem>
              </EuiPageContentBody>
            </EuiPageContent>
          </EuiPageBody>
        </EuiPage>
      </>
    );
  };
}

export default KimiaFarmaPharmacies;
