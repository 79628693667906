import Config from "../Config";
import axios from "axios";
import Error from "./Error";
import Misc from "./Misc";

const ErrorService = new Error();
const MiscService = new Misc();

class AppSettings {
  getAppSettings = payload => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload.page = payload.page || 1;
      payload.limit = payload.limit || 10;
      payload.order = payload.Order || "DESC";
      payload.search = payload.search || "";
      let url = `${Config.apiHost}/api/app-settings?page=${payload.page}&limit=${payload.limit}&order=${payload.order}&search=${payload.search}`;
      if (payload.search && payload.search.length > 0) {
        url += `&search=${payload.search}`;
      }
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then(response => {
          console.log(response)
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  createAndUpdate = payload => {
    console.log(payload)
    return new Promise((resolve, reject) => {
      payload = payload || {};
      let url = `${Config.apiHost}/api/app-settings`;
      axios
        .put(url, payload, { headers: MiscService.generateHeaders() })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  delete = key => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/app-settings/${key}`;
      axios
        .delete(url, { headers: MiscService.generateHeaders() })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
}

export default AppSettings;
