import Config from '../Config';
import axios from 'axios';
import Error from './Error';
import Misc from './Misc';

const ErrorService = new Error();
const MiscService = new Misc();

class SummaryOverview {
  getSummaries = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload.type = payload.type || 'monthly';
      payload.year = payload.year || '';
      payload.monthYearStart = payload.monthYearStart || '';
      payload.monthYearEnd = payload.monthYearEnd || '';
      payload.startDate = payload.startDate || '';
      payload.endDate = payload.endDate || '';

      let url = `${Config.apiHost}/api/v1/partner-summaries?variant=${payload.variant}&type=${payload.type}&year=${payload.year}&month_year_start=${payload.monthYearStart}&month_year_end=${payload.monthYearEnd}&start_date=${payload.startDate}&end_date=${payload.endDate}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getOverview = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload.type = payload.type || '';
      payload.startDate = payload.startDate || '';
      payload.endDate = payload.endDate || '';

      let url = `${Config.apiHost}/api/v1/partner-overview?variant=${payload.variant}&type=${payload.type}&year=${payload.year}&start_date=${payload.startDate}&end_date=${payload.endDate}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export default SummaryOverview;
