import React, { Component, useEffect } from 'react';

import {
  EuiButtonEmpty,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiText,
  EuiPanel,
  EuiDatePicker,
  EuiFlexItem,
  EuiFlexGroup,
  EuiFieldText,
  EuiSelect,
  EuiRadioGroup,
  EuiTextArea,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiPageContentBody,
  EuiTitle,
  EuiLink,
  EuiLoadingSpinner,
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiButton,
  EuiIcon,
  EuiBadge,
  EuiTab,
  EuiTabs,
  EuiPopover,
  EuiButtonIcon,
  EuiCard,
  EuiHorizontalRule
} from '@elastic/eui';
import TablePage from '../components/TablePage';
import SessionService from '../services/Sessions';
import Staff from '../services/Staffs';
import SessionSlotService from '../services/SessionSlots';
import ErrorService from '../services/Error.js';
import OrganizationService from '../services/Organizations';
import ClinicService from '../services/Clinics';
import Payments from '../services/Payments';
import WeblinkService from '../services/Weblink';
import Config from '../Config';

import moment from 'moment';
import debounce from 'debounce';
import swal from 'sweetalert';

const clinicService = new ClinicService();
const errorService = new ErrorService();
const sessionsService = new SessionService();
const sessionSlotService = new SessionSlotService();
const staffService = new Staff();
const organizationService = new OrganizationService();
const paymentService = new Payments();
const weblinkService = new WeblinkService();

const ChangeCloseModal = (props) => {
  const { sessionItem, onScheduleChange } = props;
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [
    availableClinicOrganizations,
    setAvailableClinicOrganizations,
  ] = React.useState([]);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [radioIdSelected, setRadioIdSelected] = React.useState(4); // default 4 (ended)
  const [reason, setReason] = React.useState(sessionItem?.description);
  console.log(onScheduleChange, availableClinicOrganizations);

  useEffect(() => {
    loadOrganizations();
  }, []);

  const handleSubmit = () => {
    let payload = {};
    payload.Status = radioIdSelected;
    payload.Description = reason;
    payload.Id = sessionItem.id;

    setLoading(true);
    sessionsService
      .updateSessionStatus(payload)
      .then(() => {
        setLoading(false);
        setSuccess(true);
        closeModal();
        onScheduleChange();
      })
      .catch((err) => {
        setLoading(false);
        errorService.handle(err);
      });
  };

  const closeModal = (resetSuccessState) => {
    if (resetSuccessState) {
      setSuccess(false);
    }
    setIsModalVisible(false);
    setRadioIdSelected(4);
    setReason(sessionItem?.description);
  };

  const showModal = () => setIsModalVisible(true);
  const loadOrganizations = () => {
    let payload = {
      limit: 1000,
    };
    organizationService
      .getOrganizations(payload)
      .then((result) => {
        let options = [{ value: '', text: 'Pilih organisasi' }];
        for (let i in result.items) {
          options.push({
            value: result.items[i].id,
            text: result.items[i].name,
          });
        }
        setAvailableClinicOrganizations(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let oldSlotPanel = (
    <EuiPanel>
      <EuiText>
        <p>
          <b>{sessionItem?.patientName}</b>
          <br />
          <small>{sessionItem?.patientExternalId}</small>
          <br />
          <br />
          Jadwal Saat Ini
          <br />
          <small>
            <b>{sessionItem?.staffName}</b>
          </small>
          <br />
          <small>{sessionItem?.clinicName}</small>
          <br />
          <small>
            {`${moment(sessionItem?.scheduleDate).format('dddd')}`},{' '}
            {`${moment(sessionItem?.scheduleDate).format('LL')}`} pukul{' '}
            {sessionItem?.scheduleTime} <br />
          </small>
          <br />
        </p>
      </EuiText>
    </EuiPanel>
  );

  const onChangeCloseReason = (optionId) => {
    setRadioIdSelected(optionId);
  };

  let form = (
    <EuiPanel>
      <EuiForm>
        <p>di-close karena</p>
        <br />
        <EuiRadioGroup
          options={[
            {
              id: 4,
              label: 'Ended',
            },
            {
              id: 3,
              label: 'Disabled',
            },
            {
              id: 7,
              label: 'Refund',
            },
          ]}
          idSelected={radioIdSelected}
          onChange={onChangeCloseReason}
          name="close option"
        />
        <EuiFormRow label="Alasan*">
          <div>
            <EuiTextArea
              placeholder="Masukan alasan..."
              aria-label="description"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
        </EuiFormRow>
      </EuiForm>
    </EuiPanel>
  );

  let modal;
  if (isModalVisible) {
    modal = (
      <EuiOverlayMask>
        <EuiModal onClose={closeModal} initialFocus="[name=popswitch]">
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              Close Temu-Janji Konsultasi
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            {oldSlotPanel}
            <EuiSpacer />
            <br />
            <br />
            {loading ? (
              <p>
                <EuiLoadingSpinner size="xl" />
              </p>
            ) : (
              form
            )}
          </EuiModalBody>

          {loading ? (
            ''
          ) : !success ? (
            <EuiModalFooter>
              <EuiButton onClick={handleSubmit} fill disabled={!reason}>
                Submit
              </EuiButton>
              <EuiButtonEmpty onClick={closeModal}>Batal</EuiButtonEmpty>
            </EuiModalFooter>
          ) : (
            <EuiModalFooter>
              <EuiButtonEmpty onClick={() => closeModal(true)}>
                Batal
              </EuiButtonEmpty>
            </EuiModalFooter>
          )}
        </EuiModal>
      </EuiOverlayMask>
    );
  }
  return (
    <div id={'change-close-modal'}>
      <EuiButtonEmpty size="xs" onClick={showModal}>
        Tutup Sesi
      </EuiButtonEmpty>
      {modal}
    </div>
  );
};

const ChangeActivateEndedSession = (props) => {
  const { sessionItem, onActivate, closePopover } = props;
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [
    availableClinicOrganizations,
    setAvailableClinicOrganizations,
  ] = React.useState([]);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [reason, setReason] = React.useState(``);

  const handleSubmit = () => {
    let payload = {};
    payload.Status = 1;
    payload.Description = reason;
    payload.Id = sessionItem.id;

    setLoading(true);
    sessionsService
      .updateSessionStatus(payload)
      .then(() => {
        setLoading(false);
        setSuccess(true);
        closeModal();
        onActivate();
        closePopover();
      })
      .catch((err) => {
        setLoading(false);
        errorService.handle(err);
      });
  };

  const closeModal = (resetSuccessState) => {
    if (resetSuccessState) {
      setSuccess(false);
    }
    setIsModalVisible(false);
    setReason('');
  };

  const showModal = () => setIsModalVisible(true);

  let oldSlotPanel = (
    <EuiPanel>
      <EuiText>
        <p>
          <b>{sessionItem?.patientName}</b>
          <br />
          <small>{sessionItem?.patientExternalId}</small>
          <br />
          <br />
          Jadwal Saat Ini
          <br />
          <small>
            <b>{sessionItem?.staffName}</b>
          </small>
          <br />
          <small>{sessionItem?.clinicName}</small>
          <br />
          <small>
            {`${moment(sessionItem?.scheduleDate).format('dddd')}`},{' '}
            {`${moment(sessionItem?.scheduleDate).format('LL')}`} pukul{' '}
            {sessionItem?.scheduleTime} <br />
          </small>
          <br />
        </p>
      </EuiText>
    </EuiPanel>
  );

  let form = (
    <EuiPanel>
      <EuiForm>
        <EuiFormRow label="Alasan sesi diaktifkan kembali*">
          <div>
            <EuiTextArea
              placeholder="Masukan alasan..."
              aria-label="description"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
        </EuiFormRow>
      </EuiForm>
    </EuiPanel>
  );

  let modal;
  if (isModalVisible) {
    modal = (
      <EuiOverlayMask>
        <EuiModal onClose={closeModal} initialFocus="[name=popswitch]">
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              Aktifkan Kembali Temu-janji Konsultasi
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            {oldSlotPanel}
            <EuiSpacer />
            <br />
            {loading ? (
              <p>
                <EuiLoadingSpinner size="xl" />
              </p>
            ) : (
              form
            )}
          </EuiModalBody>

          {loading ? (
            ''
          ) : !success ? (
            <EuiModalFooter>
              <EuiButton onClick={handleSubmit} fill disabled={!reason}>
                Submit
              </EuiButton>
              <EuiButtonEmpty onClick={closeModal}>Batal</EuiButtonEmpty>
            </EuiModalFooter>
          ) : (
            <EuiModalFooter>
              <EuiButtonEmpty onClick={() => closeModal(true)}>
                Batal
              </EuiButtonEmpty>
            </EuiModalFooter>
          )}
        </EuiModal>
      </EuiOverlayMask>
    );
  }
  return (
    <div id={'change-close-modal'}>
      <EuiButtonEmpty size="xs" onClick={showModal}>
        Aktifkan Kembali Sesi
      </EuiButtonEmpty>
      {modal}
    </div>
  );
};


const ChangeScheduleModal = (props) => {
  const { sessionItem, onScheduleChange } = props;
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [
    availableClinicOrganizations,
    setAvailableClinicOrganizations,
  ] = React.useState([]);
  const [availableClinics, setAvailableClinics] = React.useState([]);
  const [availableDoctors, setAvailableDoctors] = React.useState([]);
  const [availableSessionSlots, setAvailableSessionSlots] = React.useState([]);
  const [clinicOrganizationId, setClinicOrganizationId] = React.useState('');
  const [pickedClinicId, setPickedClinicId] = React.useState('');
  const [pickedDoctorId, setPickedDoctorId] = React.useState('');
  const [pickedSessionSlotId, setPickedSessionSlotId] = React.useState('');
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  useEffect(() => {
    loadOrganizations();
  }, []);

  const handleSubmit = () => {
    let payload = {};
    console.log(payload);

    payload.to_slot = pickedSessionSlotId;
    payload.from_session = sessionItem.id;
    setLoading(true);
    sessionsService
      .moveSessionToAnotherSlot(pickedSessionSlotId, payload)
      .then(() => {
        setLoading(false);
        setSuccess(true);
        closeModal();
        onScheduleChange(payload);
      })
      .catch((err) => {
        setLoading(false);
        errorService.handle(err);
      });
  };

  const closeModal = (resetSuccessState) => {
    if (resetSuccessState) {
      setSuccess(false);
    }
    setIsModalVisible(false);
  };

  const showModal = () => setIsModalVisible(true);
  const loadOrganizations = () => {
    let payload = {
      limit: 1000,
    };
    organizationService
      .getOrganizations(payload)
      .then((result) => {
        let options = [{ value: '', text: 'Pilih organisasi' }];
        for (let i in result.items) {
          options.push({
            value: result.items[i].id,
            text: result.items[i].name,
          });
        }
        setAvailableClinicOrganizations(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadClinics = (organizationId) => {
    let payload = {
      limit: 1000,
      organizationId: organizationId,
    };
    clinicService
      .getClinics(payload)
      .then((result) => {
        let options = [];
        for (let i in result.items) {
          let exist = false;
          for (let j in availableClinics) {
            if (result.items[i].id === availableClinics[j].id) {
              exist = true;
              break;
            }
          }
          if (exist) {
            continue;
          }
          options.push({
            value: result.items[i].id,
            text: result.items[i].name,
          });
        }
        if (options && options.length < 1) {
          options.splice(0, 0, {
            value: '',
            text: 'Tidak ada klinik tersedia',
          });
        } else {
          options.splice(0, 0, {
            value: '',
            text: 'Pilih klinik..',
          });
        }
        setAvailableClinics(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadDoctors = (clinicId) => {
    let payload = {
      limit: 1000,
      clinicId: clinicId,
    };
    staffService
      .getStaffs(payload)
      .then((result) => {
        let options = [];
        for (let i in result.items) {
          let exist = false;
          for (let j in availableDoctors) {
            if (result.items[i].id === availableDoctors[j].id) {
              exist = true;
              break;
            }
          }
          if (exist) {
            continue;
          }
          options.push({
            value: result.items[i].id,
            text: result.items[i].name,
          });
        }
        if (options && options.length < 1) {
          options.splice(0, 0, {
            value: '',
            text: 'Tidak ada dokter tersedia',
          });
        } else {
          options.splice(0, 0, {
            value: '',
            text: 'Pilih dokter..',
          });
        }
        setAvailableDoctors(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadSessionSlot = (staffId) => {
    let payload = {
      limit: 1000,
      staffId: staffId,
      clinicId: pickedClinicId,
      searchBy: '',
    };
    sessionSlotService
      .getSessionSlots(payload)
      .then((result) => {
        console.log(result);
        let options = [];
        for (let i in result.items) {
          let exist = false;
          for (let j in availableSessionSlots) {
            if (result.items[i].id === availableSessionSlots[j].id) {
              exist = true;
              break;
            }
          }
          if (exist) {
            continue;
          }
          options.push({
            value: result.items[i].id,
            text: `${result.items[i].specificDate}, ${result.items[i].startTime}-${result.items[i].endTime} `,
          });
        }
        if (options && options.length < 1) {
          options.splice(0, 0, {
            value: '',
            text: 'Tidak ada jadwal tersedia',
          });
        } else {
          options.splice(0, 0, {
            value: '',
            text: 'Pilih jadwal..',
          });
        }
        setAvailableSessionSlots(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClinicOrganizationChange = (e) => {
    let value = e.target.value || null;
    setClinicOrganizationId(value);
    loadClinics(value);
  };

  const onPickedClinic = (e) => {
    let value = e.target.value || null;
    if (!value || (value && value.length < 1)) {
      return;
    }

    setPickedClinicId(value);
    loadDoctors(value);
  };

  const onPickedDoctor = (e) => {
    let value = e.target.value || null;
    if (!value || (value && value.length < 1)) {
      return;
    }

    setPickedDoctorId(value);
    loadSessionSlot(value);
  };

  const onPickedSessionSlot = (e) => {
    let value = e.target.value || null;
    if (!value || (value && value.length < 1)) {
      return;
    }

    setPickedSessionSlotId(value);
  };

  let oldSlotPanel = (
    <EuiPanel>
      <EuiText>
        <p>
          <b>{sessionItem?.patientName}</b>
          <br />
          <small>{sessionItem?.patientExternalId}</small>
          <br />
          <br />
          Jadwal Saat Ini
          <br />
          <small>
            <b>{sessionItem?.staffName}</b>
          </small>
          <br />
          <small>{sessionItem?.clinicName}</small>
          <br />
          <small>
            {`${moment(sessionItem?.scheduleDate).format('dddd')}`},{' '}
            {`${moment(sessionItem?.scheduleDate).format('LL')}`} pukul{' '}
            {sessionItem?.scheduleTime} <br />
          </small>
          <br />
        </p>
      </EuiText>
    </EuiPanel>
  );

  let form = (
    <EuiPanel>
      <EuiForm>
        <p>Jadwal Baru</p>
        <br />
        <EuiFormRow label="Nama Unit">
          <EuiSelect
            placeholder="Unit"
            options={availableClinicOrganizations}
            value={clinicOrganizationId}
            name="clinicOrganizationId"
            onChange={(e) => {
              onClinicOrganizationChange(e);
            }}
            aria-label="Organisasi"
          />
        </EuiFormRow>
        {clinicOrganizationId && clinicOrganizationId.length > 0 && (
          <EuiFormRow label="Nama Klinik">
            <div>
              <EuiSelect
                placeholder="Klinik"
                options={availableClinics}
                value={pickedClinicId}
                name="pickedClinicName"
                onChange={(e) => {
                  onPickedClinic(e);
                }}
                aria-label="Klinik"
              />
            </div>
          </EuiFormRow>
        )}
        {pickedClinicId && pickedClinicId.length > 0 && (
          <EuiFormRow label="Nama Dokter">
            <div>
              <EuiSelect
                placeholder="Dokter"
                options={availableDoctors}
                value={pickedDoctorId}
                name="pickedDoctorName"
                onChange={(e) => {
                  onPickedDoctor(e);
                }}
                aria-label="Dokter"
              />
            </div>
          </EuiFormRow>
        )}
        {pickedDoctorId && pickedDoctorId.length > 0 && (
          <EuiFormRow label="Pilih Jadwal">
            <div>
              <EuiSelect
                placeholder="Jadwal"
                options={availableSessionSlots}
                value={pickedSessionSlotId}
                name="pickedSessionSlotId"
                onChange={(e) => {
                  onPickedSessionSlot(e);
                }}
                aria-label="Jadwal"
              />
            </div>
          </EuiFormRow>
        )}
      </EuiForm>
    </EuiPanel>
  );

  let modal;
  if (isModalVisible) {
    modal = (
      <EuiOverlayMask>
        <EuiModal onClose={closeModal} initialFocus="[name=popswitch]">
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              Lihat / Ubah Jadwal Pasien
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            {oldSlotPanel}
            <EuiSpacer />
            <br />
            <center> Menjadi </center>
            <br />
            {loading ? (
              <p>
                <EuiLoadingSpinner size="xl" />
              </p>
            ) : (
              form
            )}
          </EuiModalBody>

          {loading ? (
            ''
          ) : !success ? (
            <EuiModalFooter>
              <EuiButton onClick={handleSubmit} fill>
                Ubah
              </EuiButton>
              <EuiButtonEmpty onClick={closeModal}>Batal</EuiButtonEmpty>
            </EuiModalFooter>
          ) : (
            <EuiModalFooter>
              <EuiButtonEmpty onClick={() => closeModal(true)}>
                Kembali
              </EuiButtonEmpty>
            </EuiModalFooter>
          )}
        </EuiModal>
      </EuiOverlayMask>
    );
  }
  return (
    <div id={'change-session-btn'}>
      <EuiButtonEmpty size="xs" onClick={showModal}>
        Reschedule
      </EuiButtonEmpty>
      {modal}
    </div>
  );
};
class Consultations extends Component {
  state = {
    page: 1,
    limit: 10,
    order: true,
    search: '',
    searchString: '',
    patientSearchString: '',
    searchBy: 'staffName',
    orderBy: 'name',
    data: [],
    specificDate: moment(),
    dateRange: 'all',
    selectedOrganizationId: '',
    organizationId: '',
    clinicOrganizationId: '',
    sessionId: '',
    showModal: false,
    loading: false,
    availableClinicOrganizations: [],
    selectedTabId: 'today',
    popOver: {},
    showDescription: false,
    prevDescription: '',
    description: '',
    editDescription: false,
    loadingDescription: false,
    sessionItem: {},
    tabs: [
      {
        id: 'today',
        name: 'Hari ini',
        disabled: false,
      },
      {
        id: 'new',
        name: 'New',
        disabled: false,
      },
      {
        id: 'active',
        name: 'Aktif',
        disabled: false,
      },
      {
        id: 'ended',
        name: 'Ended',
        disabled: false,
      },
      {
        id: 'refunded',
        name: 'Refund',
        disabled: false,
      },
      {
        id: 'disabled',
        name: 'Disabled',
        disabled: false,
      },
      {
        id: 'all',
        name: 'All',
        disabled: false,
      },
    ],
    dateRangeOptions: [
      {
        text: 'Semua',
        value: 'all',
      },
      {
        text: 'Tanggal',
        value: 'specificDate',
      },
    ],
    searchOptions: [
      {
        text: 'Dokter',
        value: 'staffName',
      },
      {
        text: 'Pasien',
        value: 'patientName',
      },
    ],
    column: [
      {
        field: 'sequence',
        name: 'No.',
        width: '3%',
      },
      {
        field: 'scheduleDate',
        name: 'Tanggal',
        width: '7%',
      },
      {
        field: 'scheduleTime',
        name: 'Pukul',
        width: '6%',
      },
      {
        field: 'slotNumber',
        name: 'Urutan',
        width: '5%',
      },
      {
        field: 'clinicName',
        name: 'Klinik',
        width: '14%',
      },
      {
        field: 'staffName',
        name: 'Nama staff/dokter',
        width: '16%',
      },
      {
        field: 'patientName',
        name: 'Nama pasien',
        width: '17%',
      },
      {
        field: 'paymentURL',
        name: 'URL Pembayaran',
        width: '10%',
        render: (paymentURL) => (
          <EuiLink href={paymentURL} target="_blank">
            {paymentURL}
          </EuiLink>
        ),
      },
      {
        field: 'doctorURL',
        name: 'URL Dokter',
        width: '8%',
        render: (id, data) => (
          <EuiButtonEmpty onClick={() => this.copyLink('DOCTOR', data.weblinks.doctor)} size="xs">link</EuiButtonEmpty>
        ),
      },
      {
        field: 'patientURL',
        name: 'URL Pasien',
        width: '8%',
        render: (id, data) => (
          <EuiButtonEmpty onClick={() => this.copyLink('PATIENT', data.weblinks.patient)} size="xs">link</EuiButtonEmpty>
        ),
      },
      {
        field: 'assistantURL',
        name: 'URL Asisten',
        width: '8%',
        render: (id, data) => (
          <EuiButtonEmpty onClick={() => this.copyLink('ASSISTANT', data.weblinks.assistant)} size="xs">link</EuiButtonEmpty>
        ),
      },
      {
        field: 'status',
        name: 'Status',
        width: '7%',
        render: (status) => (
          <EuiBadge color={this.statusColor(status)}>
            {this.statusRender(status)}
          </EuiBadge>
        ),
      },
      {
        field: 'id',
        name: 'SOAP',
        width: '7%',
        render: (id) => (
          <EuiBadge color={this.statusColor(this.isSoapComplete(id))}>
            {this.isSoapComplete(id) ? 'SUDAH' : 'BELUM'}
          </EuiBadge>
        ),
      },
      {
        field: 'id',
        name: 'Resep Obat',
        width: '7%',
        render: (id) => (
          <EuiBadge color={this.statusColor(this.isPrescription(id))}>
            {this.isPrescription(id) ? 'SUDAH' : 'BELUM'}
          </EuiBadge>
        ),
      },
      {
        field: 'oldSchedule',
        name: 'Rescheduled',
        sortable: false,
        width: '8%',
        render: (oldSchedule) => (
          <EuiBadge color={this.statusColor(this.isRescheduled(oldSchedule))}>
            {this.isRescheduled(oldSchedule) ? 'YA' : 'TIDAK'}
          </EuiBadge>
        ),
      },
      {
        field: 'description',
        name: 'Deskripsi',
        width: '13%',
        render: (description, sessionItem) => {
          return (
            <EuiCard
              title={
                (description || '').length > 25
                  ? description.substring(0, 25) + '...'
                  : description ? description : 'Belum ada deskripsi...'
              }
              textAlign='left'
              titleSize='xs'
              onClick={() => this.showDescription(description, sessionItem)}
          />
          );
        }
      },
      {
        field: 'insuranceVendor',
        name: 'Asuransi',
        width: '13%',
        render: (insuranceVendor) => (
          <EuiText>
            {insuranceVendor == "" ? "-" : insuranceVendor}
          </EuiText>
        )
      },
      {
        field: 'id',
        name: 'Aksi',
        width: '16%',
        render: (id, sessionItem) => (
          <>
            <EuiPopover
              ownFocus={false}
              button={
                <EuiButtonIcon
                  onClick={() => this.onPopOverClick(id)}
                  iconType="boxesHorizontal"
                  aria-label="option"
                />
              }
              isOpen={this.state.popOver[id]?.isOpen}
              closePopover={() => this.onPopOverClick(id)}
              anchorPosition="upCenter"
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <ChangeScheduleModal
                  sessionItem={sessionItem}
                  onScheduleChange={() => this.page(this.state.page)}
                />
                {sessionItem.status === 4 &&
                  <ChangeActivateEndedSession
                    sessionItem={sessionItem}
                    onActivate={() => this.page(1)}
                    closePopover={() => this.onPopOverClick(id)}
                  />              
                }
                {sessionItem.status !== 1 ? (
                  <div id={'change-close-modal'}>
                    <EuiButtonEmpty
                      size="xs"
                      onClick={() => {
                        return swal({
                          title: '',
                          type: 'error',
                          text:
                            'Mohon maaf, fitur ini tidak dapat digunakan pada temu-janji ini',
                        });
                      }}
                    >
                      Tutup Sesi
                    </EuiButtonEmpty>
                  </div>
                ) : (
                  <ChangeCloseModal
                    sessionItem={sessionItem}
                    onScheduleChange={() => this.page(1)}
                  />
                )}
                <div id="payment-btn">
                  <EuiButtonEmpty
                    size="xs"
                    onClick={() => this.openReceiptBlob(id)}
                  >
                    Lihat Kwitansi
                  </EuiButtonEmpty>
                </div>
                {sessionItem.status == 0 &&
                    <div id="payment-btn">
                      <EuiButtonEmpty
                        size="xs"
                        onClick={() => this.checkPayment(id)}
                      >
                        Cek Pembayaran
                      </EuiButtonEmpty>
                    </div>
                }
                <div id='payment-btn'>
                  <EuiButtonEmpty
                    size='xs'
                    onClick={() =>
                      this.props.history.push({
                        pathname: '/events',
                        state: { secondary_key: id },
                      })
                    }
                  >
                    Lihat events
                  </EuiButtonEmpty>
                </div>
                {!moment(sessionItem.scheduleDate).isBefore(
                  moment().format('YYYY-MM-DD')
                ) &&
                Config.weblink !== '' &&
                sessionItem.status === 1 ? (
                  <a href={'/weblink-list/' + id}>SiapDok link</a>
                ) : null}
              </div>
            </EuiPopover>
          </>
        ),
        sortable: false,
      },
    ],
    loadingData: true,
    reports: [],
    showReports: false,
    loadingReport: false,
  };

  copyLink = (role, id) => {
    if (id!='') {
      navigator.clipboard.writeText(`${Config.weblink}/${weblinkService.makeUserCode(role)}-${id}`)
      alert("Link berhasil disalin")
    } else alert(`tidak ada link ${role}`);
  };

  showDescription = (description, sessionItem) => {
    this.setState({ showDescription: true, prevDescription: description, description, sessionItem });
  };

  onPopOverClick = (id) => {
    let state = this.state;
    if (!state.popOver[id]) {
      state.popOver[id] = { isOpen: true };
    } else {
      console.log(state.popOver[id]);
      state.popOver[id] = { isOpen: !state.popOver[id].isOpen };
    }
    this.setState(state);
  };

  closePopOver = (id) => {
    let state = this.state;
    state.popOver[id] = { isOpen: false };
    this.setState(state);
  };

  componentDidMount = () => {
    this.page(1);
    this.loadOrganizations();
  };

  statusRender = (status) => {
    switch (status) {
      case 0:
        return 'NEW';
      case 1:
        return 'ACTIVE';
      case 2:
        return 'REJECTED';
      case 3:
        return 'DISABLED';
      case 4:
        return 'ENDED';
      case 5:
        return 'CANCELED';
      case 7:
        return 'REFUND';
      default:
        return 'UNKNOWN';
    }
  };

  statusColor = (status) => {
    switch (status) {
      case 0:
      case false:
        return 'warning';
      case 1:
      case true:
        return '#76ff03';
      default:
        return 'danger';
    }
  };

  loadOrganizations = () => {
    let payload = {
      limit: 1000,
    };
    organizationService
      .getOrganizations(payload)
      .then((result) => {
        let options = [{ value: '', text: 'Pilih organisasi' }];
        for (let i in result.items) {
          options.push({
            value: result.items[i].id,
            text: result.items[i].name,
          });
        }
        this.setState({
          organizations: options,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          organizations: [],
        });
      });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      logsData: [],
    });
  };

  openReceiptBlob = (id) => {
    this.setState({ loading: true });
    sessionsService
      .getSessionReceipt(id)
      .then((value) => {
        var binaryData = [];
        binaryData.push(value);
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: 'application/pdf' })
        );
        link.download = `payment-receipt-${id}`;
        link.click();

        this.setState({
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  };

  isSoapComplete = (sessionId) => {
    if (this.state.soaps) {
      return this.state.soaps.includes(sessionId);
    } else {
      return false;
    }
  };

  isPrescription = (sessionId) => {
    if (this.state.prescriptions.includes(`${sessionId}`)) {
      return true;
    }
    return false;
  };

  isRescheduled = (oldSchedule) => {
    let time = moment(oldSchedule);
    return (time.isValid() && time.year() !== 1);
  };

  loadReport = () => {
    let payload = {
      page: 1,
      limit: 10000000,
      order: this.state.order ? 'desc' : 'asc',
      orderBy: this.state.orderBy,
    }

    if (this.state.selectedTabId === 'today') {
      payload.date = moment().format('YYYY-MM-DD');
    } else if (this.state.selectedTabId !== 'all') {
      payload.filterBy = this.state.selectedTabId;
    }

    if (
      this.state.dateRange === 'specificDate' &&
      this.state.specificDate &&
      typeof this.state.specificDate === 'object'
    ) {
      payload.date = this.state.specificDate.format('YYYY-MM-DD');
    }
    if (
      this.state.searchBy === 'patientName' &&
      this.state.patientSearchString &&
      this.state.patientSearchString.length > 0
    ) {
      payload.search = this.state.patientSearchString;
      payload.searchBy = this.state.searchBy;
    }
    if (
      this.state.searchBy === 'staffName' &&
      this.state.searchString &&
      this.state.searchString.length > 0
    ) {
      payload.search = this.state.searchString;
      payload.searchBy = this.state.searchBy;
    }
    if (this.state.organizationId && this.state.organizationId.length > 0) {
      payload.organizationId = this.state.organizationId;
    }

    this.setState({showReports: true, loadingReport: true, reports: []}, () => {
      sessionsService
        .getSessions(payload)
        .then((result) => {
          console.log('Results ', result);
          let obj = {
            reports: result.Items,
            loadingReport: false,
          };
          this.setState(obj);
        })
        .catch((err) => {
          console.log(err);
          let obj = {
            loadingReport: false,
          };
          this.setState(obj);
        });
    })
  }

  page = (page) => {
    page = page || 1;
    let payload = {
      page: page,
      limit: this.state.limit,
      search: this.state.search,
      order: this.state.order ? 'desc' : 'asc',
      orderBy: this.state.orderBy,
    };

    if (this.state.selectedTabId === 'today') {
      payload.date = moment().format('YYYY-MM-DD');
    } else if (this.state.selectedTabId !== 'all') {
      payload.filterBy = this.state.selectedTabId;
    }

    if (
      this.state.dateRange === 'specificDate' &&
      this.state.specificDate &&
      typeof this.state.specificDate === 'object'
    ) {
      payload.date = this.state.specificDate.format('YYYY-MM-DD');
    }
    if (
      this.state.searchBy === 'patientName' &&
      this.state.patientSearchString &&
      this.state.patientSearchString.length > 0
    ) {
      payload.search = this.state.patientSearchString;
      payload.searchBy = this.state.searchBy;
    }
    if (
      this.state.searchBy === 'staffName' &&
      this.state.searchString &&
      this.state.searchString.length > 0
    ) {
      payload.search = this.state.searchString;
      payload.searchBy = this.state.searchBy;
    }
    if (this.state.organizationId && this.state.organizationId.length > 0) {
      payload.organizationId = this.state.organizationId;
    }

    sessionsService
      .getSessions(payload)
      .then((result) => {        
        for (var a in result.Items) {
          var startFrom = (parseInt(result.Page) - 1) * parseInt(result.Limit);

          result.Items[a].sequence = parseInt(a) + startFrom + 1;
          result.Items[a].weblinks = { doctor: '', patient: '', assistant: '' };
        }
        console.log('Results ', result);
        let obj = {
          page: result.Page,
          limit: result.Limit,
          total: result.Total,
          orderBy: payload.orderBy,
          data: result.Items,
          soaps: [],
          prescriptions: [],
          loadingData: false,
        };
        console.log(result.Items);
        obj[this.state.currentSlotId] = false;
        this.setState(obj);
        this.forceUpdate();
        for (var i in result.Items) {
          this.medRecordBySession(result.Items[i].id);
          this.prescriptionBySession(result.Items[i].id);
          this.webLinkBySessionID(result.Items[i].id, i);
        }
      })
      .catch((err) => {
        console.log(err);
        let obj = {
          loadingData: false,
          data: [],
          loading: false,
        };
        obj[this.state.currentSlotId] = false;
        this.setState(obj);
      });
  };

  medRecordBySession = async (sessionId) => {
    try {
      var medR = await sessionsService.getMedRecordBySessionId(sessionId);
      if (medR) {
        if (
          //need to dive inside medical record content 
          //as session without medical record return valid medical record with only notes contained
          medR.medical_records &&
          medR.medical_records.length > 0 &&
          (medR.medical_records[0].complaint ||
            medR.medical_records[0].diagnosis ||
            medR.medical_records[0].plan ||
            medR.medical_records[0].objective)
        ) {
          this.setState((prev) => ({
            soaps: [...prev.soaps, sessionId],
          }));
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  prescriptionBySession = async (sessionId) => {
    try {
      let _presc = await sessionsService.getPrescriptionBySessionId(sessionId);
      let isTrue = false;
      if (_presc != null && _presc.items != null) {
        isTrue = true;
      }
      if (isTrue) {
        this.setState((prev) => ({
          prescriptions: [...prev.prescriptions, sessionId],
        }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  webLinkBySessionID = async (sessionId, index) => {
    const payload = { Id: sessionId };
    try {
      let _weblink = await weblinkService.listWeblink(payload);
      const weblinkLen = Object.keys(_weblink).length;
      if (weblinkLen==0) {
        Object.assign(payload, { session_id : sessionId, role : 'ALL' });        
        let _createWeblink = await weblinkService.createWeblink(payload);
        if (_createWeblink.message.includes(",")) {
          _weblink = await weblinkService.listWeblink(payload);
        }
      } else console.log(`_weblink==null`);
      
      _weblink.items.forEach(element => {
        console.log(element.id);
        if (element.role=='DOCTOR') {
          this.state.data[index].weblinks.doctor = element.id;
        }
        if (element.role=='PATIENT') {
          this.state.data[index].weblinks.patient = element.id;
        }
        if (element.role=='ASSISTANT') {
          this.state.data[index].weblinks.assistant = element.id;
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };

  handleDateChange = (date) => {
    // in moment object format
    this.setState({ specificDate: date }, () => {
      this.page(1);
    });
  };

  handleChange = (e) => {
    let value = e.target.value || null;
    let obj = {};
    obj[e.target.name] = value;
    obj['failedAttempt'] = false;
    let validationFields = { ...this.state.validationFields };
    if (validationFields[e.target.name]) {
      validationFields[e.target.name].isInvalid = false;
      obj.validationFields = validationFields;
    }
    this.setState(obj);
  };

  onOrganizationChange = (e) => {
    let value = e.target.value || null;
    this.setState({ organizationId: value }, () => {
      this.page(1);
    });
  };

  onDateRangeChange = (e) => {
    let value = e.target.value || null;
    this.setState({ dateRange: value, specificDate: moment() }, () => {
      this.page(1);
    });
  };

  onSearchByChange = (e) => {
    let value = e.target.value || null;
    this.setState({ searchBy: value });
  };

  searchStringChange = debounce((value) => {
    this.setState({ searchString: value }, () => {
      this.page(1);
    });
  }, 1000);

  onSearchStringChange = (e) => {
    let value = e.target.value || null;
    if (!value || (value && value.length < 1)) {
      this.setState({ searchString: '' });
    }
    this.setState({ searchString: value });
    this.searchStringChange(value);
  };

  patientSearchStringChange = debounce((value) => {
    this.setState({ patientSearchString: value }, () => {
      this.page(1);
    });
  }, 1000);

  onPatientSearchStringChange = (e) => {
    let value = e.target.value || null;
    if (!value || (value && value.length < 1)) {
      this.setState({ patientSearchString: '' });
    }
    this.setState({ patientSearchString: value });
    this.patientSearchStringChange(value);
  };

  checkPayment = (id) => {
    if (id && id.length !== 36) {
      swal({
        title: '',
        icon: 'error',
        type: 'error',
        text: 'Mohon maaf, id invalid',
      });
    }
    paymentService
      .checkPayment(id)
      .then((result) => {
        let text = '';
        console.log(result);
        if (result.status === 'paid') {
          text = 'Pembayaran telah lunas';
        } else {
          text = 'Pembayaran belum lunas';
        }
        swal({
          title: 'Cek pembayaran telah berhasil',
          icon: 'success',
          type: 'success',
          text: text,
        }).then((value) => {
          this.page(this.state.page);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  renderFilterComponent = () => {
    return (
      <div>
        <div
          style={{ display: 'inline-block', marginLeft: 15, marginRight: 15 }}
        >
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiSelect
                placeholder="Organisasi"
                options={this.state.organizations}
                value={this.state.organizationId}
                name="organizationName"
                onChange={this.onOrganizationChange}
                aria-label="Organisasi"
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
        {this.state.selectedTabId !== 'today' && (
          <div style={{ display: 'inline-block' }}>
            <EuiFlexGroup>
              <EuiFlexItem grow={false} style={{ marginRight: '-10px' }}>
                <EuiSelect
                  style={{ display: 'inline-block !important' }}
                  placeholder="Cari berdasarkan"
                  options={this.state.dateRangeOptions}
                  value={this.state.dateRange}
                  name="dateRange"
                  onChange={this.onDateRangeChange}
                  aria-label="Cari berdasarkan"
                />
              </EuiFlexItem>
              {this.state.dateRange === 'specificDate' && (
                <EuiFlexItem grow={false}>
                  <EuiDatePicker
                    selected={this.state.specificDate}
                    onChange={this.handleDateChange}
                  />
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </div>
        )}
        <div style={{ display: 'inline-block', marginLeft: 30 }}>
          <EuiFlexGroup>
            <EuiFlexItem grow={false} style={{ marginRight: '-10px' }}>
              <EuiSelect
                style={{ display: 'inline-block !important' }}
                placeholder="Cari berdasarkan"
                options={this.state.searchOptions}
                value={this.state.searchBy}
                name="searchBy"
                onChange={this.onSearchByChange}
                aria-label="Cari berdasarkan"
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              {this.state.searchBy === 'staffName' && (
                <EuiFieldText
                  style={{ display: 'inline-block !important' }}
                  placeholder="Cari dokter..."
                  name="searchString"
                  onChange={this.onSearchStringChange}
                  value={this.state.searchString}
                />
              )}
              {this.state.searchBy === 'patientName' && (
                <EuiFieldText
                  style={{ display: 'inline-block !important' }}
                  placeholder="Cari..."
                  name="searchString"
                  onChange={this.onPatientSearchStringChange}
                  value={this.state.patientSearchString}
                />
              )}
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton
                color='primary'
                onClick={() => {
                  this.loadReport();
                }}
                iconType='lensApp'>
                Laporan Teks
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
      </div>
    );
  };

  onSelectedTabChanged = (id) => {
    this.setState({ loadingData: true, selectedTabId: id, searchDate: '' }, () => {
      this.page(1);
      this.loadOrganizations();
    });
  };

  onTableChange = ({ page = {}, sort = {} }) => {
    const { field: sortField, direction: sortDirection } = sort;
    console.log(sortDirection);
    this.setState({ order: !this.state.order, orderBy: sortField }, () => {
      this.page(this.state.page);
    });
  };

  renderTabs = () => {
    return this.state.tabs.map((tab, index) => (
      <EuiTab
        {...(tab.href && { href: tab.href, target: '_blank' })}
        onClick={() => this.onSelectedTabChanged(tab.id)}
        isSelected={tab.id === this.state.selectedTabId}
        disabled={tab.disabled}
        key={index}
      >
        {tab.name}
      </EuiTab>
    ));
  };

  render() {
    const sorting = {
      sort: {
        field: this.state.orderBy,
        direction: this.state.order,
      },
      enableAllColumns: true,
    };

    let modalDescription;
    let modalReports;

    if (this.state.showDescription) {
      modalDescription = (
        <EuiOverlayMask>
          <EuiModal
            onClose={() => this.setState({ showDescription: false })}
            initialFocus='[name=description]'
          >
            <EuiModalHeader>
              <EuiModalHeaderTitle>
                Deskripsi Temu-janji Konsultasi
              </EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
              <EuiPanel>
                <EuiText>
                  <p>
                    <b>{this.state.sessionItem?.patientName}</b>
                    <br />
                    <small>{this.state.sessionItem?.patientExternalId || '-'}</small>
                    <br />
                    <br />
                    <small>
                      <b>{this.state.sessionItem?.staffName}</b>
                    </small>
                    <br />
                    <small>{this.state.sessionItem?.clinicName}</small>
                    <br />
                    <small>
                      {`${moment(this.state.sessionItem?.scheduleDate).format('dddd')}`},{' '}
                      {`${moment(this.state.sessionItem?.scheduleDate).format('LL')}`} pukul{' '}
                      {this.state.sessionItem?.scheduleTime} <br />
                    </small>
                    <br />
                    <small>
                      Rescheduled:{' '}
                      <b>
                        {this.isRescheduled(this.state.sessionItem?.oldSchedule)
                          ? 'Ya'
                          : 'Tidak'}
                      </b>
                    </small>
                    <br />
                    {this.isRescheduled(
                      this.state.sessionItem?.oldSchedule
                    ) && (
                      <small>
                        Jadwal lama:{' '}
                          {moment(this.state.sessionItem?.oldSchedule).format('dddd, LL [pukul] HH:mm:ss')}
                      </small>
                    )}
                  </p>
                </EuiText>
              </EuiPanel>
              <EuiFormRow label='Deskripsi'>
               <div style={{position: 'relative'}}>
                  {!this.state.editDescription && (
                    <EuiButtonIcon
                      style={{ position: 'absolute', top: 0, right: 0 }}
                      onClick={() => this.setState({ editDescription: true })}
                      iconType='indexEdit'
                      aria-label='option'
                      color='secondary'
                      size='m'
                    />
                  )}
                  <EuiTextArea
                    placeholder={this.state.editDescription ? 'Masukan deskripsi...' : ''}
                    aria-label='description'
                    value={this.state.description}
                    resize={this.state.editDescription ? 'both' : 'none'}
                    onChange={(e) =>
                      this.setState({ description: e.target.value })
                    }
                    readOnly={!this.state.editDescription}
                  />
                </div>
              </EuiFormRow>
            </EuiModalBody>
            {this.state.editDescription && (
              <EuiModalFooter>
                <EuiButton
                  isLoading={this.state.loadingDescription}
                  onClick={() => {
                    let payload = {};
                    payload.Status = this.state.sessionItem.status;
                    payload.Description = this.state.description;
                    payload.Id = this.state.sessionItem.id;

                    this.setState({ loadingDescription: true }, () => {
                      sessionsService
                        .updateSessionDescription(payload)
                        .then(() => {
                          swal({
                            title: 'Berhasil Sunting Deskripsi Temu-Janji Konsultasi',
                            icon: 'success',
                            type: 'success',
                          }).then((value) => {
                            this.setState({
                              loadingDescription: false,
                              editDescription: false,
                              showDescription: false,
                              loadingData: true
                            }, () => this.page(this.state.page));
                          });
                        })
                        .catch((err) => {
                          console.log(err);
                          this.setState({ loadingDescription: false });
                        });
                    });
                  }}
                  fill
                  disabled={!this.state.description}
                >
                  Submit
                </EuiButton>
                <EuiButtonEmpty
                  onClick={() => this.setState({ editDescription: false, description: this.state.prevDescription })}
                >
                  Batal
                </EuiButtonEmpty>
              </EuiModalFooter>
            )}
          </EuiModal>
        </EuiOverlayMask>
      );
    }
    if (this.state.showReports) {
      modalReports = (
        <EuiOverlayMask>
          <EuiModal
            onClose={() => this.setState({ showReports: false })}
            initialFocus='[name=reports]'
          >
            <EuiModalHeader>
              <EuiModalHeaderTitle>
                Laporan Temu-janji Konsultasi
              </EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
              {this.state.loadingReport ? (
               <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                >
               <EuiLoadingSpinner size='xl' />
             </div>
              ) : (
                <EuiPanel>
                  {(this.state.reports || []).length < 1 ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      Tidak ada data
                    </div>
                  ) : (
                    <div>
                      {this.state.reports.map((v, i) => {
                        let scheduleDate = moment(v.scheduleDate, 'YYYY-MM-DD');

                        return (
                          <div>
                            <EuiText key={i}>
                              {i + 1}. {scheduleDate.format('dddd')}{' '}
                              {v.scheduleDate} {v.scheduleTime} {v.clinicName} -{' '}
                              {v.staffName} / {v.patientName} /{' '}
                              {v.patientExternalId || '-'} /{' '}
                              {v.patientPhoneNumber || '-'} /{' '}
                              {v.description || '-'}
                            </EuiText>
                            {i !== this.state.reports.length - 1 && (
                              <EuiHorizontalRule />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </EuiPanel>
              )}
            </EuiModalBody>
            <EuiModalFooter>
              {(this.state.reports || []).length > 0 && (
               <EuiButton
                 fill
                 onClick={() => {
                   var data = '';
                   this.state.reports.map((v, i) => {
                    let scheduleDate = moment(v.scheduleDate, 'YYYY-MM-DD');
                    data += `${i + 1}. ${scheduleDate.format('dddd')} ${
                      v.scheduleDate
                    } ${v.scheduleTime} ${v.clinicName} - ${v.staffName} / ${
                      v.patientName
                    } / ${v.patientExternalId || '-'} / ${
                      v.patientPhoneNumber || '-'
                    } / ${v.description || '-'}\n\n`;
                   });
                   var link = document.createElement('a');
                   var mimeType = 'text/plain';

                   link.setAttribute('download', 'laporan_temu_janji.txt');
                   link.setAttribute(
                     'href',
                     'data:' +
                       mimeType +
                       ';charset=utf-8,' +
                       encodeURIComponent(data)
                   );
                   link.click();
                 }}
               >
                 Download txt
               </EuiButton>
              )}
              <EuiButtonEmpty
                onClick={() => this.setState({ showReports: false })}
              >
                OK
              </EuiButtonEmpty>
            </EuiModalFooter>
          </EuiModal>
        </EuiOverlayMask>
      );
    }

    return (
      <>
        <EuiPage className="class">
          {modalDescription}
          {modalReports}
          <EuiPageBody
            style={{ flexDirection: 'row' }}
            className={'content-container'}
          >
            <EuiPageContent>
              <EuiPageContentHeader>
                <EuiPageContentHeaderSection>
                  <EuiTitle>
                    <h2>Temu-Janji Konsultasi</h2>
                  </EuiTitle>
                </EuiPageContentHeaderSection>
              </EuiPageContentHeader>
              <EuiPageContentHeader>
                <EuiTabs>{this.renderTabs()}</EuiTabs>
              </EuiPageContentHeader>
              <EuiPageContentHeader>
                {this.renderFilterComponent()}
              </EuiPageContentHeader>
              <EuiPageContentBody>
                {!this.state.sessionSlotLoading &&
                  this.state.sessionSlotData &&
                  this.state.sessionSlotData.length < 0 && (
                    <p>Belum ada data</p>
                  )}
                {this.state.sessionSlotLoading && (
                  <p>
                    <EuiLoadingSpinner size="xl" />
                  </p>
                )}
                <EuiFlexItem>
                  <EuiPage className="class">
                    <TablePage
                      placeholder={'Cari'}
                      data={this.state.data}
                      column={this.state.column}
                      page={this.state.page}
                      limit={this.state.limit}
                      total={this.state.total}
                      prev={this.prevPage}
                      next={this.nextPage}
                      toPage={this.page}
                      onItemClick={this.onItemClick}
                      sort={sorting}
                      change={this.onTableChange}
                      loading={this.state.loadingData}
                      customEmpty={
                        <EuiPage>
                          <EuiFlexGroup direction='column'>
                            <EuiFlexItem>
                              <p>
                                <EuiIcon type='clock' size='xxl' />
                              </p>
                            </EuiFlexItem>
                            <EuiFlexItem>
                              <p>Tidak ada jadwal hari ini</p>
                            </EuiFlexItem>
                          </EuiFlexGroup>
                        </EuiPage>
                      }
                    />
                  </EuiPage>
                </EuiFlexItem>
              </EuiPageContentBody>
            </EuiPageContent>
          </EuiPageBody>
        </EuiPage>
      </>
    );
  }
}

export default Consultations;
