import React, { Component } from 'react';
import { debounce } from 'lodash';
import { EuiPage, EuiPageBody, EuiFlexItem, EuiFieldText, EuiButtonEmpty, EuiLoadingSpinner, EuiButton } from '@elastic/eui';
import TablePage from "../components/TablePage";
import PharmaciesService from '../services/Pharmacies';

const pharmaciesService = new PharmaciesService();

class Pharmacies extends Component {

  state = {
    page: 1,
    limit: 10,
    order: "ASC",
    search: "",
    loading: false,
    orderBy: "name",
    column: [
      {
        field: "code",
        name: "Kode "
      },
      {
        field: "groupName",
        name: "Group"
      },
      {
        field: "name",
        name: "Nama"
      },
      {
        field: "location",
        name: "Lokasi"
      },
      {
        field: "status",
        name: "status"
      }
    ],
    data: []

  }

  componentDidMount = () => {
    this.page(1);
  }

  page = (page) => {
    page = page || 1;
    let payload = {
      page: page,
      limit: this.state.limit,
      search: this.state.search,
      order: this.state.order,
      orderBy: this.state.orderBy
    };
    
    this.setState({
      loading: true
    });

    pharmaciesService.getPharmacies(payload)
      .then(res => {
        console.log(res);
        this.setState({
          page: res.page,
          limit: res.limit,
          total: res.total,
          order: res.order,
          orderBy: res.orderBy,
          data: res.items,
          total: res.total,
          loading: false
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loading: false
        });
      })
  }

  handleOnSearch = value => {
    this.setState({ search: value, loading: true });
    this.handleSearchDebounce();
  };

  handleSearchDebounce = debounce(() => {
    this.setState({ search: this.state.search })
    this.page(this.state.page)
  }, 2000);

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };

  add = () => {
    this.props.history.push("/pharmacy/detail/new");
  }

  onItemClick = item => {
    this.props.history.push("/pharmacy/detail/" + item.id);
  }

  render = () => {
    return (
      <>
        <EuiPage className="class">
          <EuiPageBody
            style={{ flexDirection: 'row', textAlign: 'center', width: '100%' }}
            className={'content-container'}>

            <TablePage
              title={"Daftar Apotek"}
              placeholder={"Cari"}
              searchComp={
                 <EuiFlexItem>
                  <EuiFieldText
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        this.handleOnSearch(this.state.search);
                      }
                    }}
                    value={this.state.search}
                    onChange={e => this.handleOnSearch(e.target.value)}
                    placeholder={this.props.placeholder}
                    append={
                      <EuiButtonEmpty
                        onClick={() => this.handleOnSearch(this.state.search)}
                      >
                        Cari
                      </EuiButtonEmpty>
                    }
                  />
                </EuiFlexItem>
              }
              buttonLabel={"Tambah"}
              buttonFunc={() => this.add()}
              data={this.state.data}
              column={this.state.column}
              disablePagination={this.state.loading}
              loading={this.state.loading}
              page={this.state.page}
              limit={this.state.limit}
              total={this.state.total}
              prev={this.prevPage}
              next={this.nextPage}
              toPage={this.page}
              onItemClick={this.onItemClick}
            />

          </EuiPageBody>
        </EuiPage>
      </>
    )
  }
}

export default Pharmacies;