import React, { Component, useEffect } from "react";

import {
  EuiButtonEmpty,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiText,
  EuiPanel,
  EuiDatePicker,
  EuiFlexItem,
  EuiFlexGroup,
  EuiFieldText,
  EuiSelect,
  EuiRadioGroup,
  EuiTextArea,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiBasicTable,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiPageContentBody,
  EuiTitle,
  EuiLoadingSpinner,
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiButton,
  EuiIcon,
  EuiBadge,
  EuiTab,
} from "@elastic/eui";
import TablePage from "../components/TablePage";
import SessionService from "../services/Sessions";
import Staff from "../services/Staffs";
import SessionSlotService from "../services/SessionSlots";
import ErrorService from "../services/Error.js";
import OrganizationService from "../services/Organizations";
import ClinicService from "../services/Clinics";
import Payments from "../services/Payments";
import ActionPopover from "../components/ActionPopover";
import ToExcels from "../services/ToExcels";
import Blob from "../services/Blob";
import downloadjs from "downloadjs";

import moment from "moment";
import debounce from "debounce";
import swal from "sweetalert";
import Utils from "../Utils";

const clinicService = new ClinicService();
const errorService = new ErrorService();
const sessionsService = new SessionService();
const sessionSlotService = new SessionSlotService();
const staffService = new Staff();
const organizationService = new OrganizationService();
const paymentService = new Payments();
const toExcel = new ToExcels();
const blob = new Blob();

const ChangeSlotModal = (props) => {
  const { slot, onScheduleChange } = props;
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [date, setDate] = React.useState(null);
  const [startTime, setStartTime] = React.useState(slot.startTime);
  const [endTime, setEndTime] = React.useState(slot.endTime);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [startTimeRangeOptions, setstartTimeRangeOptions] = React.useState(
    Utils.generateTimeRangeOptions(slot?.duration / 60000, "00:00:00")
  );
  const [endTimeRangeOptions, setEndTimeRangeOptions] = React.useState(
    Utils.generateTimeRangeOptions(slot?.duration / 60000, "00:00:00")
  );

  const formatter = new Intl.DateTimeFormat("id-ID", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
  const handleSubmit = () => {
    let payload = { ...slot };
    if (date) {
      payload.specificDate = date.toISOString().split("T")[0];
    }
    payload.startTime = startTime;
    payload.endTime = endTime;
    setLoading(true);
    sessionSlotService
      .update(payload)
      .then(() => {
        setLoading(false);
        setSuccess(true);
        onScheduleChange(payload);
      })
      .catch((err) => {
        setLoading(false);
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message === 'already-have-sessions'
        ) {
          swal({
            icon: 'error',
            title: 'Terdapat sesi terdaftar.',
            text: 'Mohon maaf, slot jadwal tidak dapat diubah karena sudah ada sesi pasien yang terdaftar.',
            confirmButtonText: 'OK',
          });
        } else {
          errorService.handle(err);
        }
      });
  };
  const closeModal = (resetSuccessState) => {
    if (resetSuccessState) {
      setSuccess(false);
    }
    setIsModalVisible(false);
  };
  const showModal = () => setIsModalVisible(true);

  let oldSlotPanel = (
    <EuiPanel>
      <EuiTitle>
        <h4>{success ? "Jadwal Sebelumnya" : "Jadwal Saat Ini"}</h4>
      </EuiTitle>
      <EuiSpacer />
      <EuiText>
        <p>
          <small>Nama unit dan klinik</small>
          <br />
          RSCM - {slot?.organizationName}
          <br />
          {slot?.clinicName}
          <br />
          {`${formatter.format(new Date())} ${slot?.startTime} - ${
            slot?.endTime
          }`}
          <br />
          {slot?.activeSessions || "Tidak ada"} pasien terdaftar
        </p>
      </EuiText>
    </EuiPanel>
  );
  let newSlotPanel = (
    <EuiPanel>
      <EuiTitle>
        <h4>Jadwal Baru</h4>
      </EuiTitle>
      <EuiSpacer />
      <EuiText>
        <p>
          <small>Nama unit dan klinik</small>
          <br />
          RSCM - {slot?.organizationName}
          <br />
          {slot?.clinicName}
          <br />
          {`${formatter.format(
            date ? date.toDate() : new Date()
          )} ${startTime} - ${endTime}`}
          <br />
          {slot?.activeSessions || "Tidak ada"} pasien terdaftar
        </p>
      </EuiText>
    </EuiPanel>
  );

  const onStartTimeChange = (e) => {
    let value = e.target.value || null;
    if (!value || (value && value.length < 1)) {
      return;
    }
    console.log(value);
    setStartTime(value);
    setEndTime(null);
    setEndTimeRangeOptions(
      Utils.generateTimeRangeOptions(slot?.duration / 60000, value)
    );
  };
  const onEndTimeChange = (e) => {
    let value = e.target.value || null;
    if (!value || (value && value.length < 1)) {
      return;
    }
    console.log(value);
    setEndTime(value);
  };

  let form = (
    <EuiForm>
      <EuiTitle>
        <h5>Jadwal Baru</h5>
      </EuiTitle>
      <EuiFormRow label="Tanggal">
        <EuiFlexItem grow={false}>
          <EuiDatePicker
            minDate={moment()}
            onChange={(e) => setDate(e)}
            selected={date}
          />
        </EuiFlexItem>
      </EuiFormRow>
      <EuiFormRow label="Awal">
        <EuiSelect
          placeholder="Awal"
          options={startTimeRangeOptions}
          value={startTime}
          name="startTime"
          onChange={onStartTimeChange}
          aria-label="Awal"
        />
      </EuiFormRow>
      <EuiFormRow label="Akhir">
        <EuiSelect
          placeholder="Akhir"
          options={endTimeRangeOptions}
          value={endTime}
          name="endTime"
          onChange={onEndTimeChange}
          aria-label="Akhir"
        />
      </EuiFormRow>
    </EuiForm>
  );

  let modal;
  if (isModalVisible) {
    modal = (
      <EuiOverlayMask>
        <EuiModal onClose={closeModal} initialFocus="[name=popswitch]">
          <EuiModalHeader>
            <EuiModalHeaderTitle>Lihat / Ubah Jadwal</EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            {oldSlotPanel}
            <EuiSpacer />
            {loading ? (
              <p>
                <EuiLoadingSpinner size="xl" />
              </p>
            ) : success ? (
              newSlotPanel
            ) : (
              form
            )}
          </EuiModalBody>

          {loading ? (
            ""
          ) : !success ? (
            <EuiModalFooter>
              <EuiButton onClick={handleSubmit} fill>
                Ubah
              </EuiButton>
              <EuiButtonEmpty onClick={closeModal}>Batal</EuiButtonEmpty>
            </EuiModalFooter>
          ) : (
            <EuiModalFooter>
              <EuiButtonEmpty onClick={() => closeModal(true)}>
                Kembali
              </EuiButtonEmpty>
            </EuiModalFooter>
          )}
        </EuiModal>
      </EuiOverlayMask>
    );
  }
  return (
    <div id={"change-session-btn"}>
      <EuiButton onClick={showModal}>Ubah</EuiButton>
      {modal}
    </div>
  );
};

export const ChangeCloseModal = (props) => {
  const { sessionItem, onScheduleChange } = props;
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [availableClinicOrganizations, setAvailableClinicOrganizations] =
    React.useState([]);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [radioIdSelected, setRadioIdSelected] = React.useState(4); // default 4 (ended)
  const [reason, setReason] = React.useState(``);
  console.log(onScheduleChange, availableClinicOrganizations);

  useEffect(() => {
    loadOrganizations();
  }, []);

  const handleSubmit = () => {
    let payload = {};
    payload.Status = radioIdSelected;
    payload.Description = reason;
    payload.Id = sessionItem.id;

    setLoading(true);
    sessionsService
      .updateSessionStatus(payload)
      .then(() => {
        setLoading(false);
        setSuccess(true);
        closeModal();
        onScheduleChange();
      })
      .catch((err) => {
        setLoading(false);
        errorService.handle(err);
      });
  };

  const closeModal = (resetSuccessState) => {
    if (resetSuccessState) {
      setSuccess(false);
    }
    setIsModalVisible(false);
    setRadioIdSelected(4);
    setReason('');
  };

  const showModal = () => setIsModalVisible(true);
  const loadOrganizations = () => {
    let payload = {
      limit: 1000,
    };
    organizationService
      .getOrganizations(payload)
      .then((result) => {
        let options = [{ value: "", text: "Pilih organisasi" }];
        for (let i in result.items) {
          options.push({
            value: result.items[i].id,
            text: result.items[i].name,
          });
        }
        setAvailableClinicOrganizations(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let oldSlotPanel = (
    <EuiPanel>
      <EuiText>
        <p>
          <b>{sessionItem?.patientName}</b>
          <br />
          <small>{sessionItem?.patientExternalId}</small>
          <br />
          <br />
          Jadwal Saat Ini
          <br />
          <small>
            <b>{sessionItem?.staffName}</b>
          </small>
          <br />
          <small>{sessionItem?.clinicName}</small>
          <br />
          <small>
            {`${moment(sessionItem?.scheduleDate).format("dddd")}`},{" "}
            {`${moment(sessionItem?.scheduleDate).format("LL")}`} pukul{" "}
            {sessionItem?.scheduleTime} <br />
          </small>
          <br />
        </p>
      </EuiText>
    </EuiPanel>
  );

  const onChangeCloseReason = (optionId) => {
    setRadioIdSelected(optionId);
  };

  let form = (
    <EuiPanel>
      <EuiForm>
        <p>di-close karena</p>
        <br />
        <EuiRadioGroup
          options={[
            {
              id: 4,
              label: "Ended",
            },
            {
              id: 3,
              label: "Disabled",
            },
            {
              id: 7,
              label: "Refund",
            },
          ]}
          idSelected={radioIdSelected}
          onChange={onChangeCloseReason}
          name="close option"
        />
        <EuiFormRow label="Alasan*">
          <div>
            <EuiTextArea
              placeholder="Masukan alasan..."
              aria-label="description"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
        </EuiFormRow>
      </EuiForm>
    </EuiPanel>
  );

  let modal;
  if (isModalVisible) {
    modal = (
      <EuiOverlayMask>
        <EuiModal onClose={closeModal} initialFocus="[name=popswitch]">
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              Close Temu-janji Konsultasi
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            {oldSlotPanel}
            <EuiSpacer />
            <br />
            <br />
            {loading ? (
              <p>
                <EuiLoadingSpinner size="xl" />
              </p>
            ) : (
              form
            )}
          </EuiModalBody>

          {loading ? (
            ""
          ) : !success ? (
            <EuiModalFooter>
              <EuiButton onClick={handleSubmit} fill disabled={!reason}>
                Submit
              </EuiButton>
              <EuiButtonEmpty onClick={closeModal}>Batal</EuiButtonEmpty>
            </EuiModalFooter>
          ) : (
            <EuiModalFooter>
              <EuiButtonEmpty onClick={() => closeModal(true)}>
                Batal
              </EuiButtonEmpty>
            </EuiModalFooter>
          )}
        </EuiModal>
      </EuiOverlayMask>
    );
  }
  return (
    <>
      <EuiButton onClick={showModal}>Close</EuiButton>
      {modal}
    </>
  );
};

export const ChangeScheduleModal = (props) => {
  const { sessionItem, onScheduleChange } = props;
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [availableClinicOrganizations, setAvailableClinicOrganizations] =
    React.useState([]);
  const [availableClinics, setAvailableClinics] = React.useState([]);
  const [availableDoctors, setAvailableDoctors] = React.useState([]);
  const [availableSessionSlots, setAvailableSessionSlots] = React.useState([]);
  const [clinicOrganizationId, setClinicOrganizationId] = React.useState("");
  const [pickedClinicId, setPickedClinicId] = React.useState("");
  const [pickedDoctorId, setPickedDoctorId] = React.useState("");
  const [pickedSessionSlotId, setPickedSessionSlotId] = React.useState("");
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  useEffect(() => {
    loadOrganizations();
  }, []);

  const handleSubmit = () => {
    let payload = {};
    console.log(payload);

    payload.to_slot = pickedSessionSlotId;
    payload.from_session = sessionItem.id;
    setLoading(true);
    sessionsService
      .moveSessionToAnotherSlot(pickedSessionSlotId, payload)
      .then(() => {
        setLoading(false);
        setSuccess(true);
        closeModal();
        onScheduleChange(payload);
      })
      .catch((err) => {
        setLoading(false);
        errorService.handle(err);
      });
  };

  const closeModal = (resetSuccessState) => {
    if (resetSuccessState) {
      setSuccess(false);
    }
    setIsModalVisible(false);
  };

  const showModal = () => setIsModalVisible(true);
  const loadOrganizations = () => {
    let payload = {
      limit: 1000,
    };
    organizationService
      .getOrganizations(payload)
      .then((result) => {
        let options = [{ value: "", text: "Pilih organisasi" }];
        for (let i in result.items) {
          options.push({
            value: result.items[i].id,
            text: result.items[i].name,
          });
        }
        setAvailableClinicOrganizations(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadClinics = (organizationId) => {
    let payload = {
      limit: 1000,
      organizationId: organizationId,
    };
    clinicService
      .getClinics(payload)
      .then((result) => {
        let options = [];
        for (let i in result.items) {
          let exist = false;
          for (let j in availableClinics) {
            if (result.items[i].id === availableClinics[j].id) {
              exist = true;
              break;
            }
          }
          if (exist) {
            continue;
          }
          options.push({
            value: result.items[i].id,
            text: result.items[i].name,
          });
        }
        if (options && options.length < 1) {
          options.splice(0, 0, {
            value: "",
            text: "Tidak ada klinik tersedia",
          });
        } else {
          options.splice(0, 0, {
            value: "",
            text: "Pilih klinik..",
          });
        }
        setAvailableClinics(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadDoctors = (clinicId) => {
    let payload = {
      limit: 1000,
      clinicId: clinicId,
    };
    staffService
      .getStaffs(payload)
      .then((result) => {
        let options = [];
        for (let i in result.items) {
          let exist = false;
          for (let j in availableDoctors) {
            if (result.items[i].id === availableDoctors[j].id) {
              exist = true;
              break;
            }
          }
          if (exist) {
            continue;
          }
          options.push({
            value: result.items[i].id,
            text: result.items[i].name,
          });
        }
        if (options && options.length < 1) {
          options.splice(0, 0, {
            value: "",
            text: "Tidak ada dokter tersedia",
          });
        } else {
          options.splice(0, 0, {
            value: "",
            text: "Pilih dokter..",
          });
        }
        setAvailableDoctors(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadSessionSlot = (staffId) => {
    let payload = {
      limit: 1000,
      staffId: staffId,
      clinicId: pickedClinicId,
      searchBy: "",
    };
    sessionSlotService
      .getSessionSlots(payload)
      .then((result) => {
        console.log(result);
        let options = [];
        for (let i in result.items) {
          let exist = false;
          for (let j in availableSessionSlots) {
            if (result.items[i].id === availableSessionSlots[j].id) {
              exist = true;
              break;
            }
          }
          if (exist) {
            continue;
          }
          options.push({
            value: result.items[i].id,
            text: `${result.items[i].specificDate}, ${result.items[i].startTime}-${result.items[i].endTime} `,
          });
        }
        if (options && options.length < 1) {
          options.splice(0, 0, {
            value: "",
            text: "Tidak ada jadwal tersedia",
          });
        } else {
          options.splice(0, 0, {
            value: "",
            text: "Pilih jadwal..",
          });
        }
        setAvailableSessionSlots(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClinicOrganizationChange = (e) => {
    let value = e.target.value || null;
    setClinicOrganizationId(value);
    loadClinics(value);
  };

  const onPickedClinic = (e) => {
    let value = e.target.value || null;
    if (!value || (value && value.length < 1)) {
      return;
    }

    setPickedClinicId(value);
    loadDoctors(value);
  };

  const onPickedDoctor = (e) => {
    let value = e.target.value || null;
    if (!value || (value && value.length < 1)) {
      return;
    }

    setPickedDoctorId(value);
    loadSessionSlot(value);
  };

  const onPickedSessionSlot = (e) => {
    let value = e.target.value || null;
    if (!value || (value && value.length < 1)) {
      return;
    }

    setPickedSessionSlotId(value);
  };

  let oldSlotPanel = (
    <EuiPanel>
      <EuiText>
        <p>
          <b>{sessionItem?.patientName}</b>
          <br />
          <small>{sessionItem?.patientExternalId}</small>
          <br />
          <br />
          Jadwal Saat Ini
          <br />
          <small>
            <b>{sessionItem?.staffName}</b>
          </small>
          <br />
          <small>{sessionItem?.clinicName}</small>
          <br />
          <small>
            {`${moment(sessionItem?.scheduleDate).format("dddd")}`},{" "}
            {`${moment(sessionItem?.scheduleDate).format("LL")}`} pukul{" "}
            {sessionItem?.scheduleTime} <br />
          </small>
          <br />
        </p>
      </EuiText>
    </EuiPanel>
  );

  let form = (
    <EuiPanel>
      <EuiForm>
        <p>Jadwal Baru</p>
        <br />
        <EuiFormRow label="Nama Unit">
          <EuiSelect
            placeholder="Unit"
            options={availableClinicOrganizations}
            value={clinicOrganizationId}
            name="clinicOrganizationId"
            onChange={(e) => {
              onClinicOrganizationChange(e);
            }}
            aria-label="Organisasi"
          />
        </EuiFormRow>
        {clinicOrganizationId && clinicOrganizationId.length > 0 && (
          <EuiFormRow label="Nama Klinik">
            <div>
              <EuiSelect
                placeholder="Klinik"
                options={availableClinics}
                value={pickedClinicId}
                name="pickedClinicName"
                onChange={(e) => {
                  onPickedClinic(e);
                }}
                aria-label="Klinik"
              />
            </div>
          </EuiFormRow>
        )}
        {pickedClinicId && pickedClinicId.length > 0 && (
          <EuiFormRow label="Nama Dokter">
            <div>
              <EuiSelect
                placeholder="Dokter"
                options={availableDoctors}
                value={pickedDoctorId}
                name="pickedDoctorName"
                onChange={(e) => {
                  onPickedDoctor(e);
                }}
                aria-label="Dokter"
              />
            </div>
          </EuiFormRow>
        )}
        {pickedDoctorId && pickedDoctorId.length > 0 && (
          <EuiFormRow label="Pilih Jadwal">
            <div>
              <EuiSelect
                placeholder="Jadwal"
                options={availableSessionSlots}
                value={pickedSessionSlotId}
                name="pickedSessionSlotId"
                onChange={(e) => {
                  onPickedSessionSlot(e);
                }}
                aria-label="Jadwal"
              />
            </div>
          </EuiFormRow>
        )}
      </EuiForm>
    </EuiPanel>
  );

  let modal;
  if (isModalVisible) {
    modal = (
      <EuiOverlayMask>
        <EuiModal onClose={closeModal} initialFocus="[name=popswitch]">
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              Lihat / Ubah Jadwal Pasien
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            {oldSlotPanel}
            <EuiSpacer />
            <br />
            <center> Menjadi </center>
            <br />
            {loading ? (
              <p>
                <EuiLoadingSpinner size="xl" />
              </p>
            ) : (
              form
            )}
          </EuiModalBody>

          {loading ? (
            ""
          ) : !success ? (
            <EuiModalFooter>
              <EuiButton onClick={handleSubmit} fill>
                Ubah
              </EuiButton>
              <EuiButtonEmpty onClick={closeModal}>Batal</EuiButtonEmpty>
            </EuiModalFooter>
          ) : (
            <EuiModalFooter>
              <EuiButtonEmpty onClick={() => closeModal(true)}>
                Kembali
              </EuiButtonEmpty>
            </EuiModalFooter>
          )}
        </EuiModal>
      </EuiOverlayMask>
    );
  }
  return (
    <>
      <EuiButton onClick={showModal}>Reschedule</EuiButton>
      {modal}
    </>
  );
};
class Sessions extends Component {
  state = {
    page: 1,
    limit: 10,
    order: true,
    search: "",
    searchString: "",
    patientSearchString: "",
    searchBy: "staffName",
    orderBy: "name",
    data: [],
    childData: [],
    specificDate: moment(),
    dateRange: "today",
    selectedOrganizationId: "",
    organizationId: "",
    clinicOrganizationId: "",
    sessionId: "",
    showModal: false,
    loading: false,
    availableClinicOrganizations: [],
    selectedTabId: "today",
    openedPopOver: "",
    soaps: [],
    prescriptions: [],
    dateRangeOptions: [
      {
        text: "Hari ini",
        value: "today",
      },
      {
        text: "Tanggal",
        value: "specificDate",
      },
      {
        text: "Semua",
        value: "all",
      },
    ],
    searchOptions: [
      {
        text: "Dokter",
        value: "staffName",
      },
      {
        text: "Pasien",
        value: "patientName",
      },
    ],
    column: [
      {
        field: "specificDate",
        name: "Tanggal Jadwal",
        width: "8%",
      },
      {
        field: "startTime",
        name: "Waktu Telekonsultasi",
        width: "12%",
      },
      {
        field: "clinicName",
        name: "Klinik",
        width: "25%",
      },
      {
        field: "staffName",
        name: "Nama staff/dokter",
        width: "17%",
      },
      {
        field: "appVersion",
        name: "Versi Aplikasi",
        width: "17%",
      },
      {
        field: "appPlatform",
        name: "Perangkat",
        width: "17%",
      },
      {
        field: "id",
        name: "Ubah Jadwal",
        width: "20%",
        render: (id, slot) => (
          <ChangeSlotModal
            slot={slot}
            onScheduleChange={(newSlot) => this.setScheduleChanges(newSlot, id)}
          />
        ),
      },
    ],

    childColumn: [
      {
        field: "slotNumber",
        name: "Urutan",
        width: "8%",
      },

      {
        field: "patientName",
        name: "Nama pasien",
        width: "20%",
      },
      {
        field: "appVersion",
        name: "Versi Aplikasi",
        width: "14%",
      },
      {
        field: "appPlatform",
        name: "Perangkat",
        width: "14%",
      },

      {
        field: "status",
        name: "Status",
        width: "10%",
        render: (status) => (
          <EuiBadge color={this.statusColor(status)}>
            {this.statusRender(status)}
          </EuiBadge>
        ),
      },
      {
        field: "id",
        name: "SOAP",
        width: "8%",
        render: (id) => (
          <EuiBadge color={this.statusColor(this.isSoapComplete(id))}>
            {this.isSoapComplete(id) ? "SUDAH" : "BELUM"}
          </EuiBadge>
        ),
      },
      {
        field: "id",
        name: "Resep Obat",
        width: "10%",
        render: (id) => (
          <EuiBadge color={this.statusColor(this.isPrescription(id))}>
            {this.isPrescription(id) ? "SUDAH" : "BELUM"}
          </EuiBadge>
        ),
      },
      {
        field: "id",
        name: "Aksi",
        width: "20%",
        render: (id, sessionItem) => (
          <ActionPopover
            sessionItem={sessionItem}
            id={id}
            onScheduleChange={() => this.page(1)}
            openReceiptBlob={() => this.openReceiptBlob(id)}
            checkPayment={() => this.checkPayment(id)}
          />
        ),
        sortable: false,
      },
    ],
    toExcelPayload: "",
  };

  setScheduleChanges = (changes, id) => {
    let newSesionSlotData = [...this.state.data];
    newSesionSlotData.map((e) => (e.id === id ? Object.assign(e, changes) : e));
    this.setState(
      {
        ...this.state,
        data: newSesionSlotData,
      },
      () => {
        this.forceUpdate();
      }
    );
  };

  onPopOverClick = (id) => {
    let state = this.state;
    if (!state.popOver[id]) {
      state.popOver[id] = { isOpen: true };
    } else {
      console.log(state.popOver[id]);
      state.popOver[id] = { isOpen: !state.popOver[id].isOpen };
    }
    this.setState(state);
  };

  childPage = (page, slotId) =>
    new Promise((resolve, reject) => {
      page = page || 1;
      let payload = {
        page: page,
        limit: 10,
        search: "",
        order: "asc",
        orderBy: "",
      };
      payload.slotId = slotId;
      if (
        this.state.searchBy === "patientName" &&
        this.state.patientSearchString &&
        this.state.patientSearchString.length > 0
      ) {
        payload.search = this.state.patientSearchString;
        payload.searchBy = this.state.searchBy;
      }
      if (
        this.state.dateRange === "specificDate" &&
        this.state.specificDate &&
        typeof this.state.specificDate === "object"
      ) {
        payload.specificDate = this.state.specificDate.format("YYYY-MM-DD");
      } else if (this.state.dateRange === "today") {
        payload.specificDate = moment().format("YYYY-MM-DD");
      }
      sessionsService
        .getSessions(payload)
        .then((result) => {
          let obj = {
            childData: result.Items ? result.Items : [],
            expandedRowItem: [
              {
                title: "",
                description: this.renderChild(
                  result.Items || [],
                  this.state.childColumn
                ),
              },
            ],
          };
          obj[this.state.currentSlotId] = false;
          this.setState(obj);
          resolve(obj);
        })
        .catch((err) => {
          let obj = {
            childData: [],
          };
          obj[this.state.currentSlotId] = false;
          this.setState(obj);
          reject(obj);
        });
      this.forceUpdate();
    });

  onExpandedRow = (item) => {
    return new Promise((resolve, reject) => {
      this.childPage(1, item.id)
        .then((x) => resolve(x.expandedRowItem))
        .catch((err) => reject(err));
    });
  };

  renderChild = (data, column) => (
    <EuiBasicTable
      noItemsMessage={"Belum ada data."}
      items={data}
      columns={column}
    />
  );
  componentDidMount = () => {
    this.page(1);
    this.loadOrganizations();
  };

  statusRender = (status) => {
    switch (status) {
      case 0:
        return "NEW";
      case 1:
        return "ACTIVE";
      case 2:
        return "REJECTED";
      case 3:
        return "DISABLED";
      case 4:
        return "ENDED";
      case 5:
        return "CANCELED";
      case 7:
        return "REFUND";
      default:
        return "UNKNOWN";
    }
  };

  statusColor = (status) => {
    switch (status) {
      case 0 || false:
        return "warning";
      case 1 || true:
        return "#76ff03";
      default:
        return "danger";
    }
  };

  loadOrganizations = () => {
    let payload = {
      limit: 1000,
    };
    organizationService
      .getOrganizations(payload)
      .then((result) => {
        let options = [{ value: "", text: "Pilih organisasi" }];
        for (let i in result.items) {
          options.push({
            value: result.items[i].id,
            text: result.items[i].name,
          });
        }
        this.setState({
          organizations: options,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          organizations: [],
        });
      });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      logsData: [],
    });
  };

  openReceiptBlob = (id) => {
    this.setState({ loading: true });
    sessionsService
      .getSessionReceipt(id)
      .then((value) => {
        var binaryData = [];
        binaryData.push(value);
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: "application/pdf" })
        );
        link.download = `payment-receipt-${id}`;
        link.click();

        this.setState({
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  };

  isSoapComplete = (sessionId) => {
    if (this.state.soaps.length > 0) {
      return this.state.soaps.includes(sessionId);
    } else {
      return false;
    }
  };

  isPrescription = (sessionId) => {
    if (this.state.prescriptions.includes(`${sessionId}`)) {
      return true;
    }
    return false;
  };

  page = (page) => {
    page = page || 1;
    let payload = {
      page: page,
      limit: this.state.limit,
      search: this.state.search,
      order: this.state.order ? "desc" : "asc",
      orderBy: this.state.orderBy,
    };

    if (
      this.state.dateRange === "specificDate" &&
      this.state.specificDate &&
      typeof this.state.specificDate === "object"
    ) {
      payload.specificDate = this.state.specificDate.format("YYYY-MM-DD");
    } else if (this.state.dateRange === "today") {
      payload.specificDate = moment().format("YYYY-MM-DD");
    }
    if (
      this.state.searchBy === "patientName" &&
      this.state.patientSearchString &&
      this.state.patientSearchString.length > 0
    ) {
      payload.search = this.state.patientSearchString;
      payload.searchBy = this.state.searchBy;
    }
    if (
      this.state.searchBy === "staffName" &&
      this.state.searchString &&
      this.state.searchString.length > 0
    ) {
      payload.search = this.state.searchString;
      payload.searchBy = this.state.searchBy;
    }
    if (this.state.organizationId && this.state.organizationId.length > 0) {
      payload.organizationId = this.state.organizationId;
    }
    this.setState({
      toExcelPayload: payload,
    });

    sessionSlotService
      .getSessionSlots(payload)
      .then((result) => {
        console.log("Results ", result.items);
        let obj = {
          page: result.page,
          limit: result.limit,
          total: result.total,
          orderBy: payload.orderBy,
          data: result.items,
          soaps: [],
          prescriptions: [],
        };
        obj[this.state.currentSlotId] = false;
        this.setState(obj);
        this.forceUpdate();
        for (var i in result.Items) {
          this.medRecordBySession(result.Items[i].id);
          this.prescriptionBySession(result.Items[i].id);
        }
      })
      .catch((err) => {
        console.log(err);
        let obj = {
          data: [],
        };
        obj[this.state.currentSlotId] = false;
        this.setState(obj);
      });
  };

  medRecordBySession = async (sessionId) => {
    try {
      var medR = await sessionsService.getMedRecordBySessionId(sessionId);
      if (medR) {
        if (
          //need to dive inside medical record content 
          //as session without medical record return valid medical record with only notes contained
          medR.medical_records &&
          medR.medical_records.length > 0 &&
          (medR.medical_records[0].complaint ||
            medR.medical_records[0].diagnosis ||
            medR.medical_records[0].plan ||
            medR.medical_records[0].objective)
        ) {
          this.setState((prev) => ({
            soaps: [...prev.soaps, sessionId],
          }));
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  
  prescriptionBySession = async (sessionId) => {
    try {
      let _presc = await sessionsService.getPrescriptionBySessionId(sessionId);
      let isTrue = false;
      if (_presc != null && _presc.items != null) {
        isTrue = true;
      }
      if (isTrue) {
        this.setState((prev) => ({
          prescriptions: [...prev.prescriptions, sessionId],
        }));
      }
    } catch (e) {
      console.log(e);
    }
  };
  
  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };

  handleDateChange = (date) => {
    // in moment object format
    this.setState({ specificDate: date }, () => {
      this.page(1);
    });
  };

  handleChange = (e) => {
    let value = e.target.value || null;
    let obj = {};
    obj[e.target.name] = value;
    obj["failedAttempt"] = false;
    let validationFields = { ...this.state.validationFields };
    if (validationFields[e.target.name]) {
      validationFields[e.target.name].isInvalid = false;
      obj.validationFields = validationFields;
    }
    this.setState(obj);
  };

  onOrganizationChange = (e) => {
    let value = e.target.value || null;
    this.setState({ organizationId: value }, () => {
      this.page(1);
    });
  };

  onDateRangeChange = (e) => {
    let value = e.target.value || null;
    this.setState({ dateRange: value, specificDate: moment() }, () => {
      this.page(1);
    });
  };

  onSearchByChange = (e) => {
    let value = e.target.value || null;
    this.setState({ searchBy: value });
  };

  searchStringChange = debounce((value) => {
    this.setState({ searchString: value }, () => {
      this.page(1);
    });
  }, 1000);

  onSearchStringChange = (e) => {
    let value = e.target.value || null;
    if (!value || (value && value.length < 1)) {
      this.setState({ searchString: "" });
    }
    this.setState({ searchString: value });
    this.searchStringChange(value);
  };

  patientSearchStringChange = debounce((value) => {
    this.setState({ patientSearchString: value }, () => {
      this.page(1);
    });
  }, 1000);

  onPatientSearchStringChange = (e) => {
    let value = e.target.value || null;
    if (!value || (value && value.length < 1)) {
      this.setState({ patientSearchString: "" });
    }
    this.setState({ patientSearchString: value });
    this.patientSearchStringChange(value);
  };

  checkPayment = (id) => {
    if (id && id.length !== 36) {
      swal({
        title: "",
        icon: "error",
        type: "error",
        text: "Mohon maaf, id invalid",
      });
    }
    paymentService
      .checkPayment(id)
      .then((result) => {
        let text = "";
        console.log(result);
        if (result.status === "paid") {
          text = "Pembayaran telah lunas";
        } else {
          text = "Pembayaran belum lunas";
        }
        swal({
          title: "Cek pembayaran telah berhasil",
          icon: "success",
          type: "success",
          text: text,
        }).then((value) => {
          this.page(this.state.page);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  toExcel = () => {
    const payload = this.state.toExcelPayload;
    payload.date = payload.specificDate;
    toExcel
      .fromConsultations(payload)
      .then((result) => {
        blob
          .getBlob(result.id)
          .then((res) => {
            downloadjs(
              `data:${res.contentType};base64,${res.base64}`,
              `Tabel_Laporan_Pasien_${moment().format("YYYYMMDDHHmmss")}.xlsx`,
              `${res.contentType}`
            );
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  renderFilterComponent = () => {
    return (
      <div>
        <div
          style={{ display: "inline-block", marginLeft: 15, marginRight: 15 }}
        >
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiSelect
                placeholder="Organisasi"
                options={this.state.organizations}
                value={this.state.organizationId}
                name="organizationName"
                onChange={this.onOrganizationChange}
                aria-label="Organisasi"
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
        <div style={{ display: "inline-block" }}>
          <EuiFlexGroup>
            <EuiFlexItem grow={false} style={{ marginRight: "-10px" }}>
              <EuiSelect
                style={{ display: "inline-block !important" }}
                placeholder="Cari berdasarkan"
                options={this.state.dateRangeOptions}
                value={this.state.dateRange}
                name="dateRange"
                onChange={this.onDateRangeChange}
                aria-label="Cari berdasarkan"
              />
            </EuiFlexItem>
            {this.state.dateRange === "specificDate" && (
              <EuiFlexItem grow={false}>
                <EuiDatePicker
                  selected={this.state.specificDate}
                  onChange={this.handleDateChange}
                />
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </div>
        <div style={{ display: "inline-block", marginLeft: 30 }}>
          <EuiFlexGroup>
            <EuiFlexItem grow={false} style={{ marginRight: "-10px" }}>
              <EuiSelect
                style={{ display: "inline-block !important" }}
                placeholder="Cari berdasarkan"
                options={this.state.searchOptions}
                value={this.state.searchBy}
                name="searchBy"
                onChange={this.onSearchByChange}
                aria-label="Cari berdasarkan"
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              {this.state.searchBy === "staffName" && (
                <EuiFieldText
                  style={{ display: "inline-block !important" }}
                  placeholder="Cari dokter..."
                  name="searchString"
                  onChange={this.onSearchStringChange}
                  value={this.state.searchString}
                />
              )}
              {this.state.searchBy === "patientName" && (
                <EuiFieldText
                  style={{ display: "inline-block !important" }}
                  placeholder="Cari..."
                  name="searchString"
                  onChange={this.onPatientSearchStringChange}
                  value={this.state.patientSearchString}
                />
              )}
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiButton onClick={() => this.toExcel()} fill>
                Download to Excel
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
      </div>
    );
  };

  childPage = (page, slotId) =>
    new Promise((resolve, reject) => {
      page = page || 1;
      let payload = {
        page: page,
        limit: 10,
        search: "",
        order: "asc",
        orderBy: "",
      };
      payload.slotId = slotId;
      console.log("initializing..");
      sessionsService
        .getSessions(payload)
        .then((result) => {
          let obj = {
            childData: result.Items ? result.Items : [],
            expandedRowItem: [
              {
                title: "",
                description: this.renderChild(
                  result.Items || [],
                  this.state.childColumn
                ),
              },
            ],
          };
          obj[this.state.currentSlotId] = false;
          this.setState(obj);
          resolve(obj);
        })
        .catch((err) => {
          let obj = {
            childData: [],
          };
          obj[this.state.currentSlotId] = false;
          this.setState(obj);
          reject(obj);
        });
      this.forceUpdate();
    });

  onExpandedRow = (item) => {
    return new Promise((resolve, reject) => {
      this.childPage(1, item.id)
        .then((x) => resolve(x.expandedRowItem))
        .catch((err) => reject(err));
    });
  };

  onSelectedTabChanged = (id) => {
    this.setState({ selectedTabId: id, searchDate: "" }, () => {
      this.page(1);
      this.loadOrganizations();
    });
  };

  onTableChange = ({ page = {}, sort = {} }) => {
    const { field: sortField, direction: sortDirection } = sort;
    console.log(sortDirection);
    this.setState({ order: !this.state.order, orderBy: sortField }, () => {
      this.page(this.state.page);
    });
  };

  render() {
    const sorting = {
      sort: {
        field: this.state.orderBy,
        direction: this.state.order,
      },
      enableAllColumns: true,
    };
    return (
      <>
        <EuiPage className="class">
          <EuiPageBody
            style={{ flexDirection: "row" }}
            className={"content-container"}
          >
            <EuiPageContent>
              <EuiPageContentHeader>
                <EuiPageContentHeaderSection>
                  <EuiTitle>
                    <h2>Jadwal Dokter Hari Ini</h2>
                  </EuiTitle>
                </EuiPageContentHeaderSection>
              </EuiPageContentHeader>
              <EuiPageContentHeader></EuiPageContentHeader>
              <EuiPageContentHeader>
                {this.renderFilterComponent()}
              </EuiPageContentHeader>
              <EuiPageContentBody>
                {!this.state.sessionSlotLoading &&
                  this.state.data &&
                  this.state.data.length < 0 && <p>Belum ada data</p>}
                {this.state.sessionSlotLoading && (
                  <p>
                    <EuiLoadingSpinner size="xl" />
                  </p>
                )}
                {this.state.data && (
                  <EuiFlexItem>
                    <EuiPage className="class">
                      <TablePage
                        onExpandedRow={(item) => this.onExpandedRow(item)}
                        expandedRowItem={this.state.expandedRowItem}
                        placeholder={"Cari"}
                        data={this.state.data}
                        column={this.state.column}
                        detailToggle={true}
                        // disablePagination={this.state[slot.id]}
                        page={this.state.page}
                        limit={this.state.limit}
                        total={this.state.total}
                        prev={this.prevPage}
                        next={this.nextPage}
                        toPage={this.page}
                        onItemClick={this.onItemClick}
                        sort={sorting}
                        change={this.onTableChange}
                      />
                    </EuiPage>
                  </EuiFlexItem>
                )}
              </EuiPageContentBody>
              {this.state.data == null && (
                <EuiPage>
                  <EuiFlexGroup direction="column">
                    <EuiFlexItem>
                      <p>
                        <EuiIcon type="clock" size="xxl" />
                      </p>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <p>Tidak ada jadwal hari ini</p>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiPage>
              )}
            </EuiPageContent>
          </EuiPageBody>
        </EuiPage>
      </>
    );
  }
}

export default Sessions;
