import React, { Component } from 'react';
import moment from 'moment';
import {
  EuiFieldText,
} from '@elastic/eui';
import {Calendar} from 'react-modern-calendar-datepicker';

class Datepicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCalendar: false,
    };
  }

  dateChange(date){
    this.props.onChange(date);
    this.setState({showCalendar: false});
  }

  render() {
    const {showCalendar} = this.state;
    const value = this.props.value ? this.props.value : moment.utc();
    return (
      <div className={"parent-calendar"}>
        <EuiFieldText
          fullWidth
          value={value.format("DD-MM-YYYY")}
          onClick={() => this.setState({ showCalendar: !showCalendar })}
          onChange={() => this.setState({ showCalendar: true })}
        />
        {showCalendar ? (
          <div className={"calendar-holder"}>
            <Calendar
              value={{
                day: value.date(),
                month: value.month() + 1,
                year: value.year(),
              }}
              onChange={ date => this.dateChange(date)}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default Datepicker;
