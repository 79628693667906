import Config from '../Config';
import axios from 'axios';
import Error from './Error';
import Misc from './Misc';

const ErrorService = new Error();
const MiscService = new Misc();

class Products {
  getProducts = () => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/products`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  getFeeConfig = () => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/fee-config`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  getAllProducts = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {}
      payload.page = payload.page || 1
      payload.limit = payload.limit || 1
      payload.order = payload.order || "ASC"
      payload.activeOnly = payload.activeOnly || "true"
      let url = `${Config.apiHost}/api/all-products?page=${payload.page}&limit=${payload.limit}&order=${payload.order}&activeOnly=${payload.activeOnly}`
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  updateProduct = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {}
      let url = `${Config.apiHost}/api/product/${payload.id}`;
      axios.put(url, payload, { headers: MiscService.generateHeaders() })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err)
        ErrorService.handle(err);
      })
    })
  } 

  deleteProduct = (id) => {
    return new Promise((resolve, reject) => {
      id = id || ''
      let url = `${Config.apiHost}/api/product/${id}`;
      axios.delete(url, { headers: MiscService.generateHeaders() })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err)
        ErrorService.handle(err);
      })
    })
  }

  createProduct = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {}
      let url = `${Config.apiHost}/api/products`;
      axios.post(url, payload, { headers: MiscService.generateHeaders() })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err)
        ErrorService.handle(err);
      })
    })
  }

  getProductsByHomecareClinicId = (id) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/products-by-homecare-clinic/${id}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  updateProductsHomeCareClinic = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {}
      let url = `${Config.apiHost}/api/products-by-homecare-clinic/${payload.clinic_id}`;
      axios.put(url, payload, { headers: MiscService.generateHeaders() })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err)
        ErrorService.handle(err);
      })
    })
  } 
}

export default Products;
