import React, { Component } from 'react';
import {
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiTitle,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiSelect,
  EuiFilePicker,
  EuiCheckbox,
  EuiTextArea,
  EuiButton,
  EuiGlobalToastList
} from '@elastic/eui';
import PharmaciesService from '../services/Pharmacies';
import BlobService from '../services/Blob';

const pharmaciesService = new PharmaciesService();
const blobService = new BlobService();

class PharmacyDetail extends Component {

  state = {
    id: '',
    code: '',
    name: '',
    groupId: '',
    groupName: '',
    location: '',
    status: '',
    whatsappNumber: '',
    APAName: '',
    STRANumber: '',
    SITANumber: '',
    KTPAPANumber: '',
    district: '',
    subDistrict: '',
    STRAFileId: '',
    SITAFileId: '',
    groupList: [],
    toasts: [],
    showModalMap: false,
    isLoading: false,
    isNew: false,
    isEdited: false,
    isLoadingCreate: false,
    isLoadingUpdate: false,
    isLoadingSTRAFile: false,
    isLoadingSITAFile: false
  }

  componentDidMount = () => {
    this.getPharmacyGroups();
    let uuid = this.props.match.params.uuid;
    if (uuid === "new") {
      return this.prepareForNewPharmacy();
    }
    this.getPharmacy(uuid);
  }

  getPharmacy = (uuid) => {
    this.setState({
      isLoading: true,
      id: uuid
    });

    pharmaciesService.getPharmacy(uuid)
      .then(res => {
        console.log(res);
        this.setState({
          code: res.code,
          name: res.name,
          groupId: res.groupId,
          groupName: res.groupName,
          location: res.location,
          status: res.status,
          whatsappNumber: res.whatsappNumber,
          APAName: res.APAName,
          STRANumber: res.STRANumber,
          SITANumber: res.SITANumber,
          KTPAPANumber: res.KTPAPANumber,
          district: res.district,
          subDistrict: res.subDistrict,
          STRAFileId: res.STRAFileId,
          SITAFileId: res.SITAFileId,
          isLoading: false,
          isLoadingSTRAFile: false,
          isLoadingSITAFile: false
        })
      })
      .catch(err => {
        console.log(err);
        this.setState({
          isLoading: false
        });
      })
  }

  getPharmacyGroups = () => {
    const payload = {
      page: 1,
      limit: 100,
      order: "ASC"
    }

    pharmaciesService.getPharmacyGroups(payload)
      .then(res => {
        let groupList = [];
        res.items.forEach(item => {
          groupList.push({
            value: item.id,
            text: item.name
          });
        })
        this.setState({
          groupList
        });
      })
      .catch(err => {
        console.log(err);
      })
  }

  prepareForNewPharmacy = () => {
    this.setState({
      isNew: true,
      isEdited: true
    });
  }

  straUploadFile = file => {
    this.setState({
      isLoadingSTRAFile: true
    });
    blobService.uploadFile(file[0])
      .then(res => {
        this.setState({
          STRAFileId: res,
          isLoadingSTRAFile: false
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          isLoadingSTRAFile: true
        });
      });
  }

  sitaUploadFile = file => {
    this.setState({
      isLoadingSITAFile: true
    });
    blobService.uploadFile(file[0])
      .then(res => {
        this.setState({
          SITAFileId: res,
          isLoadingSITAFile: false
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          isLoadingSITAFile: false
        });
      });
  }

  createPharmacy = () => {
    const payload = {
      code: this.state.code,
      name: this.state.name,
      groupId: this.state.groupId,
      groupName: this.state.groupName,
      location: this.state.location,
      whatsappNumber: this.state.whatsappNumber,
      APAName: this.state.APAName,
      STRANumber: this.state.STRANumber,
      SITANumber: this.state.SITANumber,
      KTPAPANumber: this.state.KTPAPANumber,
      district: this.state.district,
      subDistrict: this.state.subDistrict,
      STRAFileId: this.state.STRAFileId,
      SITAFileId: this.state.SITAFileId
    }

    this.setState({
      isLoadingCreate: true
    });

    pharmaciesService.createPharmacy(payload)
      .then(res => {
        console.log(res);
        this.setState({
          isLoadingCreate: false,
          toasts: [
            {
              title: 'Berhasil!',
              color: 'success',
              iconType: 'check',
              text: <p>Apotek berhasil dibuat!</p>,
            },
          ]
        });
        setTimeout(() => {
          this.props.history.push("/pharmacies");
        }, 2000)
      })
      .catch(err => {
        console.log(err);
        this.setState({
          isLoadingCreate: false,
          toasts: [
            {
              title: 'Gagal!',
              color: 'danger',
              iconType: 'cross',
              text: <p>Ada masalah saat memperbaruhi!</p>,
            },
          ]
        });
      })
  }

  updatePharmacy = () => {

    const payload = {
      id: this.state.id,
      code: this.state.code,
      name: this.state.name,
      groupId: this.state.groupId,
      groupName: this.state.groupName,
      location: this.state.location,
      whatsappNumber: this.state.whatsappNumber,
      APAName: this.state.APAName,
      STRANumber: this.state.STRANumber,
      SITANumber: this.state.SITANumber,
      KTPAPANumber: this.state.KTPAPANumber,
      district: this.state.district,
      subDistrict: this.state.subDistrict,
      STRAFileId: this.state.STRAFileId,
      SITAFileId: this.state.SITAFileId
    }

    this.setState({
      isLoadingUpdate: true
    });

    pharmaciesService.updatePharmacy(payload)
      .then(res => {
        console.log(res);
        this.setState({
          isLoadingUpdate: false,
          toasts: [
            {
              title: 'Berhasil!',
              color: 'success',
              iconType: 'check',
              text: <p>Apotek berhasil diperbaruhi!</p>,
            },
          ]
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          isLoadingUpdate: false,
          toasts: [
            {
              title: 'Gagal!',
              color: 'danger',
              iconType: 'cross',
              text: <p>Ada masalah saat memperbaruhi!</p>,
            },
          ]
        });
      })
  }

  cancel = () => {
    this.props.history.push("/pharmacies");
  }

  removeToast = () => {
    this.setState({
      toasts: []
    });
  }

  render = () => {
    return (
      <>
        <EuiPage>
          <EuiPageBody
            style={{ flexDirection: 'row', textAlign: 'center', width: '100%' }}
            className={'content-container'}>

            <EuiPageContent>
              <EuiPageContentHeader>
                <EuiPageContentHeaderSection>
                  <EuiTitle>
                    {this.state.isNew ? (
                      <h2>Tambah Apotik</h2>
                    ) : (
                        <h2>Lihat Apotik</h2>
                      )}
                  </EuiTitle>
                </EuiPageContentHeaderSection>
              </EuiPageContentHeader>

              <EuiPageContentHeaderSection>
                <EuiForm component="form">
                  <EuiFormRow label="Kode Internal Apotek">
                    <EuiFieldText name="code" onChange={(e) => this.setState({ code: e.target.value })} value={this.state.code} readOnly={!this.state.isEdited} />
                  </EuiFormRow>
                  <EuiFormRow label="Nama Apotek">
                    <EuiFieldText name="name" onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} readOnly={!this.state.isEdited} />
                  </EuiFormRow>
                  <EuiFormRow label="Nama Group">
                    <EuiSelect
                      hasNoInitialSelection
                      options={this.state.groupList}
                      onChange={(e) => this.setState({ groupId: e.target.value })}
                      value={this.state.groupId}
                    />
                  </EuiFormRow>
                  <EuiFormRow label="No Telp / Kontak">
                    <EuiFieldText name="whatsappNumber" onChange={(e) => this.setState({ whatsappNumber: e.target.value })} value={this.state.whatsappNumber} readOnly={!this.state.isEdited} />
                  </EuiFormRow>
                  <EuiFormRow label="Nama APA (Apoteker Pengelola Apotek)">
                    <EuiFieldText name="APAName" onChange={(e) => this.setState({ APAName: e.target.value })} value={this.state.APAName} readOnly={!this.state.isEdited} />
                  </EuiFormRow>
                  <EuiFormRow label="No STRA (Surat Tanda Registrasi Apoteker)">
                    <EuiFieldText name="STRANumber" onChange={(e) => this.setState({ STRANumber: e.target.value })} value={this.state.STRANumber} readOnly={!this.state.isEdited} />
                  </EuiFormRow>
                  <EuiFormRow label="Unggah STRA">
                    <EuiFilePicker onChange={this.straUploadFile} isLoading={this.state.isLoadingSTRAFile} disabled={!this.state.isEdited} />
                  </EuiFormRow>
                  <EuiFormRow label="No SITA (Surat Izin Praktek Apoteker)">
                    <EuiFieldText name="SITANumber" onChange={(e) => this.setState({ SITANumber: e.target.value })} value={this.state.SITANumber} readOnly={!this.state.isEdited} />
                  </EuiFormRow>
                  <EuiFormRow label="Unggah SITA">
                    <EuiFilePicker onChange={this.sitaUploadFile} isLoading={this.state.isLoadingSITAFile} disabled={!this.state.isEdited} />
                  </EuiFormRow>
                  <EuiFormRow label="No KTP APA">
                    <EuiFieldText name="KTPAPANumber" onChange={(e) => this.setState({ KTPAPANumber: e.target.value })} value={this.state.KTPAPANumber} readOnly={!this.state.isEdited} />
                  </EuiFormRow>
                  <EuiFormRow>
                    <EuiCheckbox
                      id="check"
                      label="Gunakan Alamat / Pinpoint dibawah ini"
                      checked={false}
                      onChange={(e) => console.log(e)}
                    />
                  </EuiFormRow>
                  <EuiFormRow label="Alamat">
                    <EuiTextArea
                      aria-label="Alamat"
                      onChange={(e) => this.setState({ location: e.target.value })}
                      value={this.state.location}
                      readOnly={!this.state.isEdited}
                    />
                  </EuiFormRow>
                  <EuiFormRow>
                    <div>
                      <span style={{ marginRight: 12 }}>Pinpoint belum terekam</span>
                      <EuiButton>Ubah Pinpoint</EuiButton>
                    </div>
                  </EuiFormRow>
                  <EuiFormRow label="Kelurahan">
                    <EuiFieldText name="district" onChange={(e) => this.setState({ district: e.target.value })} value={this.state.district} readOnly={!this.state.isEdited} />
                  </EuiFormRow>
                  <EuiFormRow label="Kecamatan">
                    <EuiFieldText name="subDistrict" onChange={(e) => this.setState({ subDistrict: e.target.value })} value={this.state.subDistrict} readOnly={!this.state.isEdited} />
                  </EuiFormRow>

                  {this.state.isNew ? (
                    <EuiFormRow>
                      <EuiButton fill onClick={() => this.createPharmacy()} style={{ marginRight: 12 }} isLoading={this.state.isLoadingCreate}>
                        Simpan
                      </EuiButton>
                    </EuiFormRow>
                  ) : (
                      <EuiFormRow>
                        <div>
                          {this.state.isEdited ? (
                            <EuiButton fill onClick={() => this.updatePharmacy()} style={{ marginRight: 12 }} isLoading={this.state.isLoadingUpdate}>
                              Simpan
                            </EuiButton>
                          ) : (
                              <EuiButton fill onClick={() => this.setState({ isEdited: true })} style={{ marginRight: 12 }}>
                                Ubah
                              </EuiButton>
                            )}
                          <EuiButton color="danger" onClick={() => this.cancel()} style={{ marginLeft: 12 }}>
                            Batal
                        </EuiButton>
                        </div>
                      </EuiFormRow>
                    )}


                </EuiForm>
              </EuiPageContentHeaderSection>
            </EuiPageContent>

          </EuiPageBody>
        </EuiPage>

        <EuiGlobalToastList
          toasts={this.state.toasts}
          dismissToast={this.removeToast}
          toastLifeTimeMs={6000}
        />
      </>
    )
  }
}

export default PharmacyDetail