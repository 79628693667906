import camelcaseKeysDeep from "camelcase-keys-deep";
/**
 * Validate required fields
 * @param {object} state - the state of the form component
 * @returns {object} result - contains isValid and refreshed validationFields object
 *
 *  Define validationFields object in state. The keys are the fields that are required. They has some properti:
 *  - type - the type of the value, whether it's string, date bool or number
 *  - isInvalid - the bool state of whether the form is valid or not, to be used in eui component
 *  - errors - the error message that should be shown if the value is invalid, in array
 *  - isValidFunc - (optionl) function to check customized validation, return true if valid
 *
 *  Example:

      this.state = {
        productName: '',
        slug: '',
        value: '',
        status: true,
        date: moment(),
        fixture: false, // for unit testing purpose

        validationFields: {
          productName: {
            type: 'string',
            isInvalid: false,
            errors: ['Nama produk tidak boleh kosong'],
          },
          date: {
            type: 'date',
            isInvalid: false,
            errors: ['Tanggal harus lebih anyar dari 2018'],
            isValidFunc: function(value) {
              let date = new Date(value)
              return (date.getFullYear() > 2018);
            }
          },
          slug: {
            type: 'string',
            isInvalid: false,
            errors: ['Slug hanya boleh mengandung karakter alfabet dengan huruf kecil dan strip (-)'],
            isValidFunc: function(value) {
              let exp = new RegExp('^[a-z](-?[a-z])*$', 'g')
              return exp.test(value) ? true : false;
            }
          },
        }
      }

  ...

  onSave(){
  let state = {...this.state}
  utils.validateFields(state)
  .then((result) => {
    this.setState({validationFields: result.validationFields})
    if (!result.isValid) {
      throw new Error("Invalid");
    }
 ...

 *
 */
const validateFields = state => {
  return new Promise((resolve, reject) => {
    let fields = {...state.validationFields};
    let invalids = Object.keys(fields).filter(field => {
      let value = state[field];
      switch (fields[field].type) {
        case 'string':
          if (fields[field].isValidFunc) {
            let isValid = fields[field].isValidFunc(value);
            fields[field].isInvalid = isValid ? false : true;
          } else {
            fields[field].isInvalid = value && value.length > 0 ? false : true;
          }
          return fields[field].isInvalid ? true : false;
        case 'date':
          if (fields[field].isValidFunc) {
            let isValid = fields[field].isValidFunc(value);
            fields[field].isInvalid = isValid ? false : true;
          } else {
            fields[field].isInvalid =
              value && value._isAMomentObject ? false : true;
          }
          return fields[field].isInvalid ? true : false;
        case 'bool':
          if (fields[field].isValidFunc) {
            let isValid = fields[field].isValidFunc(value);
            fields[field].isInvalid = isValid ? false : true;
          } else {
            return false;
          }
          return fields[field].isInvalid ? true : false;
        case 'number':
          if (fields[field].isValidFunc) {
            let isValid = fields[field].isValidFunc(value);
            fields[field].isInvalid = isValid ? false : true;
          } else {
            return false;
          }
          return fields[field].isInvalid ? true : false;
        default:
          return false;
      }
    });
    resolve({
      isValid: invalids.length > 0 ? false : true,
      validationFields: fields,
    });
  });
};

const reformatPhoneNumber = phoneNumber => {
  phoneNumber = phoneNumber.trim();
  if (phoneNumber[0] === '0') {
    phoneNumber = '62' + phoneNumber.substr(1);
  }
  if (phoneNumber[0] === '+') {
    phoneNumber = phoneNumber.substr(1);
  }
  return phoneNumber;
};

const generateTimeRangeOptions = (minutesToAdd, start) => {
  let result = [{ text: start, value: start }];
  let loop = true;
  let count = 0;
  while (loop) {
    // Safenet
    if (count > 100) {
      break;
    }
    let prevValue = result[result.length - 1].value;
    let hour = parseInt(prevValue.split(':')[0], 10);
    let minute = parseInt(prevValue.split(':')[1], 10);
    let current = new Date();
    current.setHours(hour);
    current.setMinutes(minute);
    current = new Date(current.getTime() + minutesToAdd * 60000);
    hour = current.getHours().toString();
    if (hour.length === 1) hour = '0' + hour;
    minute = current.getMinutes().toString();
    if (minute.length === 1) minute = '0' + minute;
    let obj = {};
    obj['text'] = hour + ':' + minute + ':00';
    obj['value'] = hour + ':' + minute + ':00';
    result.push(obj);
    count++;
    if (
      current.getHours() > 22 &&
      current.getMinutes() > 60 - minutesToAdd - 1
    ) {
      break;
    }
  }
  return result;
};

const parseJwt = (token) => {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(
    Buffer.from(base64, 'base64')
      .toString()
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}


const snakeCase = (obj) => {
  if (typeof(obj) != "object") return obj;
  for(var oldName in obj){
      // Camel to underscore
      let newName = oldName.replace(/([A-Z])/g, function($1){return "_"+$1.toLowerCase();});
      // Only process if names are different
      if (newName != oldName) {
          // Check for the old property name to avoid a ReferenceError in strict mode.
          if (obj.hasOwnProperty(oldName)) {
              obj[newName] = obj[oldName];
              delete obj[oldName];
          }
      }
      // Recursion
      if (typeof(obj[newName]) == "object") {
          obj[newName] = snakeCase(obj[newName]);
      }
  }
  return obj;
}

const camelCase = camelcaseKeysDeep;


const Utils = {
  validateFields,
  reformatPhoneNumber,
  generateTimeRangeOptions,
  parseJwt,
  snakeCase,
  camelCase,
};


export default Utils;
