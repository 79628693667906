import React, { Component } from 'react';

import { EuiPage, EuiPageBody, EuiPageContent, EuiTitle } from '@elastic/eui';
import PaymentService from '../services/Payments';
import moment from 'moment';
import TablePage from '../components/TablePage';

const paymentService = new PaymentService();
const bankCodeNames = require('../assets/bankCodeNames.json');
const paymentCodeNames = {
  CC: 'Kartu Kredit',
  DC: 'Kartu Debit',
  VA: 'Virtual Account',
};
const paymentStatuses = {
  0: 'UNPAID',
  1: 'PAID',
  2: 'UNDERPAID',
  3: 'OVERPAID',
  4: 'CANCELED',
  5: 'REPLACED',
};
class Payments extends Component {
  state = {
    page: 1,
    limit: 10,
    order: 'ASC',
    search: '',
    loading: false,
    orderBy: 'name',
    data: [],
    column: [
      {
        field: 'metadata.patient.externalId',
        name: 'MRN',
      },
      {
        field: 'metadata.patient.dob',
        name: 'Tanggal lahir',
      },
      {
        field: 'metadata.patient.name',
        name: 'Nama pengirim',
      },
      {
        field: 'metadata.patient.phoneNumber',
        name: 'Nomor telepon',
      },
      {
        field: 'description',
        name: 'Nama sesi',
      },
      {
        field: 'payment_status',
        name: 'Status',
      },
      {
        field: 'payment_method',
        name: 'Metode pembayaran',
      },
      {
        field: 'amount.units',
        name: 'Jumlah (IDR)',
      },
      {
        field: 'purchase_date',
        name: 'Purchase date',
      },
    ],
  };

  page = (page) => {
    page = page || 1;
    this.setState({ loading: true, search: '' });
    let payload = {
      page: page,
      limit: this.state.limit,
      search: this.state.search,
      order: this.state.order,
      orderBy: this.state.orderBy,
    };
    paymentService
      .getPayments(payload)
      .then((result) => {
        let detailData = [];

        if (result.data) {
          console.log(result.data);
          for (var i in result.data) {
            let item = result.data[i];
            if (item?.metadata && item?.metadata.length > 0 ) {
                  item.metadata = JSON.parse(item.metadata);
                  if (item?.metadata?.patient != null && item?.metadata?.staff != null && item?.metadata?.clinic != null) {
                      item.description =item?.metadata?.clinic?.name + ' / ' + item?.metadata?.staff?.name;
                      item.metadata.patient.dob = item?.metadata?.patient?.dob.substr(0,10);    
                    }
            } 
            item.purchase_date = item.payment_timestamp.substr(0, 10);
            item.payment_status = paymentStatuses[item.payment_status];
          }
        }
        console.log(result.data)
        this.setState(
          {
            page: result.page,
            limit: result.limit,
            total: result.total,
            order: result.order,
            orderBy: result.orderBy,
            data: result.data || [],
            loading: false,
          },
          () => {
            this.forceUpdate();
          }
        );
      })
      .catch((err) => {
        alert(3);
        console.log(err);
        this.setState({
          loading: false,
          data: [],
        });
      });
  };

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };
  componentDidMount = () => {
    this.page(1);
  };
  render() {
    return (
      <>
        <EuiPage className="class">
          <EuiPageBody
            style={{ flexDirection: 'row' }}
            className={'content-container'}
          >
            {!this.state.loading && (
              <TablePage
                title={'Pembayaran'}
                placeholder={'Nomor Tagihan'}
                data={this.state.data}
                column={this.state.column}
                location={this.props.location}
                disablePagination={this.state.loading}
                page={this.state.page}
                limit={this.state.limit}
                total={this.state.total}
                prev={this.prevPage}
                next={this.nextPage}
                toPage={this.page}
              />
            )}
          </EuiPageBody>
        </EuiPage>
      </>
    );
  }
}

export default Payments;
