import React, { Component } from "react";

import {
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiButtonEmpty,
  EuiFieldText,
} from "@elastic/eui";
import Dashboard from "./Dashboard";

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryPatient: "",
      queryDoctor: "",
      queryStaff: "",
      queryBill: "",
    };
  }

  render() {
    const self = this;
    return (
      <>
        <EuiPage className='class'>
          <EuiPageBody
            style={{ flexDirection: 'row' }}
            className={'content-container'}
          >
              Hello
          </EuiPageBody>

        </EuiPage>
      </>
    );
  }
}

export default Landing;
