import React, { Component } from "react";
import { 
  EuiPage, 
  EuiPageBody,
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiFieldText,
  EuiModalFooter,
  EuiButton,
  EuiButtonEmpty,
  EuiFlexItem,
  EuiFlexGroup,
  EuiDatePicker,
} from "@elastic/eui";
import moment from 'moment';
import TablePage from "../components/TablePage";
import VouchersService from '../services/Vouchers';
import InvoiceService from '../services/Invoices';
import { debounce } from 'lodash';

const voucherService = new VouchersService();
const invoiceService = new InvoiceService();

class VoucherUseds extends Component {
  state = {
    page: 1,
    limit: 10,
    total: 0,
    order: 'DESC',
    orderBy: 'o.created_date',
    loading: false,
    saveLoading: false,
    deleteLoading: false,
    startDate: moment(),
    endDate: moment(),
    modalSearchDate: false,
    useSearchDate: false,
    data: [],
    column: [
      {
        field: "voucher_code",
        name: "Kode Voucher"
      },
      {
        field: "datetime",
        name: "Waktu Penggunaan"
      },
      {
        field: "number",
        name: "Nomor Transaksi"
      },
      {
        field: "patient_name",
        name: "Pasien"
      },
      {
        field: "product_type",
        name: "Jenis",
        truncateText: true,
      },
      {
        field: "discount",
        name: "Nilai Diskon",
        render: (params) => {
          return this.formatDiscount(`${params}`)
        },
      },
      {
        field: "invoice_id",
        name: "Aksi",
        render: (invoiceID) => (
          <div id={'action-id'}>
            <EuiButtonEmpty onClick={this.showModal} size="xs">
              Lihat Kwitansi
            </EuiButtonEmpty>
          </div>
        ),
      },
    ],
    selectedItem: {
      id: "",
      voucher_code: "",
      datetime: "",
      number: "",
      patient_name: "",
      product_type: "",
      discount: "",
      session_id: "",
      invoice_id: "",
      curl: "",
    },
  }

  page = page => {
    page = page || 1;
    this.setState({loading: true});
    let payload = {
      page: page,
      limit: this.state.limit,
      search: this.state.search,
      order: this.state.order,
      orderBy: this.state.orderBy,
    };

    if (this.state.useSearchDate) {
      payload.startDate = this.state.startDate.format('YYYY-MM-DD');
      payload.endDate = this.state.endDate.format('YYYY-MM-DD');
    }

    var result
    voucherService.getVouchers(payload)
      .then(response => {
        result = response
        console.log(result)
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          order: result.order,
          orderBy: result.order_by,
          data: result.list || [],
          loading: false,
          modalSearchDate: false
        },() => { this.forceUpdate(); });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loading: false,
          data: [],
          modalSearchDate: false
        });
      });
  };

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };
  
  componentDidMount = () => {
    this.page(1);
  };

  onItemClick = (item) => {
    console.log(item)    
    this.setState({loading: true});
    invoiceService.getInvoicesByID(item.invoice_id).then(res => {
      console.log(res)
      this.setState({
        modal: true,
        selectedItem: {
          id: item.id,
          voucher_code: item.voucher_code,
          datetime: item.datetime,
          number: item.number,
          patient_name: item.patient_name,
          product_type: item.product_type,
          discount: this.formatDiscount(`${item.discount}`),
          session_id: item.session_id,
          invoice_id: item.invoice_id,
          curl: item.curl,
        },
        loading: false,
      });
    }).catch(err => {
      this.setState({loading: false});
      console.log(err);
    })
  }

  handleOnSearch = (value, useSearchDate = false) => {
    if (!useSearchDate) {
      this.setState({
        startDate: moment(),
        endDate: moment()
      })
    }
    this.setState({ search: value, loading: true, useSearchDate: useSearchDate });
    this.handleSearchDebounce();
  };

  handleDateChangeStart = (date) => {
    this.setState({ startDate: date });
  };

  handleDateChangeEnd = (date) => {
    this.setState({ endDate: date });
  };

  formatDiscount = (params) => {
    let discount = `${params}`
    return discount.substring(1, discount.lenght)
  };

  handleSearchDebounce = debounce(() => {
    this.page(1)
  }, 2000);

  render() {
    return (
      <>
      {this.state.modal && (
        <EuiOverlayMask>
          <EuiModal
            onClose={() => {this.setState({ modal: false })}}
            initialFocus="[name=popswitch]"
          >
            <EuiModalHeader>
              <EuiModalHeaderTitle>
                Detail Penggunaan Voucher
              </EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
              <EuiFlexGroup>
                <EuiFlexItem>
                  Tanggal
                </EuiFlexItem>
                <EuiFlexItem>
                  {this.state.selectedItem.datetime}
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiFlexGroup>
                <EuiFlexItem>
                  Kode Voucher
                </EuiFlexItem>
                <EuiFlexItem>
                  {this.state.selectedItem.voucher_code}
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiFlexGroup>
                <EuiFlexItem>
                  Nomor Transaksi
                </EuiFlexItem>
                <EuiFlexItem>
                  {this.state.selectedItem.number}
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiFlexGroup>
                <EuiFlexItem>
                  Nama Pasien
                </EuiFlexItem>
                <EuiFlexItem>
                  {this.state.selectedItem.patient_name}
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiFlexGroup>
                <EuiFlexItem>
                  Jenis Pembayaran
                </EuiFlexItem>
                <EuiFlexItem>
                  {this.state.selectedItem.product_type}
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiFlexGroup>
                <EuiFlexItem>
                  Nilai Diskon
                </EuiFlexItem>
                <EuiFlexItem>
                  {this.state.selectedItem.discount}
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiModalBody>
            <EuiModalFooter>
                <EuiButton
                  color={'primary'}
                  onClick={() => this.setState({
                    modal: false,
                  })}
                  fill>
                  Tutup
                </EuiButton>
            </EuiModalFooter>
          </EuiModal>
        </EuiOverlayMask>
      )}
      <EuiPage className="class">
        <EuiPageBody
          style={{ flexDirection: "row" }}
          className={"content-container"}
        >  
          <TablePage
            title={"Laporan Penggunaan Voucher"}
            placeholder={"Penggunaan Voucher"}
            data={this.state.data}
            column={this.state.column}
            disablePagination={this.state.loading}
            page={this.state.page}
            limit={this.state.limit}
            total={this.state.total}
            prev={this.prevPage}
            next={this.nextPage}
            toPage={this.page}
            onItemClick={this.onItemClick}
            loading={this.state.loading}
            searchComp={
              <>
                <EuiFlexGroup style={{ marginRight: 10 }}>
                  <EuiFlexItem>
                    <EuiFieldText
                      value={this.state.search}
                      onChange={e => this.handleOnSearch(e.target.value)}
                      placeholder={'Kode Voucher...'}
                      />
                    </EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                  <EuiFlexItem grow={false}>
                    <EuiDatePicker
                      selected={this.state.startDate}
                      onChange={this.handleDateChangeStart}
                    />
                  </EuiFlexItem>
                  <EuiFlexItem>
                      <EuiButton onClick={() => this.handleOnSearch(this.state.search, true)} fill>
                        Cari Tanggal
                      </EuiButton>
                  </EuiFlexItem>  
                  <EuiFlexItem>
                      <EuiButton onClick={() => {
                        this.setState({
                          startDate: moment(),
                          endDate: moment(),
                          search: '',
                        }, () => this.handleOnSearch(this.state.search))
                      }}>
                        Reset pencarian
                      </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </>
            }
          />
        </EuiPageBody>
      </EuiPage>
    </>
    )
  }
}

export default VoucherUseds;
