import React, {Component} from 'react';

import {
  EuiPage,
  EuiPageBody,
  EuiButton,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiOverlayMask,
  EuiButtonEmpty,
  EuiConfirmModal,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiSelect,
} from '@elastic/eui';
import TablePage from '../components/TablePage';
import UserService from '../services/Users';
import OrganizationService from '../services/Organizations';
import ErrorService from '../services/Error.js';
import Utils from '../Utils';

const userService = new UserService();
const organizationService = new OrganizationService();
const errorService = new ErrorService();

class User extends Component {
  state = {
    page: 1,
    limit: 10,
    order: 'ASC',
    search: '',
    loading: false,
    orderBy: 'name',
    data: [],
    column: [
      {
        field: 'emailAddress',
        name: 'Alamat email',
      },
      {
        field: 'role',
        name: 'Role',
      },
      {
        field: 'organizationName',
        name: 'Organisasi',
      },
    ],

    // Form
    emailAddress: '',
    organizationId: '',
    organizationName: '',
    password: '',
    repeatPassword: '',
    validationFields: {
      emailAddress: {
        type: 'string',
        isInvalid: false,
        errors: ['Alamat email tidak boleh kosong'],
        isValidFunc: function(value) {
          return value && value.length > 0;
        },
      },
      organizationId: {
        type: 'string',
        isInvalid: false,
        errors: ['Organisasi tidak boleh kosong'],
        isValidFunc: function(value) {
          return true;
        },
      },
      password: {
        type: 'string',
        isInvalid: false,
        errors: ['Sandi tidak boleh kosong'],
        isValidFunc: function(value) {
          return value && value.length > 0;
        },
      },
      repeatPassword: {
        type: 'string',
        isInvalid: false,
        errors: ['Sandi yang diulangi harus sama'],
        isValidFunc: function(value) {
          return value && value.length > 0;
        },
      },
    },
  };

  componentDidMount = () => {
    this.page(1);
    this.loadOrganizations();
  };

  handleChange = e => {
    let value = e.target.value || null;
    let obj = {};
    obj[e.target.name] = value;
    obj['failedAttempt'] = false;
    let validationFields = {...this.state.validationFields};
    if (validationFields[e.target.name]) {
      validationFields[e.target.name].isInvalid = false;
      obj.validationFields = validationFields;
    }
    this.setState(obj);
  };

  onOrganizationChange = e => {
    let value = e.target.value || null;
    console.log(value);
    let name = '';
    for (let i in this.state.organizations) {
      if (this.state.organizations[i].value === value) {
        name = this.state.organizations[i].name;
      }
    }
    this.setState({organizationId: value, organizationName: name});
  };

  page = page => {
    page = page || 1;
    this.setState({loading: true, search: ''});
    let payload = {
      page: page,
      limit: this.state.limit,
      search: this.state.search,
      order: this.state.order,
      orderBy: this.state.orderBy,
    };
    userService
      .getUsers(payload)
      .then(result => {
        for (let i in result.items) {
          if (
            !(
              result.items[i].organizationId &&
              result.items[i].organizationId.length > 0 &&
              result.items[i].organizationName &&
              result.items[i].organizationName.length > 0
            )
          ) {
            result.items[i].organizationId =
              '00000000-0000-0000-0000-000000000000';
            result.items[i].organizationName = 'Telekonsul Superadmin';
          }
        }
        this.setState(
          {
            page: result.page,
            limit: result.limit,
            total: result.total,
            order: result.order,
            orderBy: result.orderBy,
            data: result.items,
            loading: false,
          },
          () => {
            this.forceUpdate();
          },
        );
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loading: false,
          data: [],
        });
      });
  };

  loadOrganizations = () => {
    let payload = {
      limit: 1000,
    };
    organizationService
      .getOrganizations(payload)
      .then(result => {
        let options = [
          {
            text: 'Telekonsul Superadmin',
            value: '00000000-0000-0000-0000-000000000000',
          },
        ];
        for (let i in result.items) {
          options.push({
            value: result.items[i].id,
            text: result.items[i].name,
          });
        }
        this.setState({organizations: options});
      })
      .catch(err => {
        console.log(err);
        this.setState({
          organizations: [],
        });
      });
  };

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };

  show = item => {
    this.setState({id: item.id, name: item.name, showModal: true});
  };

  add = () => {
    this.setState({
      id: '',
      emailAddress: '',
      organizationId: '',
      organizationName: '',
      showModal: true,
    });
  };

  save = () => {
    let state = {...this.state};
    this.setState({saveLoading: true}, () => {
      Utils.validateFields(state).then(result => {
        if (this.state.id && this.state.id.length > 0) {
          result.validationFields.password.isValid = true;
          result.validationFields.repeatPassword.isValid = true;
          this.setState({validationFields: result.validationFields});
          this.forceUpdate();
          console.log(result);
          if (
            result.validationFields.emailAddress.isInvalid ||
            result.validationFields.emailAddress.isInvalid
          ) {
            return;
          }
          console.log(result);
          // Update existing
          let payload = {
            emailAddress: this.state.emailAddress,
            organizationId:
              this.state.organizationId && this.state.organizationId.length > 0
                ? this.state.organizationId
                : '00000000-0000-0000-0000-000000000000',
            id: this.state.id,
          };
          if (
            payload.organizationId === '00000000-0000-0000-0000-000000000000'
          ) {
            payload.role = 'SUPERADMIN';
          } else {
            payload.role = 'ADMIN';
          }
          userService
            .update(payload)
            .then(result => {
              this.setState({saveLoading: false, loading: true});
              this.closeModal();
              this.page(1);
            })
            .catch(err => {
              this.setState({saveLoading: false});
              errorService.handle(err);
            });
        } else {
          this.setState({validationFields: result.validationFields});
          this.forceUpdate();
          if (!result.isValid) {
            return;
          }
          // Create new one
          let payload = {
            emailAddress: this.state.emailAddress,
            password: this.state.password,
            repeatPassword: this.state.repeatPassword,
            organizationId:
              this.state.organizationId && this.state.organizationId.length > 0
                ? this.state.organizationId
                : '00000000-0000-0000-0000-000000000000',
          };
          userService
            .create(payload)
            .then(result => {
              this.setState({saveLoading: false, loading: true});
              this.closeModal();
              this.page(1);
            })
            .catch(err => {
              this.setState({saveLoading: false});
              errorService.handle(err);
            });
        }
      });
    });
  };

  delete = () => {
    if (!(this.state.id && this.state.id.length > 0)) {
      return;
    }

    let currentUser = window.localStorage.getItem('currentUser');
    currentUser = JSON.parse(currentUser);
    if (currentUser && currentUser.id && currentUser.id === this.state.id) {
      alert('Anda tidak dapat menghapus diri sendiri.');
      return;
    }
    if (!window.confirm('Apakah Anda yakin ingin menghapus item ini?')) {
      return;
    }
    userService
      .delete(this.state.id)
      .then(result => {
        this.setState({saveLoading: false, loading: true});
        this.closeModal();
        this.page(1);
      })
      .catch(err => {
        this.setState({saveLoading: false});
        errorService.handle(err);
      });
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
      id: '',
      emailAddress: '',
      organizationId: '',
      organizationName: '',
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      id: '',
      emailAddress: '',
      organizationId: '',
      organizationName: '',
    });
  };

  onItemClick = item => {
    let organizationId = '';
    let organizationName = '';
    for (let i in this.state.organizations) {
      if (item.organizationId === this.state.organizations[i].value) {
        organizationId = this.state.organizations[i].value;
        organizationName = this.state.organizations[i].text;
      }
    }
    this.setState({
      id: item.id,
      emailAddress: item.emailAddress,
      organizationId: organizationId,
      organizationName: organizationName,
      showModal: true,
    });
  };

  render() {
    return (
      <>
        <EuiPage className="class">
          <EuiPageBody
            style={{flexDirection: 'row'}}
            className={'content-container'}>
            {!this.state.loading && (
              <TablePage
                title={'Pengguna Administrator'}
                placeholder={'Cari'}
                buttonLabel={'Tambah'}
                buttonFunc={() =>
                  this.setState({
                    showModal: true,
                    id: '',
                    emailAddress: '',
                    organizationId: '',
                    organizationName: '',
                  })
                }
                data={this.state.data}
                column={this.state.column}
                disablePagination={this.state.loading}
                page={this.state.page}
                limit={this.state.limit}
                total={this.state.total}
                prev={this.prevPage}
                next={this.nextPage}
                toPage={this.page}
                onItemClick={this.onItemClick}
              />
            )}
          </EuiPageBody>
        </EuiPage>
        {this.state.showDeleteModal ? (
          <EuiOverlayMask>
            <EuiConfirmModal
              title="Hapus"
              onCancel={this.closeDeleteModal}
              onConfirm={this.closeDeleteModal}
              cancelButtonText="Batal"
              confirmButtonText="Hapus"
              buttonColor="danger"
              defaultFocusedButton="confirm">
              <p>Apakah anda ingin menghapus item ini ?</p>
            </EuiConfirmModal>
          </EuiOverlayMask>
        ) : null}
        {this.state.showModal ? (
          <EuiOverlayMask>
            <EuiModal
              onClose={() => this.closeModal()}
              initialFocus="[name=popswitch]">
              <EuiModalHeader>
                <EuiModalHeaderTitle>
                  Pengguna Administrator
                </EuiModalHeaderTitle>
              </EuiModalHeader>

              <EuiModalBody>
                <EuiForm>
                  <EuiFormRow
                    label="Alamat email"
                    isInvalid={
                      this.state.validationFields['emailAddress'].isInvalid
                    }
                    error={
                      this.state.validationFields['emailAddress'].isInvalid &&
                      this.state.validationFields['emailAddress'].errors
                    }>
                    <EuiFieldText
                      placeholder="Alamat email"
                      value={this.state.emailAddress}
                      name="emailAddress"
                      onChange={this.handleChange}
                      aria-label="Alamat email"
                    />
                  </EuiFormRow>
                  <EuiFormRow
                    label="Organisasi"
                    isInvalid={
                      this.state.validationFields['organizationId'].isInvalid
                    }
                    error={
                      this.state.validationFields['organizationId'].isInvalid &&
                      this.state.validationFields['organizationId'].errors
                    }>
                    <EuiSelect
                      placeholder="Organisasi"
                      options={this.state.organizations}
                      value={this.state.organizationId}
                      name="organizationName"
                      onChange={this.onOrganizationChange}
                      aria-label="Organisasi"
                    />
                  </EuiFormRow>
                  {!this.state.id && (
                    <EuiFormRow
                      label="Sandi"
                      isInvalid={
                        this.state.validationFields['password'].isInvalid
                      }
                      error={
                        this.state.validationFields['password'].isInvalid &&
                        this.state.validationFields['password'].errors
                      }>
                      <EuiFieldText
                        placeholder="Sandi"
                        value={this.state.password}
                        name="password"
                        type="password"
                        onChange={this.handleChange}
                        aria-label="Sandi"
                      />
                    </EuiFormRow>
                  )}
                  {!this.state.id && (
                    <EuiFormRow
                      label="Ulangi sandi"
                      isInvalid={
                        this.state.validationFields['repeatPassword'].isInvalid
                      }
                      error={
                        this.state.validationFields['repeatPassword']
                          .isInvalid &&
                        this.state.validationFields['repeatPassword'].errors
                      }>
                      <EuiFieldText
                        placeholder="Ulangi sandi"
                        value={this.state.repeatPassword}
                        name="repeatPassword"
                        type="password"
                        onChange={this.handleChange}
                        aria-label="Ulangi sandi"
                      />
                    </EuiFormRow>
                  )}
                </EuiForm>
              </EuiModalBody>

              <EuiModalFooter>
                <EuiButtonEmpty onClick={() => this.closeModal()}>
                  Batal
                </EuiButtonEmpty>
                {this.state.id && (
                  <EuiButton
                    color={'danger'}
                    onClick={() => this.delete()}
                    fill>
                    Hapus
                  </EuiButton>
                )}
                <EuiButton onClick={() => this.save()} fill>
                  Simpan
                </EuiButton>
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        ) : null}
      </>
    );
  }
}

export default User;
