import React, { Component } from "react";

import {
  EuiButton,
  EuiFlexItem,
  EuiPopover,
  EuiButtonEmpty,
  EuiFlexGroup,
} from "@elastic/eui";
import swal from "sweetalert";
import moment from "moment";

import Config from "../Config";
import { ChangeScheduleModal, ChangeCloseModal } from "../screens/Sessions";

class ActionPopover extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    isPopoverOpen: false,
  };
  render() {
    return (
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiPopover
            button={
              <EuiButton
                iconType="arrowDown"
                iconSide="right"
                onClick={() => {
                  this.setState({ isPopoverOpen: !this.state.isPopoverOpen });
                }}
              >
                {console.log(this.state.openedPopOver, this.props.id)}
                Aksi
              </EuiButton>
            }
            isOpen={this.state.isPopoverOpen}
            closePopover={() => this.setState({ isPopoverOpen: false })}
            anchorPosition="downCenter"
          >
            <EuiFlexGroup direction="column">
              <EuiFlexItem>
                <ChangeScheduleModal
                  sessionItem={this.props.sessionItem}
                  onScheduleChange={this.props.onScheduleChange}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                {this.props.sessionItem.status != 1 ? (
                  <EuiButton
                    onClick={() => {
                      return swal({
                        title: "",
                        type: "error",
                        text: "Mohon maaf, fitur ini tidak dapat digunakan pada temu-janji ini",
                      });
                    }}
                  >
                    Close
                  </EuiButton>
                ) : (
                  <ChangeCloseModal
                    sessionItem={this.props.sessionItem}
                    onScheduleChange={this.props.onScheduleChange}
                  />
                )}
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiButton onClick={this.props.openReceiptBlob}>
                  Lihat Kwitansi
                </EuiButton>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiButton onClick={this.props.checkPayment}>
                  Cek Pembayaran
                </EuiButton>
              </EuiFlexItem>
              {this.props.sessionItem.paymentURL !== "" ? (
                <EuiFlexItem>
                  <EuiButton
                    onClick={() =>
                      window.open(this.props.sessionItem.paymentURL)
                    }
                  >
                    URL Pembayaran
                  </EuiButton>
                </EuiFlexItem>
              ) : null}
              {!moment(this.props.sessionItem.scheduleDate).isBefore(
                moment().format("YYYY-MM-DD")
              ) &&
              Config.weblink !== "" &&
              this.props.sessionItem.status === 1 ? (
                <EuiFlexItem>
                  <EuiButton
                    onClick={() => {
                      window.open("/weblink-list/" + this.props.id);
                    }}
                  >
                    Siapdok Link
                  </EuiButton>
                </EuiFlexItem>
              ) : null}
            </EuiFlexGroup>
          </EuiPopover>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
}

export default ActionPopover;
