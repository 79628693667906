import Config from "../Config";
import axios from "axios";
import Error from "./Error";
import Misc from "./Misc";

const ErrorService = new Error();
const MiscService = new Misc();

class Organizations {
  getOrganizations = payload => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload.page = payload.page || 1;
      payload.limit = payload.limit || 10;
      payload.order = payload.Order || "ASC";
      payload.parentID = payload.parentID || "";
      payload.parentOnly = payload.parentOnly || "";
      let url = `${Config.apiHost}/api/organizations?page=${payload.page}&limit=${payload.limit}&order=${payload.order}&parentID=${payload.parentID}&parentOnly=${payload.parentOnly}`;
      if (payload.search && payload.search.length > 0) {
        url += `&search=${payload.search}`;
      }
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  };
  create = payload => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      let url = `${Config.apiHost}/api/organizations`;
      axios
        .post(url, payload, { headers: MiscService.generateHeaders() })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  };
  update = payload => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      let url = `${Config.apiHost}/api/organization/${payload.id}`;
      axios
        .put(url, payload, { headers: MiscService.generateHeaders() })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  };
  delete = id => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/organization/${id}`;
      axios
        .delete(url, { headers: MiscService.generateHeaders() })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
}

export default Organizations;
