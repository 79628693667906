import Config from '../Config';
import axios from 'axios';
import Error from './Error';
import Misc from './Misc';

const ErrorService = new Error();
const MiscService = new Misc();

class RegionCodes {
  getRegionCodes = payload => {
      return new Promise((resolve, reject) => {
      payload = payload || {};
      payload.limit = payload.limit || 10;
      payload.order = payload.Order || 'ASC';
      payload.search = payload.search || '';
      payload.parent = payload.parent || ''; 
      payload.page = payload.page || '';
        let url = `${Config.apiHost}/api/region-codes?page=${payload.page}&limit=${payload.limit}&order=${payload.order}&search=${payload.search}&parent=${payload.parent}`;
        axios
          .get(url, { headers: MiscService.generateHeaders() })
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            reject(err);
            ErrorService.handle(err);
          });
      });
    };
  
    getRegionCode = id => {
      return new Promise((resolve, reject) => {
        let url = `${Config.apiHost}/api/region-code/${id}`;
        axios.get(url, {headers: MiscService.generateHeaders() })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
      });
    };
  
    deleteRegionCode = id => {
      return new Promise((resolve, reject) => {
        let url = `${Config.apiHost}/api/region-code/${id}`;
        axios.get(url, {headers: MiscService.generateHeaders() })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
      });
    };
  
    updateRegionCode = payload => {
      return new Promise((resolve, reject) => {
        payload = payload || {};
        let url = `${Config.apiHost}/api/region-code/${payload.id}`;
        axios.put(url, payload, {headers: MiscService.generateHeaders() })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err)
        });
      });
    };
  
    create = payload => {
      return new Promise((resolve, reject) => {
        payload = payload || {};
        let url = `${Config.apiHost}/api/region-code`;
        axios.post(url, payload, {headers: MiscService.generateHeaders() })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err)
        });
      });
    };
}

export default RegionCodes;
