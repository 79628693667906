import Config from '../Config';
import axios from 'axios';
import Error from './Error';
import Misc from './Misc';

const ErrorService = new Error();
const MiscService = new Misc();

class Variants {
  getVariants = (payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiHost + '/api/v1/variants')
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export default Variants;
