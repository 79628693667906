import React, { Component } from "react";
import {
  EuiCard,
  EuiPage,
  EuiPageBody,
  EuiFlyout,
  EuiFlyoutHeader,
  EuiFlyoutBody,
  EuiLoadingSpinner,
  EuiTitle,
  EuiText,
  EuiButton,
} from "@elastic/eui";
import MonitoringService from "../services/Monitoring";
import TablePage from "../components/TablePage";
import * as $ from "jquery";
import {
  JitsiInit,
  JitsiConnect,
  Jitsi,
  JitsiDisconnect,
} from "../components/jitsi";

const monitoringService = new MonitoringService();

var interval;

class Monitoring extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jitsiReady: false,
      dataReady: false,
      showFlyout: false,
      tracks: [],
      participants: {},
      itemsTest: [
        {
          id: "12345",
          roomId: "abc123",
          clinicName: "Mata",
          patientName: "Robet",
          staffName: "dr. Stein",
        },
        {
          id: "12346",
          roomId: "abc124",
          clinicName: "Mata",
          patientName: "Tobet",
          staffName: "dr. Steiner",
        },
        {
          id: "12347",
          roomId: "abc125",
          clinicName: "Pediatrik",
          patientName: "Sobet",
          staffName: "dr. Steinberg",
        },
        {
          id: "12348",
          roomId: "abc126",
          clinicName: "Bersalin",
          patientName: "Dobet",
          staffName: "dr. Epstein",
        },
      ],
    };

    Jitsi.onRemoteTrack = () => {
      this.setState(
        {
          tracks: Jitsi.RemoteTracks,
        },
        this.updateTracks
      );
    };

    Jitsi.onRemoveTrack = () => {
      this.setState(
        {
          tracks: Jitsi.RemoteTracks,
        },
        this.updateTracks
      );
    };

    Jitsi.onJoined = () => {
      this.setState({
        isJoining: false,
      });
    };

    this.columns = [
      { field: "clinicName", name: "Nama Klinik", sortable: true },
      { field: "patientName", name: "Nama Pasien", sortable: true },
      { field: "staffName", name: "Nama Dokter", sortable: true },
    ];
  }

  updateTracks = () => {
    let participants = {};
    Object.keys(this.state.tracks).map((participantId) => {
      const tracks = this.state.tracks[participantId];
      tracks &&
        tracks.map &&
        tracks.map((track) => {
          const id =
            "#video-" +
            participantId +
            "-" +
            track.type +
            "-" +
            (track.videoType || "");
          const el = $(id);

          if (el && el[0] && track.containers && !track.containers[0]) {
            console.log(track, el);

            track.attach(el[0]);
          }

          track.conference &&
            track.conference.participants &&
            Object.keys(track.conference.participants).map((id, index) => {
              if (id === participantId) {
                participants[participantId] =
                  track.conference.participants[id]._displayName ||
                  "Partisipan " + index;
              }
            });
        });
    });
    this.setState({ participants });
  };

  componentDidMount = () => {
    this.fetch();
    JitsiInit()
      .then(() => {
        this.setState({
          jitsiReady: true,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  componentWillUnmount = () => {};

  fetch() {
    monitoringService
      .getSessions()
      .then((data) => {
        console.log(data, this.state);
        this.setState({
          items: data.Items,
          dataReady: true,
        });
      })
      .catch((e) => {
        this.setState({
          dataReady: true,
        });
      });
  }

  render() {
    return (
      <>
        <EuiPage className="class">
          <EuiPageBody
            style={{ flexDirection: "row", textAlign: "center", width: "100%" }}
            className={"content-container"}
          >
            <TablePage
              title="Pemantauan"
              loading={!(this.state.jitsiReady && this.state.dataReady)}
              data={this.state.items}
              column={this.columns}
              disablePagination={true}
              onItemClick={(item) => {
                Jitsi.RoomName = item.roomId;
                this.setState({
                  item,
                  showFlyout: true,
                  isJoining: true,
                  participants: {},
                  tracks: {},
                });
                JitsiConnect();
              }}
            ></TablePage>
          </EuiPageBody>
        </EuiPage>
        {this.state.showFlyout && (
          <EuiFlyout
            ownFocus={true}
            hideCloseButton={false}
            size="l"
            onClose={() => {
              JitsiDisconnect();
              this.setState({
                showFlyout: false,
              });
            }}
          >
            <EuiFlyoutHeader hasBorder={true}>
              <EuiTitle size="m">
                <h2>Klinik {this.state.item.clinicName}</h2>
              </EuiTitle>
              <EuiText textAlign={"left"}>
                <p>
                  <strong>Nama Dokter</strong>: {this.state.item.staffName}
                </p>
                <p>
                  <strong>Nama Pasien</strong>:{" "}
                  {this.state.item.patientName}
                </p>
              </EuiText>
            </EuiFlyoutHeader>

            <EuiFlyoutBody>
              {this.state.isJoining && (
                <>
                  <EuiText>Mencoba masuk ruangan...</EuiText>
                  <EuiLoadingSpinner size="m" />
                </>
              )}
              {!this.state.isJoining && (
                <EuiText>
                  Jumlah partisipan saat ini:{" "}
                  {Object.keys(this.state.participants).length || 0}
                </EuiText>
              )}
              {Object.keys(this.state.tracks).map((participantId) => {
                if (this.state.participants[participantId]) {
                  const tracks = this.state.tracks[participantId];

                  return (
                    <EuiCard
                      textAlign="left"
                      title={this.state.participants[participantId]}
                    >
                      {tracks.map &&
                        tracks.map((track, index) => {
                          if (
                            track.type === "video" &&
                            track.track &&
                            track.track.readyState === "live"
                          ) {
                            const id =
                              "video-" +
                              participantId +
                              "-" +
                              track.type +
                              "-" +
                              (track.videoType || "");

                            return (
                              <video
                                style={{ maxWidth: "200px" }}
                                autoPlay="1"
                                id={id}
                              />
                            );
                          }
                        })}
                    </EuiCard>
                  );
                }
              })}
            </EuiFlyoutBody>
          </EuiFlyout>
        )}
      </>
    );
  }
}

export default Monitoring;
