import React, { Component } from "react";
import { EuiBasicTable, EuiText, EuiButtonIcon, EuiHealth, EuiDescriptionList } from "@elastic/eui";

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageIndex: 0,
      pageSize: 10,
      itemIdToExpandedRowMap: {},
      data: []
    };
  }


  getRowProps = item => {
    if (this.props.onItemClick) {
      return {
        onClick: () => {
          this.props.onItemClick(item);
        }
      };
    }
    return {};
  };

  toggleDetails = (item) => {
    this.props.onExpandedRow(item).then(x => {
      const itemIdToExpandedRowMapValues = { ...this.state.itemIdToExpandedRowMap };
      if (itemIdToExpandedRowMapValues[this.props.data.findIndex(x => x.id == item.id)]) {
        delete itemIdToExpandedRowMapValues[this.props.data.findIndex(x => x.id == item.id)];
      } else {
        const listItems = x ? x : [];
        itemIdToExpandedRowMapValues[this.props.data.findIndex(x => x.id == item.id)] = (
          <EuiDescriptionList listItems={listItems} />
        );
      }
      this.setState({
        itemIdToExpandedRowMap: itemIdToExpandedRowMapValues
      });
    })
  };

  componentDidMount = () => {
    this.setState({
      data: this.props.data
    })
  }

  componentDidUpdate = () => {
    if (this.state.data !== this.props.data) {
      this.setState({
        itemIdToExpandedRowMap: {},
        data: this.props.data
      });
    }
  }


  render() {
    const { pageIndex, pageSize } = this.state;
    const columns = this.props.columns && this.props.detailToggle ? [...this.props.columns, {
      field: 'detail',
      name: 'Detail',
      width: '20%',
      render: (id, sessionItem) => (
        <>
          <EuiButtonIcon
            onClick={() => this.toggleDetails(sessionItem)}
            aria-label={this.state.itemIdToExpandedRowMap[this.state.data.findIndex(x => x.id == sessionItem.id)] ? 'Collapse' : 'Expand'}
            iconType={this.state.itemIdToExpandedRowMap[this.state.data.findIndex(x => x.id == sessionItem.id)] ? 'arrowUp' : 'arrowDown'}
          />
        </>
      ),
      sortable: false,
    }] : this.props.columns;
    const sort = this.props.sort ? this.props.sort : {};
    const change = this.props.change ? this.props.change : {};

    if (this.state.itemIdToExpandedRowMap) {
      return (
        <EuiBasicTable
          itemIdToExpandedRowMap={this.state.itemIdToExpandedRowMap}
          items={this.state.data}
          noItemsMessage={'Belum ada data.'}
          columns={columns}
          rowProps={this.getRowProps}
          sorting={sort}
          onChange={change}
        />
      );
    } else {
      return (
        <EuiBasicTable
          items={this.state.data}
          noItemsMessage={'Belum ada data.'}
          columns={columns}
          rowProps={this.getRowProps}
          sorting={sort}
          onChange={change}
        />
      );
    }
  }
}

export default Table;
