import Config from "../Config";
import axios from "axios";
import Misc from "./Misc";

const MiscService = new Misc();

class Weblink {
  listWeblink = (payload) => {
    return new Promise((resolve, reject) => {
      axios.get(Config.apiHost + "/api/session/weblink/" + payload.Id, { headers: MiscService.generateHeaders() })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }

  createWeblink = (payload) => {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + "/api/session/weblink", payload, { headers: MiscService.generateHeaders() }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }

  makeUserCode = (data) => {
    let output = "";
    switch (data) {
      case "PATIENT":
        output = "00";
      break;
      case "DOCTOR":
        output = "01";
      break;
      case "ASSISTANT":
        output = "02";
      break;
      default:
        output = "";
      break;
    }
    return output;
  }
}

export default Weblink;