import Config from "../Config";
import axios from "axios";
import Error from "./Error";
import Misc from "./Misc";

const ErrorService = new Error();
const MiscService = new Misc();

class Registrations {
  getRegistrations = (id) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/reg-extra-data/${id}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  create = payload => {
    console.log(payload)
    return new Promise((resolve, reject) => {
      payload = payload || {};
      let url = `${Config.apiHost}/api/reg-extra-data`;
      axios
        .post(url, payload, { headers: MiscService.generateHeaders() })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  update = payload => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      let url = `${Config.apiHost}/api/reg-extra-data/${payload.id}`;
      axios
        .put(url, payload, { headers: MiscService.generateHeaders() })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  delete = id => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/reg-extra-data/${id}`;
      axios
        .delete(url, { headers: MiscService.generateHeaders() })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  }
  getExtraData = (id) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/extra-data/${id}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
}

export default Registrations;
