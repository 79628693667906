import React from 'react';
import {Route, Redirect} from 'react-router-dom';

function PrivateRoute({component: Component, ...rest}) {
  let isLoggedIn = false
  let token = localStorage.getItem('token');
  if (token && token.length > 0) {
    isLoggedIn = true
  }
  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? <Component {...props} {...rest} /> : <Redirect to="/login" />
      }
    />
  );
}

export default PrivateRoute;
