import Config from '../Config';
import axios from 'axios';
import Error from './Error';
import Misc from './Misc';

const ErrorService = new Error();
const MiscService = new Misc();

class Blob {
  uploadFile = (file) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/blob/upload`;
      const formData = new FormData();
      formData.append('file', file);
      axios
        .post(url, formData, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getBlob = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiHost + '/api/blob/' + id, {
          headers: MiscService.generateHeaders(),
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
}

export default Blob;
