import React, { Component } from 'react';

import {
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiTitle,
  EuiLoadingSpinner,
} from '@elastic/eui';

import SuperSet from '../services/Superset';

import Config from '../Config';

const SuperSetService = new SuperSet();

class Superset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      supersetSrc: '',
      loadFailed: false,
    };
  }

  componentDidMount = () => {
    SuperSetService.auth(Config.variant)
      .then((v) => {
        this.setState({
          supersetSrc: `${Config.supersetBaseUrl}/login/?token=${v.access_token}&next=${this.props.supersetPath}`,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loadFailed: true });
      })
      .finally(() => this.setState({ loading: false }));
  };

  renderFrame = () => {
    if (this.state.loading) {
      return (
        <EuiPageContent
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <EuiLoadingSpinner
            style={{
              alignItems: 'center',
              justifyContent: 'center',
            }}
            size='xl'
          />
        </EuiPageContent>
      );
    }

    return (
      <EuiPageContent
        className='card-badge'
        style={{ margin: 20, flexDirection: 'column' }}
        horizontalPosition='left'
        verticalPosition='center'
        betaBadgeLabel={this.props.supersetName}
      >
        {this.state.loadFailed ? (
          <EuiPageContent
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '75vh',
            }}
          >
            <EuiTitle size='m'>
              <h4>
                Gagal memuat superset.
              </h4>
            </EuiTitle>
          </EuiPageContent>
        ) : (
          <iframe
            id={this.props.supersetName}
            title={this.props.supersetName}
            style={{
              marginTop: 30,
              width: '100%',
              height: '80vh',
            }}
            seamless
            frameBorder='0'
            scrolling='no'
            src={this.state.supersetSrc}
          ></iframe>
        )}
      </EuiPageContent>
    );
  };

  render() {
    return (
      <>
        <EuiPage className='class'>
          <EuiPageBody
            style={{ alignItems: 'center' }}
            className={'content-container'}
          >
            {this.renderFrame()}
          </EuiPageBody>
        </EuiPage>
      </>
    );
  }
}

export default Superset;
