import React, { Component } from 'react';
import {
  EuiPage,
  EuiPageBody,
  EuiFilePicker,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPageContent,
  EuiPageContentHeader,
  EuiTitle,
  EuiPageContentBody,
  EuiText,
  EuiSpacer,
  EuiForm,
  EuiButton,
  EuiSelect,
  EuiFormRow,
  EuiSwitch
} from '@elastic/eui';
import Staff from '../services/Staffs';
import swal from 'sweetalert';
import Organizations from '../services/Organizations';

const staffServices = new Staff();
const organizationServices = new Organizations();

class StaffImport extends Component {

  constructor(props) {
    super(props);
    this.state = {
      file: {},
      selectedOrganizationId: '',
      organizations: []
    }
  }

  componentDidMount = () => {
    this.getOrganizations()
  }

  getOrganizations = () => {
    organizationServices.getOrganizations()
      .then((response) => {
        let items = [];
        
        response.items.forEach(item => {
          items.push({ value: item.id, text: item.name })
        });

        this.setState({
          organizations: items,
          selectedOrganizationId: items[0].value
        });
      })
      .catch((err) => {
        console.log(err)
      })
  }

  onChangeFile = (file) => {
    this.setState({ file })
  }

  onChangeOrganization = (event) => {
    this.setState({
      selectedOrganizationId: event.target.value
    });
  }

  submitFile = () => {
    const {selectedOrganizationId, file} = this.state;
    staffServices.import({ organizationId: selectedOrganizationId, file })
      .then(response => {
        swal({
          title: '',
          type: 'success',
          text: 'Data staff berhasil diimpor',
        })
        this.setState({
          file: {},
          selectedOrganizationId: '',
          organizations: []
        })
      })
      .catch(err => {
        console.log(err)
        if (err.response) {
          swal({
            title: '',
            type: 'success',
            text: err.response.data.message,
          })
        }
      })

  }

  render = () => {
    const { organizations, selectedOrganizationId } = this.state;

    return (
      <>
        <EuiPage className="class content-container">
          <EuiPageContent>
            <EuiPageContentHeader>
              <EuiTitle>
                <h2>Import Staff</h2>
              </EuiTitle>
            </EuiPageContentHeader>
            <EuiPageContentBody>
              <EuiForm className="form-layout">
                <EuiFlexGroup>
                  <EuiFlexItem grow={2}>
                    <EuiFormRow
                      label="Organisasi">
                      <EuiSelect
                        options={organizations}
                        value={selectedOrganizationId}
                        onChange={event => this.onChangeOrganization(event)}
                        aria-label="Use aria labels when no actual label is in use"
                      />
                    </EuiFormRow>
                    <h5 style={{ margin: 12 }}>Pilih File CSV</h5>
                    <EuiFilePicker
                      multiple
                      initialPromptText="Pilih atau seret dan lepas berkas"
                      onChange={file => {
                        this.onChangeFile(file)
                      }}
                      display="large"
                      aria-label="Use aria labels when no actual label is in use"
                    />
                    <EuiButton style={{ marginTop: 12 }} fill onClick={() => this.submitFile()}>
                      Proses
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiForm>
            </EuiPageContentBody>
          </EuiPageContent>
        </EuiPage>
      </>
    )
  }
}

export default StaffImport