import React, { Component } from "react";

import {
    EuiPage,
    EuiPageBody,
    EuiPageContent,
    EuiButtonEmpty,
    EuiFieldText, EuiTitle
} from "@elastic/eui";

class MyFrame extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 400,
            height: 400,
            src: ""
        };
    }

    render() {
        return (
            <>
                <EuiPageContent
                    className={this.props.className}
                    style={{ margin: 20, flexDirection: "column" }}
                    width={this.props.width}
                    height={this.props.height}
                    horizontalPosition={this.props.horizontalPosition ?? "center"}
                    verticalPosition="center"
                    betaBadgeLabel={this.props.betaBadgeLabel}

                >
                    {this.props.title ? <EuiTitle size="m" style={{ float: "left" }}>
                        <h4>{this.props.title}</h4>
                    </EuiTitle> : <></>}
                    <iframe
                        style={{ marginTop: this.props.betaBadgeLabel ? 0 : 30 }}
                        width={this.props.width}
                        height={this.props.height}
                        seamless
                        frameBorder="0"
                        scrolling="no"
                        src={this.props.src}
                    >
                    </iframe>
                </EuiPageContent>
            </>
        )
    }
}
export default MyFrame;