import Config from '../Config';
import axios from 'axios';
import Error from './Error';
import Misc from './Misc';
import moment from "moment";

const ErrorService = new Error();
const MiscService = new Misc();

class CheckEventError {
  getAllCheckEventError = payload => {
    return new Promise((resolve, reject) => {
    payload = payload || {};
    payload.limit = payload.limit || 10;
    payload.order = payload.Order || 'DESC';
    payload.search = payload.search || '';
    payload.page = payload.page || '';
    payload.startDate = payload.startDate || '';
    payload.endDate = payload.endDate || '';
      let url = `${Config.apiHost}/api/events/error?page=${payload.page}&limit=${payload.limit}&order=${payload.order}`;

      if (payload.search !== '') {
        url += `&code=${payload.search}`;
      }

      if (payload.startDate !== '' && payload.endDate !== '') {
        url += `&start_date=${payload.startDate}&end_date=${payload.endDate}`;
      }
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then(response => {
          for (let i in response.data.list) {
            response.data.list[i].activation_time = moment.unix(response.data.list[i].scheduled_time).format('YYYY-MM-DD HH:mm:ss'); // this.unixTime(response.data.list[i].scheduled_time);
          }
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  
  getCheckEventErrorById = id => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/events/error/${id}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
      });
  };
  
  deleteCheckEventErrorById = id => {
    console.log(id)
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/events/error/${id}`;
      axios
        .delete(url, { headers: MiscService.generateHeaders() })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
      });
  };
}

export default CheckEventError;
