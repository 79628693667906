/* eslint-disable no-undef */
import * as $ from 'jquery';

const Jitsi = {
  Name: "Monitor-1",
  Connection: undefined,
  RoomName: "x",
  RemoteTracks: {},
};

const options = {
  hosts: {
    domain: process.env["REACT_APP_JITSI_DOMAIN"],
    muc: process.env["REACT_APP_MUC_NAME"],
  },
  bosh: process.env["REACT_APP_BOSH_URL"],
  clientNode: "http://jitsi.org/jitsimeet",
};

function onTrackAdded(track) {
  console.log("remote received");

  if (track.isLocal()) {
    return;
  }
  const participant = track.getParticipantId();

  if (!Jitsi.RemoteTracks[participant]) {
    Jitsi.RemoteTracks[participant] = [];
  }
  const idx = Jitsi.RemoteTracks[participant].push(track);

  track.addEventListener(
    JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
    (audioLevel) => console.log(`Audio Level remote: ${audioLevel}`)
  );
  track.addEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, () =>
    console.log("remote track muted")
  );
  track.addEventListener(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, () =>
    console.log("remote track stoped")
  );

  if (Jitsi.onRemoteTrack) {
    Jitsi.onRemoteTrack(track);
  }
  console.log(track);
}

function onTrackRemoved(track) {
  console.log("removed");
  const participant = track.getParticipantId();

  let toBeRemoved = -1
  Jitsi.RemoteTracks[participant] && Jitsi.RemoteTracks[participant].map((t, index) => {
    if(toBeRemoved === -1 && t.track && t.track.id && track.track && track.track.id === t.track.id) {
        toBeRemoved = index;
    }
  })
  if (toBeRemoved !== -1) {
    Jitsi.RemoteTracks[participant] = Jitsi.RemoteTracks[participant].splice(toBeRemoved, 1)
  }
  if (Jitsi.onRemoveTrack) {
    Jitsi.onRemoveTrack(track);
  }
}

function onConferenceJoined() {
  console.log("conference joined!");
  Jitsi.IsJoined = true;
  if (Jitsi.onJoined) {
    Jitsi.onJoined();
  }
}

function unload() {
  if (Jitsi.Room && Jitsi.IsJoined) {
    Jitsi.Room.leave();
  }
  if (Jitsi.Connection) {
    Jitsi.Connection.disconnect();
  }
}

function onUserLeft(id) {
  console.log("user left");
  if (!Jitsi.RemoteTracks[id]) {
    return;
  }
  const tracks = Jitsi.RemoteTracks[id];

  for (let i = 0; i < tracks.length; i++) {
    for (let j = 0; j < tracks[i].containers.length; j++) {
      const container = tracks[i].containers[j];
      console.log(container);
    }
  }
}

const confOptions = {
  openBridgeChannel: true,
};

function onConnectionSuccess() {
  console.log("connecting");
  Jitsi.IsJoined = false;

  Jitsi.Room = undefined;

  const room = Jitsi.Connection.initJitsiConference(
    Jitsi.RoomName,
    confOptions
  );
  room.on(JitsiMeetJS.events.conference.TRACK_ADDED, onTrackAdded);
  room.on(JitsiMeetJS.events.conference.TRACK_REMOVED, onTrackRemoved);

  room.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, onConferenceJoined);
  room.on(JitsiMeetJS.events.conference.USER_JOINED, (id) => {
    console.log("user join");
    Jitsi.RemoteTracks[id] = [];
  });
  room.on(JitsiMeetJS.events.conference.USER_LEFT, onUserLeft);
  room.on(JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED, (track) => {
    console.log(`${track.getType()} - ${track.isMuted()}`);
  });
  room.on(
    JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED,
    (userID, displayName) => console.log(`${userID} - ${displayName}`)
  );
  room.on(
    JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED,
    (userID, audioLevel) => console.log(`${userID} - ${audioLevel}`)
  );

  room.setDisplayName(Jitsi.Name);
  room.join();
  Jitsi.Room = room;
}

function JitsiDisconnect() {
  if (Jitsi.Roomunit && Jitsi.IsJoined) {
    Jitsi.Room.leave();
  }
  console.log("disconnect!");
  Jitsi.Connection.removeEventListener(
    JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
    onConnectionSuccess
  );
  Jitsi.Connection.removeEventListener(
    JitsiMeetJS.events.connection.CONNECTION_FAILED,
    onConnectionFailed
  );
  Jitsi.Connection.removeEventListener(
    JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
    JitsiDisconnect
  );
}

function onConnectionFailed() {
  console.error("Connection Failed!");
}

const JitsiInit = (cb) => {
  return new Promise((resolve, reject) => {
    try {
      console.log("Init jitsi");
      JitsiMeetJS.init({
        disableAudioLevels: true,
      });

      JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.DEBUG);

      resolve();
    } catch (e) {
      reject(e);
    }
  });
};

const JitsiConnect = (cb) => {
  const connection = new JitsiMeetJS.JitsiConnection(null, null, options);

  connection.addEventListener(
    JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
    onConnectionSuccess
  );
  connection.addEventListener(
    JitsiMeetJS.events.connection.CONNECTION_FAILED,
    onConnectionFailed
  );
  connection.addEventListener(
    JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
    JitsiDisconnect
  );

  Jitsi.Connection = connection;
  //Jitsi.Connection.connect({ id:'recorder', password:'2f85733b-9f92-4808-a36c-7d090320b17c'});
  Jitsi.Connection.connect();
  console.log("xxx", connection);
  if (cb) {
    cb();
  }
};

export { JitsiInit, Jitsi, JitsiConnect, JitsiDisconnect };

$(window).bind("beforeunload", unload);
$(window).bind("unload", unload);
