import React, { Component } from 'react';

import {
  EuiPage,
  EuiPageBody,
  EuiButton,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiOverlayMask,
  EuiButtonEmpty,
  EuiConfirmModal,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiSelect,
} from '@elastic/eui';
import TablePage from '../components/TablePage';
import PharmacyService from '../services/Pharmacies';
import ErrorService from '../services/Error.js';
import Utils from '../Utils';

const pharmacyService = new PharmacyService();
const errorService = new ErrorService();

class PharmacyGroups extends Component {
  state = {
    page: 1,
    limit: 10,
    order: 'ASC',
    search: '',
    loading: false,
    orderBy: 'name',
    data: [],
    column: [
      {
        field: 'name',
        name: 'Name',
      },
    ],

    // Form
    name: '',
    validationFields: {
      name: {
        type: 'string',
        isInvalid: false,
        errors: ['Nama tidak boleh kosong'],
        isValidFunc: function (value) {
          return value && value.length > 0;
        },
      },
    },
  };

  componentDidMount = () => {
    this.page(1);
  };

  handleChange = (e) => {
    let value = e.target.value || null;
    let obj = {};
    obj[e.target.name] = value;
    obj['failedAttempt'] = false;
    let validationFields = { ...this.state.validationFields };
    if (validationFields[e.target.name]) {
      validationFields[e.target.name].isInvalid = false;
      obj.validationFields = validationFields;
    }
    this.setState(obj);
  };

  page = (page) => {
    page = page || 1;
    this.setState({ loading: true, search: '' });
    let payload = {
      page: page,
      limit: this.state.limit,
      search: this.state.search,
      order: this.state.order,
      orderBy: this.state.orderBy,
    };
    pharmacyService
      .getPharmacyGroups(payload)
      .then((result) => {
        console.log(result.items);
        this.setState(
          {
            page: result.page,
            limit: result.limit,
            total: result.total,
            order: result.order,
            orderBy: result.orderBy,
            data: result.items,
            loading: false,
          },
          () => {
            this.forceUpdate();
          }
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
          data: [],
        });
      });
  };

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };

  add = () => {
    this.setState({ id: '', name: '', showModal: true });
  };

  show = (item) => {
    this.setState({ id: item.id, name: item.name, showModal: true });
  };

  save = () => {
    let state = { ...this.state };
    this.setState({ saveLoading: true }, () => {
      Utils.validateFields(state).then((result) => {
        this.setState({ validationFields: result.validationFields });
        this.forceUpdate();
        if (!result.isValid) {
          return;
        }
        if (this.state.id && this.state.id.length > 0) {
          // Update existing
          pharmacyService
            .updatePharmacyGroup({
              name: this.state.name,
              id: this.state.id,
            })
            .then((result) => {
              this.setState({ saveLoading: false, loading: true });
              this.closeModal();
              this.page(1);
            })
            .catch((err) => {
              this.setState({ saveLoading: false });
              errorService.handle(err);
            });
        } else {
          // Create new one
          pharmacyService
            .createPharmacyGroup({ name: this.state.name })
            .then((result) => {
              this.setState({ saveLoading: false, loading: true });
              this.closeModal();
              this.page(1);
            })
            .catch((err) => {
              this.setState({ saveLoading: false });
              errorService.handle(err);
            });
        }
      });
    });
  };

  delete = () => {
    if (!(this.state.id && this.state.id.length > 0)) {
      return;
    }
    if (!window.confirm('Apakah Anda yakin ingin menghapus item ini?')) {
      return;
    }
    pharmacyService
      .deletePharmacyGroup(this.state.id)
      .then((result) => {
        this.setState({ saveLoading: false, loading: true });
        this.closeModal();
        this.page(1);
      })
      .catch((err) => {
        this.setState({ saveLoading: false });
        errorService.handle(err);
      });
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
      id: '',
      name: '',
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      id: '',
      name: '',
    });
  };

  onItemClick = (item) => {
    this.setState({
      id: item.id,
      name: item.name,
      showModal: true,
    });
  };

  render() {
    return (
      <>
        <EuiPage className="class">
          <EuiPageBody
            style={{ flexDirection: 'row' }}
            className={'content-container'}
          >
            {!this.state.loading && (
              <TablePage
                title={'Grup Apotek'}
                placeholder={'Cari'}
                buttonLabel={'Tambah'}
                buttonFunc={() =>
                  this.setState({ showModal: true, id: '', name: '' })
                }
                data={this.state.data}
                column={this.state.column}
                disablePagination={true}
                page={this.state.page}
                limit={this.state.limit}
                total={this.state.total}
                prev={this.prevPage}
                next={this.nextPage}
                toPage={this.page}
                onItemClick={this.onItemClick}
              />
            )}
          </EuiPageBody>
        </EuiPage>
        {this.state.showDeleteModal ? (
          <EuiOverlayMask>
            <EuiConfirmModal
              title="Hapus"
              onCancel={this.closeDeleteModal}
              onConfirm={this.closeDeleteModal}
              cancelButtonText="Batal"
              confirmButtonText="Hapus"
              buttonColor="danger"
              defaultFocusedButton="confirm"
            >
              <p>Apakah anda ingin menghapus item ini ?</p>
            </EuiConfirmModal>
          </EuiOverlayMask>
        ) : null}
        {this.state.showModal && (
          <EuiOverlayMask>
            <EuiModal
              onClose={() => this.closeModal()}
              initialFocus="[name=popswitch]"
            >
              <EuiModalHeader>
                <EuiModalHeaderTitle>Group Apotek</EuiModalHeaderTitle>
              </EuiModalHeader>

              <EuiModalBody>
                <EuiForm>
                  <EuiFormRow
                    label="Nama"
                    isInvalid={this.state.validationFields['name'].isInvalid}
                    error={
                      this.state.validationFields['name'].isInvalid &&
                      this.state.validationFields['name'].errors
                    }
                  >
                    <EuiFieldText
                      placeholder="Nama"
                      value={this.state.name}
                      name="name"
                      onChange={this.handleChange}
                      aria-label="Nama"
                    />
                  </EuiFormRow>
                </EuiForm>
              </EuiModalBody>

              <EuiModalFooter>
                <EuiButtonEmpty onClick={() => this.closeModal()}>
                  Batal
                </EuiButtonEmpty>
                {this.state.id && (
                  <EuiButton
                    color={'danger'}
                    onClick={() => this.delete()}
                    fill
                  >
                    Hapus
                  </EuiButton>
                )}
                <EuiButton onClick={() => this.save()} fill>
                  Simpan
                </EuiButton>
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        )}
      </>
    );
  }
}

export default PharmacyGroups;
