import React, { Component } from "react";
import { 
  EuiPage, 
  EuiPageBody,
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiFieldText,
  EuiModalFooter,
  EuiButton,
  EuiButtonEmpty,
  EuiFlexItem,
  EuiFlexGroup,
} from "@elastic/eui";
import moment from 'moment';
import TablePage from "../components/TablePage";
import CheckEventErrorService from '../services/CheckEventError';
import { debounce } from 'lodash';

const ceeService = new CheckEventErrorService();

class CheckEventError extends Component {
  state = {
    page: 1,
    limit: 10,
    total: 0,
    order: 'DESC',
    orderBy: 'timestamp',
    loading: false,
    updateLoading: false,
    deleteLoading: false,
    startDate: moment(),
    endDate: moment(),
    modalSearchDate: false,
    useSearchDate: false,
    isAction: false,
    data: [],
    column: [
      {
        field: "activation_time",
        name: "Tanggal"
      },
      {
        field: "code",
        name: "Kode"
      },
      {
        field: "attempt",
        name: "Perulangan"
      },
      {
        field: "result_log",
        name: "Result Log"
      },
      {
        field: "",
        name: "Aksi",
        render: () => (
          <div id={'action-id'}>
            <EuiButtonEmpty onClick={this.showModal} size="xs">
              Hapus
            </EuiButtonEmpty>
          </div>
        ),
      },
    ],
    
    selectedItem: {
      id: "",
      activation_time: "",
      scheduled_time: "",
      code: "",
      attempt: "",
    },
  }

  page = page => {
    page = page || 1;
    this.setState({loading: true});
    let payload = {
      page: page,
      limit: this.state.limit,
      search: this.state.search,
      order: this.state.order,
      orderBy: this.state.orderBy,
    };

    if (this.state.useSearchDate) {
      payload.startDate = this.state.startDate.format('YYYY-MM-DD');
      payload.endDate = this.state.endDate.format('YYYY-MM-DD');
    }

    var result
    ceeService.getAllCheckEventError(payload)
      .then(response => {
        result = response
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          order: result.order,
          orderBy: result.order_by,
          data: result.list || [],
          loading: false,
          modalSearchDate: false
        },() => { this.forceUpdate(); });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loading: false,
          data: [],
          modalSearchDate: false
        });
      });
  };

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };

  componentDidMount = () => {
    this.page(1);
    this.setState({isAction:true})
  };

  showModal = () => {
    this.setState({modal: true});
  }

  onItemClick = (item) => {
    this.setState({
      selectedItem: {
        id: item.id,
        activation_time: item.activation_time,
        scheduled_time: item.scheduled_time,
        code: item.code,
        attempt: item.attempt,
        result_log: item.result_log,
      }
    });
  }

  buttonAction = (params) => {
    switch (params.action) {
      case 'retry':
        console.log(`retry`)
        break;
      default:
        console.log(`default-delete:${params.payload.id}`);
        ceeService.deleteCheckEventErrorById(params.payload.id).then(res => {
          console.log(`${res}`);
          this.setState({deleteLoading: false, updateLoading: false, modal: false})
          this.handleOnSearch()
        }).catch(err => {
          console.log(err);
        })
      break;
    }
  }

  handleOnSearch = (value, useSearchDate = false) => {
    if (!useSearchDate) {
      this.setState({
        startDate: moment(),
        endDate: moment()
      })
    }
    this.setState({ search: value, loading: true, useSearchDate: useSearchDate });
    this.handleSearchDebounce();
  };

  handleDateChangeStart = (date) => {
    // in moment object format
    this.setState({ startDate: date });
  };

  handleDateChangeEnd = (date) => {
    // in moment object format
    this.setState({ endDate: date });
  };

  handleSearchDebounce = debounce(() => {
    this.page(1)
  }, 2000);

  render() {
      return (
        <>
        {this.state.modal && (
          <EuiOverlayMask>
            <EuiModal
              onClose={() => {this.setState({ modal: false })}}
              initialFocus="[name=popswitch]"
            >
              <EuiModalHeader>
                <EuiModalHeaderTitle>
                  Detail Periksa Error
                </EuiModalHeaderTitle>
              </EuiModalHeader>

              <EuiModalBody>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    Tanggal
                  </EuiFlexItem>
                  <EuiFlexItem>
                    {this.state.selectedItem.activation_time}
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    Kode
                  </EuiFlexItem>
                  <EuiFlexItem>
                    {this.state.selectedItem.code}
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    Perulangan
                  </EuiFlexItem>
                  <EuiFlexItem>
                    {this.state.selectedItem.attempt}
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    Result Log
                  </EuiFlexItem>
                  <EuiFlexItem>
                    {this.state.selectedItem.result_log}
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiModalBody>
              <EuiModalFooter>
                <EuiButton fill color={"danger"} onClick={() => this.buttonAction({action: 'delete', payload: this.state.selectedItem})} disabled={this.state.updateLoading} isLoading={this.state.deleteLoading}>
                Hapus
                </EuiButton>
                <EuiButton fill onClick={() => this.setState({ modal: false, })} >
                Tutup
                </EuiButton>
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        )}
        <EuiPage className="class">
          <EuiPageBody
            style={{ flexDirection: "row" }}
            className={"content-container"}
          >  
            <TablePage
              title={"Periksa Error"}
              placeholder={"Periksa Error"}
              data={this.state.data}
              column={this.state.column}
              disablePagination={this.state.loading}
              page={this.state.page}
              limit={this.state.limit}
              total={this.state.total}
              prev={this.prevPage}
              next={this.nextPage}
              toPage={this.page}
              onItemClick={this.onItemClick}
              loading={this.state.loading}
              searchComp={
                <>
                  <EuiFlexGroup style={{ marginRight: 10 }}>
                    <EuiFlexItem>
                      <EuiFieldText
                        onKeyDown={e => {
                          if (e.keyCode === 13) {
                            this.handleOnSearch(this.state.search);
                          }
                        }}
                        value={this.state.search}
                        onChange={e => this.handleOnSearch(e.target.value)}
                        placeholder={'Search...'}
                        append={
                          <EuiButtonEmpty
                            onClick={() => this.handleOnSearch(this.state.search)}>
                            Cari
                          </EuiButtonEmpty>
                        }
                        />
                      </EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiFlexGroup>
                    <EuiFlexItem>
                        <EuiButton onClick={() => {
                          this.setState({
                            startDate: moment(),
                            endDate: moment(),
                            search: '',
                          }, () => this.handleOnSearch(this.state.search))
                        }}>
                          Reset pencarian
                        </EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </>
              }
            />
          </EuiPageBody>
        </EuiPage>
      </>
    )
  }
}

export default CheckEventError;
