import Config from "../Config";
import axios from "axios";
import Error from './Error';
import Misc from "./Misc";

const ErrorService = new Error();
const MiscService = new Misc();

class SettingServices {
    getMaintenanceMode = () => {
        return new Promise((resolve, reject) => {
            let url = `${Config.apiHost}/api/maintenance-mode`
            axios.get(url, { headers: MiscService.generateHeaders() })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((err) => {
                    reject(err)
                    ErrorService.handle(err)
                })
        });
    }

    setMaintenanceStatus = (status) => {
        return new Promise((resolve, reject) => {
            let url = `${Config.apiHost}/api/maintenance-mode`
            axios.put(url, {
                key: 'MAINTENANCE_MODE',
                value: `${status}`
            }, { headers: MiscService.generateHeaders() })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((err) => {
                    reject(err)
                    ErrorService.handle(err)
                })
        });
    }

    getAppSettings = (key) => {
        return new Promise((resolve, reject) => {
            let url = `${Config.apiHost}/api/app-settings/${key}`
            axios.get(url, { headers: MiscService.generateHeaders() })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((err) => {
                    reject(err)
                })
        });
    }

}

export default SettingServices;
