import React, {Component} from 'react';

import {
  EuiPage,
  EuiPageBody,
  EuiButton,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiOverlayMask,
  EuiButtonEmpty,
  EuiConfirmModal,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiSelect,
} from '@elastic/eui';
import TablePage from '../components/TablePage';
import MedicineService from '../services/Medicines';
import ErrorService from '../services/Error.js';
import Utils from '../Utils';

const medicineService = new MedicineService();
const errorService = new ErrorService();

class MedicineDosages extends Component {
  state = {
    page: 1,
    limit: 10,
    order: 'ASC',
    search: '',
    loading: false,
    orderBy: 'dose',
    data: [],
    column: [
      {
        field: 'dose',
        dose: 'Dosis obat',
      },
    ],

    // Form
    dose: '',
    validationFields: {
      dose: {
        type: 'string',
        isInvalid: false,
        errors: ['Dosis tidak boleh kosong'],
        isValidFunc: function(value) {
          return value && value.length > 0;
        },
      },
    },
  };

  componentDidMount = () => {
    this.page(1);
  };

  handleChange = e => {
    let value = e.target.value || null;
    let obj = {};
    obj[e.target.name] = value;
    obj['failedAttempt'] = false;
    let validationFields = {...this.state.validationFields};
    if (validationFields[e.target.name]) {
      validationFields[e.target.name].isInvalid = false;
      obj.validationFields = validationFields;
    }
    this.setState(obj);
  };

  page = page => {
    page = page || 1;
    this.setState({loading: true, search: ''});
    let payload = {
      page: page,
      limit: this.state.limit,
      search: this.state.search,
      order: this.state.order,
      orderBy: this.state.orderBy,
    };
    medicineService
      .getDosages(payload)
      .then(result => {
        this.setState(
          {
            page: result.page,
            limit: result.limit,
            total: result.total,
            order: result.order,
            orderBy: result.orderBy,
            data: result.items,
            loading: false,
          },
          () => {
            this.forceUpdate();
          },
        );
      })
      .catch(err => {
        this.setState({
          loading: false,
          data: [],
        });
      });
  };

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };

  show = item => {
    this.setState({id: item.id, dose: item.dose, showModal: true});
  };

  add = () => {
    this.setState({
      id: '',
      dose: '',
      showModal: true,
    });
  };

  save = () => {
    let state = {...this.state};
    this.setState({saveLoading: true}, () => {
      Utils.validateFields(state).then(result => {
        if (this.state.id && this.state.id.length > 0) {
          this.setState({validationFields: result.validationFields});
          this.forceUpdate();
          // Update existing
          let payload = {
            dose: this.state.dose,
            id: this.state.id,
          };
          medicineService
            .updateDosage(payload)
            .then(result => {
              this.setState({saveLoading: false, loading: true});
              this.closeModal();
              this.page(1);
            })
            .catch(err => {
              this.setState({saveLoading: false});
              errorService.handle(err);
            });
        } else {
          this.setState({validationFields: result.validationFields});
          this.forceUpdate();
          if (!result.isValid) {
            return;
          }
          // Create new one
          let payload = {
            dose: this.state.dose,
          };
          medicineService
            .createDosage(payload)
            .then(result => {
              this.setState({saveLoading: false, loading: true});
              this.closeModal();
              this.page(1);
            })
            .catch(err => {
              this.setState({saveLoading: false});
              errorService.handle(err);
            });
        }
      });
    });
  };

  delete = () => {
    if (!(this.state.id && this.state.id.length > 0)) {
      return;
    }

    if (!window.confirm('Apakah Anda yakin ingin menghapus item ini?')) {
      return;
    }
    medicineService
      .deleteDosage(this.state.id)
      .then(result => {
        this.setState({saveLoading: false, loading: true});
        this.closeModal();
        this.page(1);
      })
      .catch(err => {
        this.setState({saveLoading: false});
        errorService.handle(err);
      });
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
      id: '',
      dose: '',
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      id: '',
      dose: '',
    });
  };

  onItemClick = item => {
    let organizationId = '';
    let organizationName = '';
    for (let i in this.state.organizations) {
      if (item.organizationId === this.state.organizations[i].value) {
        organizationId = this.state.organizations[i].value;
        organizationName = this.state.organizations[i].text;
      }
    }
    this.setState({
      id: item.id,
      dose: item.dose,
      showModal: true,
    });
  };

  render() {
    return (
      <>
        <EuiPage className="class">
          <EuiPageBody
            style={{flexDirection: 'row'}}
            className={'content-container'}>
            {!this.state.loading && (
              <TablePage
                title={'Dosis obat'}
                placeholder={'Cari'}
                buttonLabel={'Tambah'}
                buttonFunc={() =>
                  this.setState({
                    showModal: true,
                    id: '',
                    dose: '',
                  })
                }
                data={this.state.data}
                column={this.state.column}
                disablePagination={this.state.loading}
                page={this.state.page}
                limit={this.state.limit}
                total={this.state.total}
                prev={this.prevPage}
                next={this.nextPage}
                toPage={this.page}
                onItemClick={this.onItemClick}
              />
            )}
          </EuiPageBody>
        </EuiPage>
        {this.state.showDeleteModal ? (
          <EuiOverlayMask>
            <EuiConfirmModal
              title="Hapus"
              onCancel={this.closeDeleteModal}
              onConfirm={this.closeDeleteModal}
              cancelButtonText="Batal"
              confirmButtonText="Hapus"
              buttonColor="danger"
              defaultFocusedButton="confirm">
              <p>Apakah anda ingin menghapus item ini ?</p>
            </EuiConfirmModal>
          </EuiOverlayMask>
        ) : null}
        {this.state.showModal ? (
          <EuiOverlayMask>
            <EuiModal
              onClose={() => this.closeModal()}
              initialFocus="[name=popswitch]">
              <EuiModalHeader>
                <EuiModalHeaderTitle>
                  Dosis obat
                </EuiModalHeaderTitle>
              </EuiModalHeader>

              <EuiModalBody>
                <EuiForm>
                  <EuiFormRow
                    label="Dosis obat"
                    isInvalid={
                      this.state.validationFields['dose'].isInvalid
                    }
                    error={
                      this.state.validationFields['dose'].isInvalid &&
                      this.state.validationFields['dose'].errors
                    }>
                    <EuiFieldText
                      placeholder="1 kali sehari"
                      value={this.state.dose}
                      name="dose"
                      onChange={this.handleChange}
                      aria-label="Dosis obat"
                    />
                  </EuiFormRow>
                </EuiForm>
              </EuiModalBody>

              <EuiModalFooter>
                <EuiButtonEmpty onClick={() => this.closeModal()}>
                  Batal
                </EuiButtonEmpty>
                {this.state.id && (
                  <EuiButton
                    color={'danger'}
                    onClick={() => this.delete()}
                    fill>
                    Hapus
                  </EuiButton>
                )}
                <EuiButton onClick={() => this.save()} fill>
                  Simpan
                </EuiButton>
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        ) : null}
      </>
    );
  }
}

export default MedicineDosages;
