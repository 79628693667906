import React, { Component } from "react";
import { 
  EuiPage, 
  EuiPageBody,
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiFieldText,
  EuiModalFooter,
  EuiButton,
  EuiButtonEmpty,
  EuiFlexItem,
  EuiFlexGroup,
  EuiCodeBlock,
  EuiDatePicker,
} from "@elastic/eui";
import moment from 'moment';
import TablePage from "../components/TablePage";
import JSONPretty from 'react-json-pretty';
import ThirdpartyLogService from '../services/ThirdpartyLogs';
import { debounce } from 'lodash';

const thirdPartyLogs = new ThirdpartyLogService;

class ThirdpartyLogs extends Component {
  state = {
    page: 1,
    limit: 10,
    total: 0,
    order: 'DESC',
    orderBy: 'timestamp',
    loading: false,
    saveLoading: false,
    deleteLoading: false,
    startDate: moment(),
    endDate: moment(),
    modalSearchDate: false,
    useSearchDate: false,
    data: [],
    column: [
      {
        field: "timestamp",
        name: "Tanggal"
      },
      {
        field: "thirdparty_name",
        name: "Rumah Sakit"
      },
      {
        field: "method",
        name: "Method"
      },
      {
        field: "endpoint",
        name: "Endpoint"
      },
      {
        field: "payload",
        name: "Payload",
        truncateText: true,
      },
      {
        field: "status_code",
        name: "Status"
      },
    ],
    selectedItem: {
      id: "",
      thirdparty_name: "",
      method: "",
      endpoint: "",
      payload: {
        body: "",
        header: "",
      },
      response: {
        body: "",
        header: "",
      },
      status_code: 0,
      timestamp: "",
      curl: "",
    },
  }

  page = page => {
    page = page || 1;
    this.setState({loading: true});
    let payload = {
      page: page,
      limit: this.state.limit,
      search: this.state.search,
      order: this.state.order,
      orderBy: this.state.orderBy,
    };

    if (this.state.useSearchDate) {
      payload.startDate = this.state.startDate.format('YYYY-MM-DD');
      payload.endDate = this.state.endDate.format('YYYY-MM-DD');
    }

    var result
    thirdPartyLogs.getThirdpartyLogs(payload)
      .then(response => {
        result = response
        console.log(result)
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          order: result.order,
          orderBy: result.order_by,
          data: result.logs || [],
          loading: false,
          modalSearchDate: false
        },() => { this.forceUpdate(); });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loading: false,
          data: [],
          modalSearchDate: false
        });
      });
  };

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };
  
  componentDidMount = () => {
    this.page(1);
  };

  onItemClick = (item) => {
    console.log(item)
    thirdPartyLogs.getThirdpartyLogsById(item.id).then(res => {
      console.log("DATA ------------> ", res)
      console.log("INNER DATA -------------> ", JSON.parse(res.payload).header)
      this.setState({
        modal: true,
        selectedItem: {
          id: res.id,
          thirdparty_name: res.thirdparty_name,
          method: res.method,
          endpoint: res.endpoint,
          payload: {
            body: JSON.parse(res.payload).body,
            header: JSON.parse(res.payload).header,
          },
          response: {
            body: JSON.parse(res.response).body,
            header: JSON.parse(res.response).header,
          },
          status_code: res.status_code,
          timestamp: res.timestamp,
          curl: res.curl,
        }
      });
    }).catch(err => {
      console.log(err);
    })
  }

  handleOnSearch = (value, useSearchDate = false) => {

    if (!useSearchDate) {
      this.setState({
        startDate: moment(),
        endDate: moment()
      })
    }
    this.setState({ search: value, loading: true, useSearchDate: useSearchDate });
    this.handleSearchDebounce();
  };

  handleDateChangeStart = (date) => {
    // in moment object format
    this.setState({ startDate: date });
  };

  handleDateChangeEnd = (date) => {
    // in moment object format
    this.setState({ endDate: date });
  };

  handleSearchDebounce = debounce(() => {
    this.page(1)
  }, 2000);

  render() {
      return (
        <>
        {this.state.modal && (
          <EuiOverlayMask>
            <EuiModal
              onClose={() => {this.setState({ modal: false })}}
              initialFocus="[name=popswitch]"
            >
              <EuiModalHeader>
                <EuiModalHeaderTitle>
                  Detail Riwayat Pihak Ketiga
                </EuiModalHeaderTitle>
              </EuiModalHeader>

              <EuiModalBody>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    Tanggal
                  </EuiFlexItem>
                  <EuiFlexItem>
                    {this.state.selectedItem.timestamp}
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    Sumber
                  </EuiFlexItem>
                  <EuiFlexItem>
                    {this.state.selectedItem.thirdparty_name}
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    Method
                  </EuiFlexItem>
                  <EuiFlexItem>
                    {this.state.selectedItem.method}
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    Endpoint
                  </EuiFlexItem>
                  <EuiFlexItem>
                    {this.state.selectedItem.endpoint}
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    Status
                  </EuiFlexItem>
                  <EuiFlexItem>
                    {this.state.selectedItem.status_code}
                  </EuiFlexItem>
                </EuiFlexGroup>
                { this.state.selectedItem.payload.header !== "" ? <>
                <EuiFlexGroup>
                  <EuiFlexItem>Payload Header</EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                    <EuiFlexItem>
                      <EuiCodeBlock language='json' isCopyable>
                        <JSONPretty data={this.state.selectedItem.payload.header}></JSONPretty>
                      </EuiCodeBlock>
                    </EuiFlexItem>
                </EuiFlexGroup>
                </> : null}
                { this.state.selectedItem.payload.body !== "" ? <>
                <EuiFlexGroup>
                  <EuiFlexItem>Payload Body</EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiCodeBlock language='json' isCopyable>
                      <JSONPretty data={this.state.selectedItem.payload.body}></JSONPretty>
                    </EuiCodeBlock>
                  </EuiFlexItem>
                </EuiFlexGroup>
                </> : null}
                { this.state.selectedItem.response.header !== "" ? <>
                <EuiFlexGroup>
                  <EuiFlexItem>Response Header</EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiCodeBlock language='json' isCopyable>
                      <JSONPretty data={this.state.selectedItem.response.header}></JSONPretty> 
                    </EuiCodeBlock>
                  </EuiFlexItem>
                </EuiFlexGroup>
                </> : null}
                {this.state.selectedItem.response.body !== "" ? <>
                <EuiFlexGroup>
                  <EuiFlexItem>Response Body</EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiCodeBlock language='json' isCopyable>
                      <JSONPretty data={this.state.selectedItem.response.body}></JSONPretty>
                    </EuiCodeBlock>
                  </EuiFlexItem>
                </EuiFlexGroup>
                </> : null}
                <EuiFlexGroup>
                  <EuiFlexItem>
                    Curl
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFlexItem>
                    <EuiCodeBlock language='text' isCopyable>
                      {`${this.state.selectedItem.curl}`}
                    </EuiCodeBlock>
                  </EuiFlexItem>
                </EuiFlexItem>
              </EuiModalBody>
              <EuiModalFooter>
                  <EuiButton
                    color={'primary'}
                    onClick={() => this.setState({
                      modal: false,
                    })}
                    fill>
                    Tutup
                  </EuiButton>
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        )}
        <EuiPage className="class">
          <EuiPageBody
            style={{ flexDirection: "row" }}
            className={"content-container"}
          >  
            <TablePage
              title={"Riwayat Pihak Ketiga"}
              placeholder={"Daftar riwayat"}
              data={this.state.data}
              column={this.state.column}
              disablePagination={this.state.loading}
              page={this.state.page}
              limit={this.state.limit}
              total={this.state.total}
              prev={this.prevPage}
              next={this.nextPage}
              toPage={this.page}
              onItemClick={this.onItemClick}
              loading={this.state.loading}
              searchComp={
                <>
                  <EuiFlexGroup style={{ marginRight: 10 }}>
                    <EuiFlexItem>
                      <EuiFieldText
                        onKeyDown={e => {
                          if (e.keyCode === 13) {
                            this.handleOnSearch(this.state.search);
                          }
                        }}
                        value={this.state.search}
                        onChange={e => this.handleOnSearch(e.target.value)}
                        placeholder={'Search...'}
                        append={
                          <EuiButtonEmpty
                            onClick={() => this.handleOnSearch(this.state.search)}>
                            Cari
                          </EuiButtonEmpty>
                        }
                        />
                      </EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiFlexGroup>
                    <EuiFlexItem grow={false}>
                      <EuiDatePicker
                        selected={this.state.startDate}
                        onChange={this.handleDateChangeStart}
                      />
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <div style={{ marginTop: 10 }}>
                        sampai
                      </div>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiDatePicker
                        selected={this.state.endDate}
                        onChange={this.handleDateChangeEnd}
                      />
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiButton onClick={() => this.handleOnSearch(this.state.search, true)} fill>
                          Cari Tanggal
                        </EuiButton>
                    </EuiFlexItem>  
                    <EuiFlexItem>
                        <EuiButton onClick={() => {
                          this.setState({
                            startDate: moment(),
                            endDate: moment(),
                            search: '',
                          }, () => this.handleOnSearch(this.state.search))
                        }}>
                          Reset pencarian
                        </EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </>
              }
            />
          </EuiPageBody>
        </EuiPage>
      </>
      )
  }
}

export default ThirdpartyLogs;
