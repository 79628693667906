import React, { Component } from "react";

import {
  EuiPageContent,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiPageContentBody,
  EuiTitle,
  EuiFieldText,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiPage,
  EuiPageBody,
  EuiFormRow,
  EuiFilePicker,
  EuiSpacer,
} from "@elastic/eui";

class ClinicDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <EuiPage className="euiNavDrawerPage">
          <EuiPageBody className={"content-container"}>
            <EuiPageContent>
              <EuiPageContentHeader>
                <EuiPageContentHeaderSection>
                  <EuiTitle>
                    <h2>Klinik</h2>
                  </EuiTitle>
                </EuiPageContentHeaderSection>
                <EuiPageContentHeaderSection>
                  <EuiFlexGroup>
                    {this.props.buttonLabel ? (
                      <EuiFlexItem grow={false}>
                        <EuiButton fill onClick={this.props.butonFunc}>
                          {this.props.buttonLabel}
                        </EuiButton>
                      </EuiFlexItem>
                    ) : null}
                  </EuiFlexGroup>
                </EuiPageContentHeaderSection>
              </EuiPageContentHeader>
              <EuiPageContentBody>
                <EuiForm
                  className="form-layout"
                >
                  <EuiFormRow label="Rumah Sakit">
                    <EuiFieldText name="hospital" placeholder="Pilih Rumah Sakit"/>
                  </EuiFormRow>
                  <EuiFormRow label="Klinik Induk">
                    <EuiFieldText name="parentClinic"  placeholder="Pilih Klinik Induk"/>
                  </EuiFormRow>
                  <EuiFormRow label="Nama">
                    <EuiFieldText name="name"  placeholder="Nama Klinik"/>
                  </EuiFormRow>
                  <EuiFormRow label="Kode Klinik">
                    <EuiFieldText name="codeClinic"  placeholder="Kode Klinik"/>
                  </EuiFormRow>
                  <EuiFormRow label="Telepon">
                    <EuiFieldText name="phone"  placeholder="Telepon"/>
                  </EuiFormRow>
                  <EuiFormRow label="Alamat">
                    <EuiFieldText name="address"  placeholder="Alamat"/>
                  </EuiFormRow>
                  <EuiFormRow label="Lokasi">
                    <EuiFieldText name="location"  placeholder="Pilih lokasi pada peta"/>
                  </EuiFormRow>
                  <EuiFormRow label="Surel">
                    <EuiFieldText name="email"  placeholder="Surel"/>
                  </EuiFormRow>
                  <EuiFormRow label="Ikon">
                    <EuiFilePicker name="icon" id='iconFilePicker'/>
                  </EuiFormRow>
                  <EuiSpacer size="l" />
                  <EuiFlexGroup justifyContent={"flexEnd"}>
                    <EuiFlexItem grow={false}>
                      <EuiButton fill color='danger'>Hapus</EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiButton fill>Ubah</EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiForm>
              </EuiPageContentBody>
            </EuiPageContent>
          </EuiPageBody>
        </EuiPage>
      </>
    );
  }
}

export default ClinicDetail;
