import Config from '../Config';
import axios from 'axios';
import Error from './Error';
import Misc from './Misc';

const ErrorService = new Error();
const MiscService = new Misc();

class SuperSet {
  auth = (variant) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/superset/access-token/` + variant;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then(async (response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          // handled by the caller
          // ErrorService.handle(err);
        });
    });
  };
}

export default SuperSet;
