import React, { Component } from "react";
import { EuiPage, EuiPageBody, EuiFlexItem, EuiFieldText, EuiButtonEmpty } from "@elastic/eui";
import { debounce } from 'lodash';
import TablePage from "../components/TablePage";
import Staff from "../services/Staffs";

const staffService = new Staff();

class Staffs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staffType: this.identifyStaffType(),
      page: 1,
      limit: 10,
      order: "ASC",
      search: "",
      loading: true,
      orderBy: "name",
      data: [],
      column: [
        {
          field: "name",
          name: "Nama"
        },
        {
          field: "emailAddress",
          name: "Email"
        },
        {
          field: "phoneNumber",
          name: "Nomor Telepon"
        },
        {
          field: "organizationName",
          name: "Organisasi"
        },
        {
          field: "instant",
          name: "Instan"
        }
      ]
    };
  }

  componentDidMount = () => {
    const search = this.props.location.search;
    const searchIndex = search.search("q=");
    if (searchIndex !== -1) {
      const searchQuery = search.slice(searchIndex + 2);
      this.handleOnSearch(searchQuery);
    }
    this.page(1);
  };

  page = page => {
    page = page || 1;
    this.setState({ loading: true });
    let payload = {
      page: page,
      limit: this.state.limit,
      search: this.state.search,
      order: this.state.order,
      orderBy: this.state.orderBy
    };
    if (this.state.staffType === "doctors") {
      payload.type = "DOCTOR";
    } else if (this.state.staffType === "assistants") {
      payload.type = "NURSE";
    } else if (this.state.staffType === "staffs-pharmacy") {
      payload.type = "PHARMACY";
    } else {
      payload.type = "HOTEL_STAFF";
    }
    staffService
      .getStaffs(payload)
      .then(result => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          order: result.order,
          orderBy: result.orderBy,
          data: result.items,
          loading: false
        }, () => {
          this.forceUpdate();
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loading: false,
          data: []
        });
      });
  };

  handleOnSearch = (value) => {
    this.setState({ search: value, loading: true });
    this.handleSearchDebounce();
  }

  handleSearchDebounce = debounce(() => {
    const payload = {
      search: this.state.search,
      type: ""
    }
    if (this.state.staffType === "doctors") {
      payload.type = "DOCTOR";
    } else if (this.state.staffType === "assistants") {
      payload.type = "NURSE";
    } else if (this.state.staffType === "staffs-pharmacy") {
      payload.type = "PHARMACY";
    } else {
      payload.type = "HOTEL_STAFF";
    }
    staffService
      .getStaffs(payload)
      .then(result => {
        console.log(result)
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          order: result.order,
          orderBy: result.orderBy,
          data: result.items,
          loading: false
        },() => {
          this.forceUpdate();
        });
      })
      .catch(err => {
        console.log(err)
      })
  }, 2000)

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };

  onItemClick = item => {
    this.props.history.push("/staff/detail/" + item.id);
  };

  identifyStaffType = () => {
    let staffType = this.props.match.path.split("/")
    return staffType[1]
  }

  render() {
    return (
      <>
        <EuiPage className="class">
          <EuiPageBody
            style={{ flexDirection: "row" }}
            className={"content-container"}
          >
            <TablePage
              title={this.state.staffType === 'doctors' ? "Dokter" : this.state.staffType === 'assistants' ? "Asisten" : this.state.staffType === 'staffs-pharmacy' ? "Staff Farmasi" : "Staff Hotel" }
              placeholder={
                this.state.staffType === 'doctors' ? "Nama Dokter" : this.state.staffType === 'assistants' ? "Nama Asisten" : this.state.staffType === 'staffs-pharmacy' ? "Nama Staff Farmasi" : "Nama Staff Hotel" 
              }
              searchComp={(
                <EuiFlexItem>
                  <EuiFieldText
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        this.handleOnSearch(this.state.search);
                      }
                    }}
                    value={this.state.search}
                    onChange={(e) => this.handleOnSearch(e.target.value)}
                    placeholder={this.props.placeholder}
                    append={
                      <EuiButtonEmpty
                        onClick={() => this.handleOnSearch(this.state.search)}
                      >
                        Cari
                      </EuiButtonEmpty>
                    }
                  />
                </EuiFlexItem>
              )}
              buttonLabel={"Tambah Baru"}
              buttonFunc={() => {
                this.props.history.push("/staff/detail/new");
              }}
              buttonImportLabel={"Import Staff"}
              buttonImportFunc={() => {
                this.props.history.push("/staff/import");
              }}
              data={this.state.data}
              column={this.state.column}
              location={this.props.location}
              disablePagination={this.state.loading}
              loading={this.state.loading}
              page={this.state.page}
              limit={this.state.limit}
              total={this.state.total}
              prev={this.prevPage}
              next={this.nextPage}
              toPage={this.page}

              onItemClick={this.onItemClick}
            />
          </EuiPageBody>
        </EuiPage>
      </>
    );
  }
}

export default Staffs;
