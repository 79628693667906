import React, { Component } from 'react';
import Config from './Config';
import PrivateRoute from './PrivateRoute';

import SupersetScreen from './screens/Superset';

class Superset extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let supersetPages = (Config.supersetPages || []).map((page) => (
      <PrivateRoute
        path={'/' + page.id}
        exact
        component={SupersetScreen}
        supersetName={page.name}
        supersetPath={page.path}
      />
    ));

    return <div>{supersetPages}</div>;
  }
}

export default Superset;
