import React, { Component } from "react";
import {
    EuiPage,
    EuiPageBody,
    EuiPageContent,
    EuiSpacer,
    EuiPageContentBody,
    EuiTitle,
    EuiSwitch,
} from "@elastic/eui";
import SettingServices from '../services/Settings';


const settingsServcice = new SettingServices();
class Settings extends Component {
    state = {
        maintenance_mode: false

    }
    onChangeMaintenanceMode = () => {
        console.log("Maintenance mode")
        this.setMaintenanceStatus(!this.state.maintenance_mode)
        // this.setState({
        //     maintenance_mode: !this.state.maintenance_mode
        // })
    }

    componentDidMount = () => {
        this.getMaintenanceStatus()
    }

    getMaintenanceStatus = () => {
        settingsServcice.getMaintenanceMode().then(result => {
            console.log("Maintenance status", result)
            this.setState({
                maintenance_mode: result.value == 'true'
            })
        });
    }

    setMaintenanceStatus = (status) => {
        settingsServcice.setMaintenanceStatus(status).then(_ => {
            this.getMaintenanceStatus();
        })
    }

    render() {
        return (

            <EuiPage style={{ textAlign: "left" }}>
                <EuiPageBody
                    className={'content-container'}
                >
                    <EuiPageContent>
                        <EuiTitle size="l">
                            <h1>Pengaturan</h1>
                        </EuiTitle>
                        <EuiSpacer />
                        <EuiPageContentBody>
                            <h2 style={{ fontWeight: "bold" }}>Maintenance Mode</h2><br />
                            <EuiSwitch
                                label="Aktifkan maintenance mode"
                                checked={this.state.maintenance_mode}
                                onChange={(_) => this.onChangeMaintenanceMode()}
                            />

                        </EuiPageContentBody>
                    </EuiPageContent>

                </EuiPageBody>
            </EuiPage>

        );
    }
}

export default Settings;