import React from 'react';
import moment from 'moment';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import './App.css';
import PrivateRoute from './PrivateRoute';
import Landing from './screens/Landing';
import Orders from './screens/Orders';
import Payments from './screens/Payments';
import Admin from './screens/Admin';
import Organizations from './screens/Organizations';
import Patients from './screens/Patients';
import Staffs from './screens/Staffs';
import Sessions from './screens/Sessions';
import SessionSlots from './screens/SessionSlots';
import Clinics from './screens/Clinics';
import ClinicDetail from './screens/ClinicDetail';
import SessionDetail from './screens/SessionDetail';
import StaffDetail from './screens/StaffDetail';
import PatientDetail from './screens/PatientDetail';
import Hospital from './screens/Hospital';
import Login from './screens/Login';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';
import StaffImport from './screens/StaffImport';
import Whatsapp from './screens/Whatsapp';
import PatientsOfClinic from './screens/PatientsOfClinic';
import Products from './screens/Products';
import Monitoring from './screens/Monitoring';
import MedicineApplications from './screens/MedicineApplications';
import MedicineDosages from './screens/MedicineDosages';
import Medicines from './screens/Medicines';
import Pharmacies from './screens/Pharmacies';
import PharmacyDetail from './screens/PharmacyDetail';
import PharmacyGroups from './screens/PharmacyGroups';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import Settings from './screens/Settings';
import Consultations from './screens/Consultation';
import AppSettings from './screens/AppSettings';
import SyncBridge from './screens/SyncBridge';
import RegionCodes from './screens/RegionCodes';
import ThirdpartyLogs from './screens/ThirdpartyLogs';
import WeblinkList from './screens/WeblinkList';
import CheckEventError from './screens/CheckEventError';
import VoucherUseds from './screens/VoucherUseds';
import Vouchers from './screens/Vouchers';
import Events from './screens/Events';
import KimiaFarmaPharmacies from './screens/KimiaFarmaPhamacies';
import Summary from './screens/Summary';
import Overview from './screens/Overview';

import Superset from './Superset';

import Utils from './Utils';
import UserService from './services/Users';
import ErrorService from './services/Error';
import LaboratoryTest from './screens/LaboratoryTest';

const userService = new UserService();
const errorService = new ErrorService();

const history = createBrowserHistory();
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (window.location.pathname !== '/login') {
      // first render always check
      this.checkAuth();
      // do another check every 30 second interval
      this.intervalId = setInterval(() => this.checkAuth(), 30000);
    }
  }

  componentWillUnmount() {
    if (window.location.pathname !== '/login') {
      clearInterval(this.intervalId);
    }
  }

  async checkAuth() {
    try {
      let token = window.localStorage.getItem('token');
      let parsedJwt = Utils.parseJwt(token);
      let expiryUnix = parsedJwt.exp;
      let expiryMoment = moment.unix(expiryUnix);
      let expirySub1Day = expiryMoment.subtract('1', 'days');

      // refresh one day before expire
      if (moment().isAfter(expirySub1Day)) {
        let result = await userService.refresh();
        window.localStorage.setItem('token', result.token);
      }
    } catch (e) {
      console.log(e);
      // treat as 401 (expired session)
      errorService.handle({ response: { status: 401 } });
    }
  }

  render() {
    let isInDashboardScene = () => {
      return (
        window.localStorage.getItem('token') &&
        window.localStorage.getItem('token').length > 0 &&
        window.location.pathname !== '/login'
      );
    };
    console.log(window.location.pathname);
    return (
      <div className='App' id='main'>
        <Router history={history}>
          {!isInDashboardScene() ? '' : <Header />}
          {!isInDashboardScene() ? '' : <Sidebar />}
          <Route path='/login' exact component={Login} />
          <PrivateRoute path='/' exact component={Landing} />
          {/* <PrivateRoute path="/assistants" exact component={Staffs} />
          <PrivateRoute path="/doctors" exact component={Staffs} />
          <PrivateRoute path="/staffs-hotel" exact component={Staffs} />
          <PrivateRoute path="/staffs-pharmacy" exact component={Staffs} />
          <PrivateRoute path="/staff/detail/:uuid" exact component={StaffDetail} />
          <PrivateRoute path="/staff/import" exact component={StaffImport} />
          <PrivateRoute path="/pharmacies" exact component={Pharmacies} />
          <PrivateRoute path="/laboratory-test" exact component={LaboratoryTest} />
          <PrivateRoute path="/pharmacy/detail/:uuid" exact component={PharmacyDetail} />
          <PrivateRoute path="/pharmacy-groups" exact component={PharmacyGroups} /> */}
          <PrivateRoute
            path='/prescriptions/:variant'
            exact
            component={KimiaFarmaPharmacies}
          />
          <PrivateRoute path='/summaries/:variant' exact component={Summary} />
          <PrivateRoute path='/overview/:variant' exact component={Overview} />
          {!isInDashboardScene() ? '' : <Footer />}
        </Router>
      </div>
    );
  }
}

export default App;
