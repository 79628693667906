import React, { Component } from 'react';
import { debounce } from 'lodash';
import {
  EuiPage,
  EuiPageBody,
  EuiFlexItem,
  EuiFieldText,
  EuiButtonEmpty,
  EuiButton,
} from '@elastic/eui';
import TablePage from '../components/TablePage';
import Laboratory from '../services/LaboratoryTest';

const laboratoryService = new Laboratory();

class LaboratoryTest extends Component {
  state = {
    id: '',
    page: 1,
    limit: 10,
    order: 'ASC',
    search: '',
    loading: false,
    downloading: false,
    orderBy: 'name',
    column: [
      {
        field: 'order_id',
        name: 'Kode ',
      },
      {
        field: 'lab_expiry_date',
        name: 'Masa Berlaku',
      },
      {
        field: 'patient_name',
        name: 'Nama Pasien',
      },
      {
        field: 'preparations',
        name: 'Persiapan',
      },
      {
        field: 'name',
        name: 'Jenis Sampel',
      },
      {
        field: 'lab_address',
        name: 'Lokasi',
      },
      {
        field: 'status',
        name: 'Status',
        render: (v) => this.renderStatus(v),
      },
      {
        field: '',
        name: 'Aksi',
        render: (v) => (
          <EuiButton
            disabled={v.status < 2 || this.state.id}
            isLoading={v.id === this.state.id}
            onClick={() => {
              this.setState({ id: v.id }, () => {
                laboratoryService
                  .getDiagnosticReceiptBySession(v.session_id)
                  .then((value) => {
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(
                      new Blob([value], { type: 'application/pdf' })
                    );
                    link.download = `prodia-payment-receipt-${v.id}.pdf`;
                    link.click();
                  })
                  .catch((err) => console.log(err))
                  .finally(() =>
                    this.setState({
                      id: '',
                    })
                  );
              });
            }}
          >
            Unduh Kwitansi
          </EuiButton>
        ),
      },
    ],
    data: [],
  };

  renderStatus = (status) => {
    switch (status) {
      case 0:
        return 'Created';
      case 1:
        return 'Invoice';
      case 2:
        return 'PAID';
      case 3:
        return 'PROCEEDED';
      case 4:
        return 'RESERVED_4';
      case 5:
        return 'RESERVED_5';
      case 6:
        return 'RESERVED_6';
      case 7:
        return 'RESERVED_7';
      case 8:
        return 'RESERVED_8';
      case 9:
        return 'RESERVED_9';
      case 10:
        return 'DONE';
      default:
        return 'UNKNOWN';
    }
  };

  componentDidMount = () => {
    this.page(1);
  };

  page = (page) => {
    page = page || 1;
    this.setState({
      loading: true,
    });

    let payload = {
      page: page,
      limit: this.state.limit,
      search: this.state.search,
      order: this.state.order,
      orderBy: this.state.orderBy,
    };

    laboratoryService
      .getDiagnosticRequests(payload)
      .then((res) => {
        var data = res['items'];
        console.log(res);
        data.forEach((element) => {
          if (element['services'].length > 0) {
            element['name'] = element['services'][0]['name'];
            element['type'] = element['services'][0]['type'];
            element['price'] = element['services'][0]['price'];
            element['external_id'] = element['services'][0]['external_id'];
            element['notes'] = element['services'][0]['notes'];
            element['preparations'] = element['services'][0]['preparations'];
          }
          if (element['lab_expiry_date'] == '0001-01-01 00:00:00') {
            element['lab_expiry_date'] = 'No expiration date';
          }
        });

        this.setState(
          {
            page: res.page,
            limit: res.limit,
            total: res.total,
            order: res.order,
            orderBy: res.orderBy,
            data: data,
            loading: false,
          },
          () => {
            this.forceUpdate();
          }
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  };

  handleOnSearch = (value) => {
    this.setState({ search: value, loading: true });
    this.handleSearchDebounce();
  };

  handleSearchDebounce = debounce(() => {
    this.setState({ search: this.state.search });
    this.page();
  }, 2000);

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };

  add = () => {
    // this.props.history.push("/pharmacy/detail/new");
  };

  onItemClick = (item) => {
    // this.props.history.push("/pharmacy/detail/" + item.id);
  };

  render = () => {
    return (
      <>
        <EuiPage className='class'>
          <EuiPageBody
            style={{ flexDirection: 'row', textAlign: 'center', width: '100%' }}
            className={'content-container'}
          >
            <TablePage
              title={'Uji Lab'}
              placeholder={'Cari'}
              searchComp={
                <EuiFlexItem>
                  <EuiFieldText
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        this.handleOnSearch(this.state.search);
                      }
                    }}
                    value={this.state.search}
                    onChange={(e) => this.handleOnSearch(e.target.value)}
                    placeholder={this.props.placeholder}
                    append={
                      <EuiButtonEmpty
                        onClick={() => this.handleOnSearch(this.state.search)}
                      >
                        Cari
                      </EuiButtonEmpty>
                    }
                  />
                </EuiFlexItem>
              }
              // buttonLabel={"Tambah"}
              // buttonFunc={() => this.add()}
              data={this.state.data}
              column={this.state.column}
              disablePagination={this.state.loading}
              loading={this.state.loading}
              page={this.state.page}
              limit={this.state.limit}
              total={this.state.total}
              prev={this.prevPage}
              next={this.nextPage}
              toPage={this.page}
              onItemClick={this.onItemClick}
            />
          </EuiPageBody>
        </EuiPage>
      </>
    );
  };
}

export default LaboratoryTest;
