import Config from '../Config';
import axios from 'axios';
import Error from './Error';
import Misc from './Misc';
import moment from "moment";

const ErrorService = new Error();
const MiscService = new Misc();

class Vouchers {
  getVouchers = payload => {
    return new Promise((resolve, reject) => {
    payload = payload || {};
    payload.limit = payload.limit || 10;
    payload.order = payload.Order || 'DESC';
    payload.search = payload.search || '';
    payload.page = payload.page || '';
    payload.startDate = payload.startDate || '';
    payload.endDate = payload.endDate || '';
      let url = `${Config.apiHost}/api/vouchers/used?page=${payload.page}&limit=${payload.limit}&order=${payload.order}`;

      if (payload.search !== '') {
        url += `&voucher_code=${payload.search}`;
      }

      if (payload.startDate !== '') {
        url += `&used_date=${payload.startDate}`;
      }
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then(response => {
          for (let i in response.data.list) {
            response.data.list[i].datetime = moment.unix(response.data.list[i].created_date).format('YYYY-MM-DD HH:mm:ss');
          }
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  
  getVouchersById = id => {
    return new Promise((resolve, reject) => {
        let url = `${Config.apiHost}/api/vouchers/used/${id}`;
        axios
          .get(url, { headers: MiscService.generateHeaders() })
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            reject(err);
            ErrorService.handle(err);
          });
      });
  };
  
  getAllVouchers = payload => {
    return new Promise((resolve, reject) => {
    payload = payload || {};
    payload.limit = payload.limit || 10;
    payload.order = payload.Order || 'ASC';
    payload.search = payload.search || '';
    payload.page = payload.page || '';
      let url = `${Config.apiHost}/api/vouchers?page=${payload.page}&limit=${payload.limit}&order=${payload.order}&search=${payload.search}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  deleteVoucher = code => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/voucher/${code}`;
      axios.delete(url, {headers: MiscService.generateHeaders() })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
        ErrorService.handle(err);
      });
    });
  };

  updateVouchers = (item, oldCode) => {
    return new Promise((resolve, reject) => {
      let payload = {};
      payload.code = oldCode || "";
      payload.item = item || {};
      let url = `${Config.apiHost}/api/voucher/${payload.code}`;
      axios.put(url, payload, {headers: MiscService.generateHeaders() })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
        ErrorService.handle(err)
      });
    });
  };

  createVoucher = payload => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      let url = `${Config.apiHost}/api/voucher`;
      axios.post(url, payload, {headers: MiscService.generateHeaders() })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
        ErrorService.handle(err)
      });
    });
  };

}

export default Vouchers;
