import React, { Component } from "react";
import { 
  EuiPage, 
  EuiPageBody,
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiModalFooter,
  EuiButton,
  EuiButtonEmpty,
  EuiFlexItem,
} from "@elastic/eui";
import TablePage from "../components/TablePage";
import Utils from "../Utils";
import Error from '../services/Error';
import RegionCode from '../services/RegionCodes';
import { debounce } from 'lodash';

const RegionCodesService = new RegionCode;
const ErrorService = new Error();

class RegionCodes extends Component {
  state = {
    page: 1,
    limit: 10,
    total: 0,
    order: 'ASC',
    orderBy: 'name',
    loading: false,
    saveLoading: false,
    deleteLoading: false,
    data: [],
    column: [
      {
        field: "name",
        name: "Nama Wilayah"
      },
      {
        field: "code",
        name: "Kode"
      },
      {
        field: "parent",
        name: "Parent"
      },
    ],
    id: '',
    name: '',
    code: '',
    parent: '',
    modal: false,
    validationFields: {
      name: {
        type: "string",
        isInvalid: false,
        errors: ["Nama tidak boleh kosong"],
        isValidFunc: function (value) {
          return value && value.length > 0;
        }
      },
    },
  }

  page = page => {
    page = page || 1;
    this.setState({loading: true});
    let payload = {
      page: page,
      limit: this.state.limit,
      search: this.state.search,
      order: this.state.order,
      orderBy: this.state.orderBy,
      parent: "all",
    };
    var result
    RegionCodesService.getRegionCodes(payload)
      .then(response => {
        result = response
        console.log(result)
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          order: result.order,
          orderBy: result.order_by,
          data: result.data || [],
          loading: false,
        },() => { this.forceUpdate(); });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loading: false,
          data: [],
        });
      });
  };

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };
  componentDidMount = () => {
    this.page(1);
  };

  add = () => {
    this.setState({
      id: '',
      name: '',
      code: '',
      parent: '',
      modal: true,
      active: false,
    })
  }

  handleChange = e => {
    let value = e.target.value || null;
    let obj = {};
    obj[e.target.name] = value;
    obj["failedAttempt"] = false;
    let validationFields = { ...this.state.validationFields };
    if (validationFields[e.target.name]) {
      validationFields[e.target.name].isInvalid = false;
      obj.validationFields = validationFields;
    }
    this.setState(obj);
  };
  
  save = () => {
    let state = { ...this.state };
    this.setState({ saveLoading: true }, () => {
      Utils.validateFields(state).then(result => {
        this.setState({ validationFields: result.validationFields });
        this.forceUpdate();
        if (!result.isValid) {
          this.setState({ saveLoading: false })
          console.log(result);
          return;
        }
        let payload = {
            name: this.state.name,
            code: this.state.code,
            parent: this.state.parent,
        }
        if (this.state.id && this.state.id.length > 0) {
          payload.id = this.state.id
          RegionCodesService.updateRegionCode(payload)
          .then((result) => {
            this.setState({ saveLoading: false, modal: false })
            this.page(1);
          })
          .catch((err) => {
            this.setState({ saveLoading: false });
            ErrorService.handle(err);    
          })
        } else {
            RegionCodesService.create(payload)
          .then((result) => {
            this.setState({ saveLoading: false, modal: false })
            this.page(1);
          })
          .catch((err) => {
            console.log(err)
            this.setState({ saveLoading: false });
            ErrorService.handle(err);    
          })
        }
      });
    });
  };

  onItemClick = (item) => {
      console.log(item)
    this.setState({
      id: item.id,
      name: item.name,
      code: item.code,
      parent: item.parent,
      modal: true,
    })
  }

  delete = () => {
    if (!(this.state.id && this.state.id.length > 0)) {
      return;
    }
    if (!window.confirm("Apakah Anda yakin ingin menghapus item ini?")) {
      return;
    }
    this.setState({ deleteLoading: true})
    RegionCodesService.deleteRegionCode(this.state.id)
      .then(result => {
        this.setState({ modal: false, deleteLoading: false });
        this.page(1);
      })
      .catch(err => {
        console.log(err.response)
        this.setState({ modal: false, deleteLoading: false });
        ErrorService.handle(err);
      });
  };

  handleOnSearch = value => {
    this.setState({ search: value, loading: true });
    this.handleSearchDebounce();
  };

  handleSearchDebounce = debounce(() => {
    this.page(1)
  }, 2000);

  render() {
      return (
        <>
        {this.state.modal && (
          <EuiOverlayMask>
            <EuiModal
              onClose={() => {this.setState({ modal: false })}}
              initialFocus="[name=popswitch]"
            >
              <EuiModalHeader>
                <EuiModalHeaderTitle>
                  Tambah Kode Wilayah 
                </EuiModalHeaderTitle>
              </EuiModalHeader>

              <EuiModalBody>
                <EuiForm>
                  <EuiFormRow label="Nama Wilayah"
                    isInvalid={
                      this.state.validationFields['name'].isInvalid
                    }
                    error={
                      this.state.validationFields['name'].isInvalid &&
                      this.state.validationFields['name'].errors
                    }
                  >
                    <EuiFieldText
                      placeholder="Nama Wilayah"
                      value={this.state.name}
                      name="name"
                      onChange={this.handleChange}
                      aria-label="name"
                    />
                  </EuiFormRow>
                  <EuiFormRow label="Kode">
                    <EuiFieldText
                      placeholder=""
                      value={this.state.code}
                      name="code"
                      onChange={this.handleChange}
                      aria-label="code"
                    />
                  </EuiFormRow>
                  <EuiFormRow label="Parent">
                    <EuiFieldText
                      placeholder="Parent"
                      value={this.state.parent}
                      onChange={this.handleChange}
                      name="parent"
                      aria-label="parent"
                    />
                  </EuiFormRow>
                </EuiForm>
              </EuiModalBody>

              <EuiModalFooter>
                <EuiButtonEmpty
                  onClick={() => {this.setState({ modal: false })}}
                >
                  Batal
                </EuiButtonEmpty>
                {this.state.id && this.state.id.length > 0 ? (
                  <>
                    <EuiButton
                      color={"danger"}
                      onClick={() => this.delete()}
                      disabled={this.state.saveLoading}
                      isLoading={this.state.deleteLoading}
                      fill
                    >
                      Hapus
                    </EuiButton>
                    <EuiButton fill isLoading={this.state.saveLoading} disabled={this.state.deleteLoading} onClick={() => this.save()}>
                      Update
                    </EuiButton>
                  </>
                ) : (
                  <EuiButton fill isLoading={this.state.saveLoading} disabled={this.state.deleteLoading} onClick={() => this.save()}>
                    Tambah
                  </EuiButton>
                )}
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        )}
        <EuiPage className="class">
          <EuiPageBody
            style={{ flexDirection: "row" }}
            className={"content-container"}
          >
            <TablePage
              title={"Kode Wilayah"}
              buttonLabel={'Tambah Baru'}
              buttonFunc={() => this.add()}
              placeholder={"Nama Produk"}
              data={this.state.data}
              column={this.state.column}
              disablePagination={this.state.loading}
              page={this.state.page}
              limit={this.state.limit}
              total={this.state.total}
              prev={this.prevPage}
              next={this.nextPage}
              toPage={this.page}
              onItemClick={this.onItemClick}
              loading={this.state.loading}
              searchComp={
                <EuiFlexItem>
                  <EuiFieldText
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        this.handleOnSearch(this.state.search);
                      }
                    }}
                    value={this.state.search}
                    onChange={e => this.handleOnSearch(e.target.value)}
                    placeholder={this.props.placeholder}
                    append={
                      <EuiButtonEmpty
                        onClick={() => this.handleOnSearch(this.state.search)}>
                        Cari
                      </EuiButtonEmpty>
                    }
                  />
                </EuiFlexItem>
              }
            />
          </EuiPageBody>
        </EuiPage>
      </>
      )
  }
}

export default RegionCodes;
