import React, { Component } from "react";
import Product from '../services/Products';
import { 
  EuiPage, 
  EuiPageBody,
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiFieldNumber,
  EuiModalFooter,
  EuiButton,
  EuiButtonEmpty,
  EuiSwitch, 
} from "@elastic/eui";
import TablePage from "../components/TablePage";
import Utils from "../Utils";
import Error from '../services/Error';
import swal from 'sweetalert';

const ProductService = new Product();
const ErrorService = new Error();

class Products extends Component {
  state = {
    page: 1,
    limit: 10,
    total: 0,
    order: 'ASC',
    orderBy: 'name',
    loading: false,
    saveLoading: false,
    deleteLoading: false,
    data: [],
    column: [
      {
        field: "title",
        name: "Nama Produk"
      },
      {
        field: "description",
        name: "Deskripsi"
      },
      {
        field: "fee",
        name: "Tarif"
      },
      {
        field: "grossFee",
        name: "Tarif Bruto"
      }
    ],
    id: '',
    title: '',
    description: '',
    fee: '',
    priority: '',
    externalId: '',
    modal: false,
    validationFields: {
      title: {
        type: "string",
        isInvalid: false,
        errors: ["Tidak boleh kosong"],
        isValidFunc: function (value) {
          return value && value.length > 0;
        }
      },
    },
  }

  page = page => {
    page = page || 1;
    this.setState({loading: true, search: ''});
    let payload = {
      page: page,
      limit: this.state.limit,
      search: this.state.search,
      order: this.state.order,
      orderBy: this.state.orderBy,
      activeOnly: "false",
    };
    var result
    ProductService
      .getAllProducts(payload)
      .then(response => {
        result = response
        return ProductService.getFeeConfig();
      })
      .then((feeConfig) => {
        for (let i in result.items) {
          result.items[i].fee = `Rp ${result.items[i].price.units}`
          result.items[i].grossFee = `Rp ${(result.items[i].price.units + (result.items[i].price.units * feeConfig.settlementPercentageCut/100) + feeConfig.feeCut)}`
        }
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          order: result.order,
          orderBy: result.order_by,
          data: result.items || [],
          loading: false,
        },() => { this.forceUpdate(); });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loading: false,
          data: [],
        });
      });
  };

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };
  componentDidMount = () => {
    this.page(1);
  };

  add = () => {
    this.setState({
      id: '',
      title: '',
      description: '',
      fee: '',
      priority: '',
      externalId: '',
      modal: true,
      active: false,
    })
  }

  handleChange = e => {
    let value = e.target.value || null;
    let obj = {};
    obj[e.target.name] = value;
    obj["failedAttempt"] = false;
    let validationFields = { ...this.state.validationFields };
    if (validationFields[e.target.name]) {
      validationFields[e.target.name].isInvalid = false;
      obj.validationFields = validationFields;
    }
    this.setState(obj);
  };
  
  save = () => {
    let state = { ...this.state };
    this.setState({ saveLoading: true }, () => {
      Utils.validateFields(state).then(result => {
        this.setState({ validationFields: result.validationFields });
        this.forceUpdate();
        if (!result.isValid) {
          this.setState({ saveLoading: false })
          console.log(result);
          return;
        }
        let payload = {
          title: this.state.title,
          description: this.state.description,
          priority: parseInt(this.state.priority, 10),
          price: {
            currency_code: "IDR",
            units: parseInt(this.state.fee, 10)
          },
          external_id: this.state.externalId,
          active: this.state.active,
        }
        if (this.state.id && this.state.id.length > 0) {
          payload.id = this.state.id
          ProductService.updateProduct(payload)
          .then((result) => {
            this.setState({ saveLoading: false, modal: false })
            this.page(1);
          })
          .catch((err) => {
            this.setState({ saveLoading: false });
            ErrorService.handle(err);    
          })
        } else {
          ProductService.createProduct(payload)
          .then((result) => {
            this.setState({ saveLoading: false, modal: false })
            this.page(1);
          })
          .catch((err) => {
            console.log(err)
            this.setState({ saveLoading: false });
            ErrorService.handle(err);    
          })
        }
      });
    });
  };

  onItemClick = (item) => {
    this.setState({
      id: item.id,
      description: item.description,
      fee: item.price.units,
      title: item.title,
      priority: item.priority,
      modal: true,
      externalId: item.external_id || '',
      active: item.active || false
    })
  }

  delete = () => {
    if (!(this.state.id && this.state.id.length > 0)) {
      return;
    }
    if (!window.confirm("Apakah Anda yakin ingin menghapus item ini?")) {
      return;
    }
    this.setState({ deleteLoading: true})
    ProductService.deleteProduct(this.state.id)
      .then(result => {
        this.setState({ modal: false, deleteLoading: false });
        this.page(1);
      })
      .catch(err => {
        console.log(err.response)
        this.setState({ modal: false, deleteLoading: false });
        // ErrorService.handle(err);
        if (err.response && err.response.data && err.response.data.message && err.response.data.message === "product-still-in-use") {
          swal({
            icon: 'error', 
            title: 'Produk ini masih digunakan', 
            html: true,
            text: 'Silakan lepas produk dari klinik/slot sesi yang bersangkutan', 
            confirmButtonText: 'OK',
            closeOnClickOutside: true,
            closeOnEsc: true,
          });
        }
      });
  };

  render() {
      return (
        <>
        {this.state.modal && (
          <EuiOverlayMask>
            <EuiModal
              onClose={() => {this.setState({ modal: false })}}
              initialFocus="[name=popswitch]"
            >
              <EuiModalHeader>
                <EuiModalHeaderTitle>
                  Tambah Produk 
                </EuiModalHeaderTitle>
              </EuiModalHeader>

              <EuiModalBody>
                <EuiForm>
                  <EuiFormRow label="Nama"
                    isInvalid={
                      this.state.validationFields['title'].isInvalid
                    }
                    error={
                      this.state.validationFields['title'].isInvalid &&
                      this.state.validationFields['title'].errors
                    }
                  >
                    <EuiFieldText
                      placeholder=""
                      value={this.state.title}
                      name="title"
                      onChange={this.handleChange}
                      aria-label="title"
                    />
                  </EuiFormRow>
                  <EuiFormRow label="Deskripsi">
                    <EuiFieldText
                      placeholder=""
                      value={this.state.description}
                      name="description"
                      onChange={this.handleChange}
                      aria-label="description"
                    />
                  </EuiFormRow>
                  <EuiFormRow label="Prioritas">
                    <EuiFieldNumber
                      placeholder="0"
                      value={this.state.priority}
                      onChange={this.handleChange}
                      name="priority"
                      aria-label="Urutan"
                    />
                  </EuiFormRow>
                  <EuiFormRow label="Tarif">
                    <EuiFieldText
                      placeholder=""
                      value={this.state.fee}
                      name="fee"
                      onChange={this.handleChange}
                      aria-label="fee"
                    />
                  </EuiFormRow>
                  <EuiFormRow label="ID eksternal">
                    <EuiFieldText
                      placeholder=""
                      value={this.state.externalId}
                      name="externalId"
                      onChange={this.handleChange}
                      aria-label="externalId"
                    />
                  </EuiFormRow>
                  <EuiFormRow label="Aktif">
                    <EuiSwitch
                      label=""
                      onChange={() => {
                        this.setState({
                          active: !this.state.active
                        });
                      }}
                      checked={this.state.active}
                    />
                  </EuiFormRow>
                </EuiForm>
              </EuiModalBody>

              <EuiModalFooter>
                <EuiButtonEmpty
                  onClick={() => {this.setState({ modal: false })}}
                >
                  Batal
                </EuiButtonEmpty>
                {this.state.id && this.state.id.length > 0 ? (
                  <>
                    <EuiButton
                      color={"danger"}
                      onClick={() => this.delete()}
                      disabled={this.state.saveLoading}
                      isLoading={this.state.deleteLoading}
                      fill
                    >
                      Hapus
                    </EuiButton>
                    <EuiButton fill isLoading={this.state.saveLoading} disabled={this.state.deleteLoading} onClick={() => this.save()}>
                      Update
                    </EuiButton>
                  </>
                ) : (
                  <EuiButton fill isLoading={this.state.saveLoading} disabled={this.state.deleteLoading} onClick={() => this.save()}>
                    Tambah
                  </EuiButton>
                )}
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        )}
        <EuiPage className="class">
          <EuiPageBody
            style={{ flexDirection: "row" }}
            className={"content-container"}
          >
            <TablePage
              title={"Produk"}
              buttonLabel={'Tambah Baru'}
              buttonFunc={() => this.add()}
              placeholder={"Nama Produk"}
              data={this.state.data}
              column={this.state.column}
              disablePagination={this.state.loading}
              page={this.state.page}
              limit={this.state.limit}
              total={this.state.total}
              prev={this.prevPage}
              next={this.nextPage}
              toPage={this.page}
              onItemClick={this.onItemClick}
              loading={this.state.loading}
            />
          </EuiPageBody>
        </EuiPage>
      </>
      )
  }
}

export default Products;
