import Config from '../Config';
import axios from 'axios';
import Error from './Error';
import Misc from './Misc';

const ErrorService = new Error();
const MiscService = new Misc();

class Sync {
  runThirdPartySync = () => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/thirdparty/sync`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  getThirdpartySyncEvents = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload.page = payload.page || 1;
      payload.limit = payload.limit || 10;
      payload.order = payload.Order || 'ASC';
      let url = `${Config.apiHost}/api/thirdparty/sync-events?page=${payload.page}&limit=${payload.limit}&order=${payload.order}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
}

export default Sync;
